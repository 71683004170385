import Amplify from 'aws-amplify';

const awsExports = {
  admin: {
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolRegion: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ADMIN,
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_ADMIN_WEB_CLIENT_ID,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  },
  clinica: {
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolRegion: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLINICA,
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLINICA_WEB_CLIENT_ID,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  },
  paciente: {
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolRegion: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_PACIENTE,
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_PACIENTE_WEB_CLIENT_ID,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.REACT_APP_DOMAIN_NAME,
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
        responseType: 'code'
      }
    }
  }
};

export default (frontend: 'admin' | 'clinica' | 'paciente' | '') => Amplify.configure(awsExports[frontend || 'paciente']);
