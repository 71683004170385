import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { convertTo64 } from './convertTo64';


interface ISignInformation {
  clinicaName: string;
  medicoName: string;
  medicoCrm: string;
  medicoUrlAssinatura: string;
  medicoAssinatura: Uint8Array;
}

class HandlePdf {

  async mergePDF (pdfs: any[]){

    const mergedPdf = await PDFDocument.create();
  
    for (let pdf of pdfs){
      const convertedPdf = Buffer.from(await convertTo64(pdf) as string, 'base64');
      const loadedPdf = await PDFDocument.load(convertedPdf);
      const copiedPages = await mergedPdf.copyPages(loadedPdf,loadedPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      })
    }
  
    const isMerged = await mergedPdf.save();
  
  
    const blob = [new Blob([isMerged], { type: 'application/pdf' })];
    const pdf = [new File(blob,`${Date.now()}-exame-clinica.pdf`)]
  
    return pdf;
  }

  async signPdf (pdf: any, info: ISignInformation){
    try {
      const convertedPdf = Buffer.from(await convertTo64(pdf) as string, 'base64');
  
      const pdfDoc = await PDFDocument.load(convertedPdf);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const date = new Intl.DateTimeFormat('pt-BR').format(Date.now())
      const hour = new Intl.DateTimeFormat('pt-BR',{
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      }).format(Date.now())
      firstPage.drawText(`Assinado de forma digital por ${info.clinicaName}. Médico Responsável: ${info.medicoName} - CRM: ${info.medicoCrm}. ${date} e ${hour} `, {
        x: 5,
        y: 5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }) 


      const jpgImage = await pdfDoc.embedPng(Buffer.from(info.medicoAssinatura))
      const scale = 0.5
      const x = 300 * scale;
      const y = 50 * scale;
      
      firstPage.drawImage(jpgImage, {
      x: 430,
      y: 5,
      width: x,
      height: y,
      })
  
      const signedPdfBuffer = await pdfDoc.save()
  
      const blob = [new Blob([signedPdfBuffer], { type: 'application/pdf' })];
      const signedPdf = new File(blob,`${Date.now()}-exame-clinica-assinado.pdf`)
  
      return signedPdf;
    }catch (error){
      return []
    }
    
  }

}

export { HandlePdf }
