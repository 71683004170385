import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

import api from '../../services/api';
import SearchExames from '../../templates/SearchExames';
import { maskName } from '../../utils/mask';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    }
  }),
);

interface Pet {
  id: string;
  nome: string;
  raca: string;
  responsavel: string;
  corPredominante: string;
  tipoSanguineo: string;
  peso: number;
  familia: object;
}

interface ParamTypes {
  id: string;
}

interface State {
  peso: number;
}

function Pet() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idPet, setIdPet] = useState({} as Pet);
  const [nome, setNome] = useState('');
  const [raca, setRaca] = useState('');
  const [corPredominante, setCorPredominante] = useState('');
  const [tipoSanguineo, setTipoSanguineo] = useState('');
  const [idFamilia, setIdFamilia] = React.useState('');
  const [familias, setFamilias] = React.useState([]);

  const [peso, setPeso] = React.useState<State>({
    peso: 0,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeso({
      ...peso,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIdFamilia(event.target.value as string);
  }

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
    }
  }

  useEffect(() => {
    const getFamilias = async () => {
      const familias = await api.get('/familiapet');
      setFamilias(familias.data);
    };

    getFamilias();

    if (id) {
      const getData = async () => {
        const userTokens = await Auth.currentSession();

        const response = await api.get(`/pet/${id}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });

        setIdPet(response.data.id);
        setNome(response.data.nome);
        setRaca(response.data.raca);
        setCorPredominante(response.data.corPredominante);
        setTipoSanguineo(response.data.tipoSanguineo);
        setPeso({
          ...peso,
          peso: response.data.peso,
        });
        setIdFamilia(response.data.familia.id);
      };

      getData();
    }
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const userTokens = await Auth.currentSession();
      const responsavel = userTokens.getAccessToken().payload.sub;
      const usuario = await api.get(`usuario/${responsavel}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      })

      await api.put("/pet", {
        id,
        nome,
        raca,
        responsavel: usuario.data,
        corPredominante,
        tipoSanguineo,
        peso: Number(peso.peso),
        familia: {
          id: idFamilia
        },
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/pets');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error.response.data,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main className='main-paciente'>
        <SearchExames searchField={false} />
        <div className='pet'>
          <h1>Pet</h1>
          <form onSubmit={handleSubmit} className='pet-form'>
            <div className={classes.grid3}>
              <Input
                type="hidden"
                style={{ display: "none" }}
                name="id"
                value={idPet}
              />
              <TextField
                id="standard-basic"
                label="Nome"
                variant="standard"
                required
                name="nome"
                value={nome}
                onChange={(e) => { setNome(maskName(e.target.value)) }}
              />
              <TextField
                id="standard-basic"
                label="Raça"
                variant="standard"
                required
                name="raca"
                value={raca}
                onChange={(e) => { setRaca(e.target.value) }}
              />
              <TextField
                id="standard-basic"
                label="Cor Predominante"
                variant="standard"
                required
                name="corPredominante"
                value={corPredominante}
                onChange={(e) => { setCorPredominante(e.target.value) }}
              />
            </div>
            <div className={classes.grid3}>
              <TextField
                id="standard-basic"
                label="Tipo Sanguineo"
                variant="standard"
                name="tipoSanguineo"
                value={tipoSanguineo}
                onChange={(e) => { setTipoSanguineo(e.target.value) }}
              />
              <TextField
                type="number"
                id="standard-basic"
                label="Peso"
                variant="standard"
                name="peso"
                value={peso.peso}
                onChange={handleChange}
              />
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">Família</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={idFamilia}
                  onChange={handleChangeSelect}
                  label="Família"
                  required
                >
                  {
                    familias.map((row: any, index) => {
                      return (
                        <MenuItem value={row.id}>{row.nome}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className='form-buttons'>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Salvar
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => { history.push('/pets') }}
              >
                Voltar
              </Button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

export default Pet;
