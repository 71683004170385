import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface Props extends ButtonProps {
  isLoading?: boolean;
}

const SubmitButton = ({ isLoading, children, ...rest }: Props) => {
  if (isLoading) {
    return (
      <Button disabled {...rest}>
        <CircularProgress style={{ width: 24, height: 24, color: '#fff' }} />
      </Button>
    );
  }
  return <Button {...rest}>{children}</Button>;
};

export default SubmitButton;
