import React, { useState, FormEvent, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';

import './styles.scss';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import grafismo from '../../assets/images/grafismo-admin.png';
import { UIStore } from '../../UIStore';

import LogoTextoClipo from '../../assets/images/logo-texto.svg';

function NovaSenhaAdmin(props: {
  location: {
    state: {
      username: string,
      password: string,
      email?: string,
      name: string
    }
  }
}) {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [name, setName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [email, setEmail] = useState(props.location.state.email);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<ShowEye />);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(<ShowEye />);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [display, setDisplay] = useState<"grid" | "none">("none");

  if (props.location.state === undefined || props.location.state === null) {
    history.goBack();
  }

  useEffect(() => {
    if (props.location.state.name !== '') {
      setActive(true);
    }

    setName(props.location.state.name);
    setUsername(props.location.state.username);
    setOldPassword(props.location.state.password);
  }, [])

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  function handleConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
    if (showConfirmPassword) {
      setConfirmPasswordIcon(<ShowEye />);
    } else {
      setConfirmPasswordIcon(<HideEye />);
    }
  }

  const checkPasswordLength = (password: string) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  }

  const checkSpecialCharacters = (password: string) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  }

  const checkUppercase = (password: string) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  }

  const checkLowercase = (password: string) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }
  }

  const checkNumber = (password: string) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkLowercase(event.target.value);
    checkNumber(event.target.value);
  };

  async function signIn(e: FormEvent) {
    e.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire({
        title: 'Erro!',
        text: "Por favor, verifique a senha e confirmação de senha novamente",
        icon: 'error',
      });

      return false;
    }

    if (password.match(/^(?=.*\d)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/) === null) {
      return Swal.fire({
        title: 'Erro!',
        text: 'Sua senha não contém os caracteres necessários!',
        icon: 'error',
      });
    }

    try {
      const user = await Auth.signIn(username, oldPassword);
      const challengeResponse = await Auth.completeNewPassword(user, password, {
        name: user.challengeParam.userAttributes.name || name,
        family_name: user.challengeParam.userAttributes.family_name || familyName       
      });
      if (challengeResponse.challengeName === 'MFA_SETUP') {
        history.push('/setup-mfa', { username: user.username, password });
      } else {
        UIStore.update(s => {
          s.signed = true;
          s.nivelUser = 'admin';
          s.username = user.username;
        });
        localStorage.setItem('signed', 'true');
        localStorage.setItem('username', user.username);
        localStorage.setItem('nivelUser', 'admin');
        history.replace('/exames');
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error.toString(),
        icon: 'error',
      });
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
    }

  }

  return (
    <>
      <div id="loginAdmin">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <img src={LogoTextoClipo} />
              <form className="materializeForm" onSubmit={signIn}>
                {!active ?
                  <>
                    <input
                      type="text"
                      placeholder="Nome"
                      autoFocus
                      name="name"
                      value={name}
                      onChange={(e) => { setName(e.target.value) }}
                      required
                    />
                    <input
                      type="text"
                      placeholder="Sobrenome"
                      autoFocus
                      name="family_name"
                      value={familyName}
                      onChange={(e) => { setFamilyName(e.target.value) }}
                      required
                    />
                  </>
                  :
                  ""
                }
                <input
                  type="text"
                  placeholder="Endereço de e-mail"
                  autoFocus
                  name="email"
                  readOnly={props.location.state.email !== undefined && props.location.state.email !== null && props.location.state.email !== ''}
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
                <div className="container-pass">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Nova senha"
                    name="password"
                    value={password}
                    onChange={(e) => { handlePasswordChange(e) }}
                    onBlur={() => setDisplay("none")}
                    onFocus={() => setDisplay("grid")}
                    required
                  />
                  <a onClick={(e) => { handlePassword() }}>{passwordIcon}</a>
                </div>
                <div className="validation" style={{ display: display }}>
                  <div className="validator">
                    {charNumberValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">Mínimo 8 caracteres</p>
                  </div>
                  <div className="validator">
                    {specialCharValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 caracter especial</p>
                  </div>
                  <div className="validator">
                    {uppercaseValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 letra maiúscula</p>
                  </div>
                  <div className="validator">
                    {lowercaseValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 letra minúscula</p>
                  </div>
                  <div className="validator">
                    {numberValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 número</p>
                  </div>
                </div>
                <div className="container-pass">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirmação de senha"
                    name="password"
                    value={confirmPassword}
                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                    required
                  />
                  <a onClick={(e) => { handleConfirmPassword() }}>{confirmPasswordIcon}</a>
                </div>
                <button>Trocar senha</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NovaSenhaAdmin;
