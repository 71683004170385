import { Auth } from 'aws-amplify';
import api from '..';

export const haveWelcomePack = async () => {
  const userTokens = await Auth.currentSession();
  const id = userTokens.getIdToken().payload.sub;
  const { data } = await api.get(`usuario/welcome-pack/${id}`, {
    headers: {
      'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
    },
  });

  return data.haveWelcomePack;
};

export const disableWelcomePack = async () => {
  const userTokens = await Auth.currentSession();
  const id = userTokens.getIdToken().payload.sub;

  await api.patch(`usuario/welcome-pack/${id}`, { welcome_pack: false });
};

export const confirmSignUp = async (email?: string, code?: string) => {
  await api.post(`/usuario/confirma/${email}`, { code });
};

export const getExams = async (url: string = "/exame") => {
  try {
    const userTokens = await Auth.currentSession();
    const { data: examsRes } = await api.get(url, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
      },
    });

    return examsRes;
  } catch (error) {
    throw new Error("Erro ao buscar exames cadastrados.")
  }
};
