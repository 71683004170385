import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../services/api';

import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface Parentesco {
  id: string;
  nome: object;
}

interface ParamTypes {
  id: string;
}

function Parentesco() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idParentesco, setIdParentesco] = useState({} as Parentesco);
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!nome) {
        Swal.fire({
          title: 'Digite o nome!',
          icon: 'warning',
        });
        return;
      }
      const result = await api.put("/parentesco", {
        id,
        nome,
        descricao: nome
      });

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/parentescos');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }


  useEffect(() => {
    if (id) {
      const getData = async () => {
        const response = await api.get('/parentesco/' + id);
        setIdParentesco(response.data.id);
        setNome(response.data.nome);
      };

      getData();
    }
  }, []);

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Parentesco</h1>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="hidden"
                    name="id"
                    value={idParentesco}
                  />
                  <div className={classes.root}>
                    <TextField
                      id="outlined-basic"
                      label="Família"
                      variant="outlined"
                      // required
                      name="nome"
                      value={nome}
                      onChange={(e) => { setNome(e.target.value) }}
                    />
                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/parentescos') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Parentesco;
