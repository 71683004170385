import React, { FormEvent, useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone';
import { IUploadFile } from './index.d';
import PublishIcon from '@material-ui/icons/Publish';
import './styles.scss';

function UploadFile(props: IUploadFile): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hover, setHover] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    let filteredArr = props.selectedFiles.reduce((acc, current) => {
      const x = acc.find((item: { name: any; }) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    props.setValidFiles([...filteredArr]);
  }, [props.selectedFiles])

  const validateFile = (file: any) => {
    if (props.validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  }

  const preventDefault = (e: FormEvent) => {
    e.preventDefault();
    // e.stopPropagation();
  }

  const dragOver = (e: any) => {
    preventDefault(e);
    setHover('hover');
  }

  const dragEnter = (e: any) => {
    preventDefault(e);
  }

  const dragLeave = (e: any) => {
    preventDefault(e);
    setHover('');
  }

  const fileDrop = (e: any) => {
    preventDefault(e);
    setHover('');
  }

  const fileSize = (size: any) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const fileType = (fileName: any) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  }

  const removeFile = (name: any) => {
    const index = props.validFiles.findIndex(e => e.name === name);
    const index2 = props.selectedFiles.findIndex(e => e.name === name);
    const index3 = props.unsupportedFiles.findIndex(e => e.name === name);
    props.validFiles.splice(index, 1);
    props.selectedFiles.splice(index2, 1);
    props.setValidFiles([...props.validFiles]);
    props.setSelectedFiles([...props.selectedFiles]);
    if (index3 !== -1) {
      props.unsupportedFiles.splice(index3, 1);
      props.setUnsupportedFiles([...props.unsupportedFiles]);
    }
  }

  const setFile = (file: any) => {
    if (validateFile(file)) {
      props.setSelectedFiles(prevArray => [...prevArray, file]);
    } else {
      file['invalid'] = true;
      props.setSelectedFiles(prevArray => [...prevArray, file]);
      setErrorMessage('Formato inválido!');
      props.setUnsupportedFiles(prevArray => [...prevArray, file]);
    }
  }


  const handleFiles = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      const currentFile = files[i];
      const nameExists = props.validFiles.some(file => file.name === currentFile.name);
      if (nameExists) {
        const date = new Date();
        const time = date.getHours() + '' + date.getMinutes() + '' + date.getSeconds();
        const blob = currentFile.slice(0, currentFile.size, currentFile.type);
        const newFile = new File([blob], `${time}-${currentFile.name}`, { type: currentFile.type });
        setFile(newFile);
      } else {
        setFile(currentFile);
      }
    }
  }

  const fileInputClicked = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  }

  const openImageModal = (file: File) => {
    const reader = new FileReader();
    if (props.modalRef.current) props.modalRef.current.style.display = "none";
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      if (props.modalImageRef.current) props.modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    }
  }

  return (
    <div className="container">
      {props.unsupportedFiles.length === 0 && props.validFiles.length ? <button className="file-upload-btn" disabled={props.submit} onClick={() => props.uploadFiles()}>Subir Exame</button> : ''}
      {props.unsupportedFiles.length ? <p>Por favor remova os exames em formato inválido.</p> : ''}
      <Dropzone onDrop={handleFiles}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className="drop-container" id={hover} {...getRootProps({
              onDragOver: e => dragOver(e),
              onDragEnter: e => dragEnter(e),
              onDragLeave: e => dragLeave(e),
              onDrop: e => fileDrop(e),
              onClick: () => fileInputClicked(),
            })}>
              <input
                {...getInputProps()}
                ref={fileInputRef}
                className="file-input"
                multiple={props.multiple}
              />

              <div className="drop-message">
                <div className="upload-icon"><PublishIcon /></div>
                {props.type === 'person' &&
                  <>
                    <span style={{ display: "block", lineHeight: "2rem" }}>Arraste, clique ou fotografe para enviar seus exames.</span>
                    <span style={{ display: "block", lineHeight: "2rem" }}>Formatos de arquivos aceitos: pdf, png e jpg.</span>
                  </>
                }

                {props.type === 'clinic' &&
                  <>
                    <span style={{ display: "block", lineHeight: "2rem" }}>Arraste ou clique aqui para enviar exames (PDF)</span>
                  </>
                }
              </div>
            </div>
          </section>
        )}
      </Dropzone>

      <div className="container-file-drop">
        {
          props.validFiles.map((data, i) =>
            <div className="file-status-bar" key={i}>
              <div onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}>
                <div className="file-type-logo"></div>
                <div className="file-type">{fileType(data.name)}</div>
                <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                <div className="file-remove" onClick={() => removeFile(data.name)}>X</div>
              </div>
            </div>
          )
        }
      </div>
    </div >
  )
}

export default UploadFile;
