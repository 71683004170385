import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import bluedark_logo from '../../assets/images/bluedark_logo.svg';
import { disableWelcomePack, haveWelcomePack } from '../../services/api/user';
import { CircleCheckbox } from '../CircleCheckbox';
import './styles.scss';
interface WelcomeProps {
  title: JSX.Element;
  description: string;
  disableOption: boolean;
}

export const Welcome = (props: WelcomeProps) => {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const haveWelcome = await haveWelcomePack();
      setOpen(haveWelcome);
    })();
  }, []);

  const handleClick = () => {
    if (checked) disableWelcomePack();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className="welcome-to-new-design">
        <img src={bluedark_logo} alt="Logo" />
        {props.title}
        <p>{props.description}</p>
        <button onClick={handleClick}>Ok</button>
        {props.disableOption && (
          <div className="dont-see-more">
            <CircleCheckbox
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
            <p>Não mostrar novamente</p>
          </div>
        )}
      </div>
    </Dialog>
  );
};
