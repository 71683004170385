import { Auth } from "aws-amplify";
import { formatISO } from "date-fns";
import Swal from "sweetalert2";

import { ExameDTO } from "../dtos/exameDto";
import api from "../services/api";

interface ReduceOwner {
  fullName: string;
  firstName: string;
  isDependente: boolean;
}


interface IHandleExams {
  reduceOwners: Array<ReduceOwner>,
  reduceClinics: Array<string>
}

export const handleExams = (exams: ExameDTO[]) => {
  const result: IHandleExams = {
    reduceOwners: [],
    reduceClinics: []
  }

  exams.map((exam: ExameDTO) => {
    const fullName = exam.dadosPessoaExame.nome;
    const nameSplit = fullName.split(' ');
    const firstName = nameSplit[0];
    const isDependente = !!exam.dependente;
    const reduceOwner = result.reduceOwners.findIndex(
      value => value.fullName === fullName,
    );

    if (reduceOwner < 0) {
      result.reduceOwners.push({ fullName, firstName, isDependente });
    }

    if (exam.clinica) {
      const reduceClinic = result.reduceClinics.findIndex(
        value => value === exam.clinica.nomeFantasia,
      );

      if (reduceClinic < 0) {
        result.reduceClinics.push(exam.clinica.nomeFantasia);
      }
    }
  });

  return result;
};

export const handleDelete = async (url: string, type?: string) => {
  const userTokens = await Auth.currentSession();

  function refreshPage() {
    window.location.reload();
  }

  Swal.fire({
    title: `Você tem certeza que deseja deletar o ${type ? type : "arquivo"}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: "#418107",
    confirmButtonText: "Deletar",
    cancelButtonText: "Cancelar"
  }).then((result) => {
    if (result.isConfirmed) {
      try {
        const deleteConfirmed = async () => {
          await api.delete(`${url}`, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          }).then(refreshPage);
        }
        deleteConfirmed();
      } catch (error) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Ocorreu um erro ao executar a ação solicitada!',
          icon: 'error'
        });
        return;
      }
    }
  });
}

export const handleDownload = async (url: string, archive?: string) => {
  if (archive) {
    const ext = archive.substr(archive.length - 3);
    const userTokens = await Auth.currentSession();
    let typeFile = ext == 'pdf' ? 'application' : 'image';

    api.get(`${url}`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      },
      responseType: 'blob'
    }).then(response => {
      const data = response.data;
      const file = new Blob(
        [data],
        { type: typeFile + '/' + ext });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');

      link.href = fileURL;
      link.setAttribute(
        'download',
        `${archive}`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }).catch(error => {
      console.log(error);
    });
  }
}

export const handleShare = async (id: string[]) => {
  if (!id.length) {
    Swal.fire({
      title: 'Atenção!',
      text: 'Selecione um ou mais documentos para compartilhar!',
      icon: 'warning'
    });
    return;
  }

  Swal.fire({
    title: 'Quanto tempo o documento ficará disponível?',
    icon: 'warning',
    input: 'select',
    inputPlaceholder: 'Selecione o período',
    inputOptions: {
      60: '1 hora',
      1440: '1 dia',
      10080: '1 semana',
      20160: '2 semanas'
    },
    showCancelButton: true,
    confirmButtonColor: "#418107",
    confirmButtonText: "Sim, Compartilhar!",
    cancelButtonText: "Cancelar!"
  }).then(async (result) => {
    if (result.isConfirmed) {
      const minutes = parseInt(result.value, 10);
      const now = new Date();
      now.setHours(now.getHours(), now.getMinutes() + minutes);

      const userTokens = await Auth.currentSession();
      api.put('/exame/acesso', {
        id,
        expirarEm: formatISO(now)
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      }).then((result) => {
        Swal.fire({
          title: 'Sucesso!',
          html: `<p style="text-align: justify;">Os documentos compartilhados poderão ser visualizados e baixados por quem possuir o código e a senha.</p><p style="text-align: justify;">Seu código também foi enviado para o seu e-mail.</p><strong>Código: </strong>${result.data.codigo}<br><strong>Senha:</strong> ${result.data.senha}`,
          icon: 'success',
        });
      })
        .catch((error) => {
          Swal.fire({
            title: 'Erro!',
            html: error.response.data,
            icon: 'error',
          });
        });
    }
  });
}

export const getFileURL = async (url: string, archive: string): Promise<string | undefined> => {
  const ext = archive.split('.').pop();
  const typeFile = ext == 'pdf' ? 'application' : 'image';
  
  try {
    const userTokens = await Auth.currentSession();
    const exameFile = await api.get(`${url}`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      },
      responseType: 'blob'
    });
    const file = new Blob(
      [exameFile.data],
      { type: typeFile + '/' + ext }
    );

    return URL.createObjectURL(file);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error.response.data.text,
      title: error.response.data.title
    });
  }
}
