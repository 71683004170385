export const states = [
    {
      code: 11,
      uf: "RO",
      description: "Rondônia"
    },
    {
      code: 12,
      uf: "AC",
      description: "Acre"
    },
    {
      code: 13,
      uf: "AM",
      description: "Amazonas"
    },
    {
      code: 14,
      uf: "RR",
      description: "Roraima"
    },
    {
      code: 15,
      uf: "PA",
      description: "Pará"
    },
    {
      code: 16,
      uf: "AP",
      description: "Amapá"
    },
    {
      code: 17,
      uf: "TO",
      description: "Tocantins"
    },
    {
      code: 21,
      uf: "MA",
      description: "Maranhão"
    },
    {
      code: 22,
      uf: "PI",
      description: "Piauí"
    },
    {
      code: 23,
      uf: "CE",
      description: "Ceará"
    },
    {
      code: 24,
      uf: "RN",
      description: "Rio Grande do Norte"
    },
    {
      code: 25,
      uf: "PB",
      description: "Paraíba"
    },
    {
      code: 26,
      uf: "PE",
      description: "Pernambuco"
    },
    {
      code: 27,
      uf: "AL",
      description: "Alagoas"
    },
    {
      code: 28,
      uf: "SE",
      description: "Sergipe"
    },
    {
      code: 29,
      uf: "BA",
      description: "Bahia"
    },
    {
      code: 31,
      uf: "MG",
      description: "Minas Gerais"
    },
    {
      code: 32,
      uf: "ES",
      description: "Espírito Santo"
    },
    {
      code: 33,
      uf: "RJ",
      description: "Rio de Janeiro"
    },
    {
      code: 35,
      uf: "SP",
      description: "São Paulo"
    },
    {
      code: 41,
      uf: "PR",
      description: "Paraná"
    },
    {
      code: 42,
      uf: "SC",
      description: "Santa Catarina"
    },
    {
      code: 43,
      uf: "RS",
      description: "Rio Grande do Sul"
    },
    {
      code: 50,
      uf: "MS",
      description: "Mato Grosso do Sul"
    },
    {
      code: 51,
      uf: "MT",
      description: "Mato Grosso"
    },
    {
      code: 52,
      uf: "GO",
      description: "Goiás"
    },
    {
      code: 53,
      uf: "DF",
      description: "Distrito Federal"
    }
  ];
