import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  Checkbox,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  makeStyles,
  Paper,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import ArrowAsc from '@material-ui/icons/ArrowUpward';
import ArrowDesc from '@material-ui/icons/ArrowDownward';
import { ImRadioUnchecked, } from 'react-icons/im';
import { FaCheckCircle } from 'react-icons/fa';

import './styles.scss';
import { IHeadAction, IDataListProps } from './index.d';
import Options from './Options';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      color: '#ccc',
      padding: 0,
      margin: 'auto .6rem',
      transition: '.2s',

      "&:hover": {
        color: "#01A3FF"
      },

      ['@media (max-width: 455px)']: {
        color: '##01A3FF',
      }
    },
    checked: {
      "&$checked": { color: "#01A3FF" },
      ['@media (max-width: 455px)']: {
        "&$checked": { color: "#01A3FF" }
      }
    }
  })
);

type Order = 'asc' | 'desc';

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const DataList = (props: IDataListProps) => {
  const { rows, pageName, checkbox = true, avatarBackground = true, pagination } = props;
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [selected, setSelected] = useState<string[]>([]);
  const classes = useStyles();
  const rowCount = rows.length;
  const numSelected = selected.length;
  const enablePagination = pagination && rowCount > 9 ? true : false;

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  }

  const handleCheckboxClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  const handleSelectAllCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleData = (row: any) => {
    const rowDataProps = props.handleRowProps(row);
    const rowProps = {
      avatar: rowDataProps.avatar,
      title: rowDataProps.title,
      subTitle: rowDataProps.subTitle,
      description: rowDataProps.description,
      orderBy: rowDataProps.orderBy,
      onClick: rowDataProps.onClick,
      param: rowDataProps.param,
      actions: rowDataProps.actions,
    }

    return rowProps;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className='data-list'>
      {pageName && <Typography className="page-title" variant="h6" component="div">{pageName}</Typography>}
      {
        numSelected < 1 ?
          <div className="list-head">
            <Toolbar className="tool-bar">
              {checkbox ?
                <Tooltip title="SELECIONAR TODOS">
                  <Checkbox
                    classes={{
                      root: classes.checkbox,
                      checked: classes.checked
                    }}
                    className="select-all-checkbox"
                    icon={<ImRadioUnchecked />}
                    checkedIcon={<FaCheckCircle />}
                    color="primary"
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllCheckboxClick}
                  />
                </Tooltip> :
                <span className='paddingCheckbox'></span>}
              <Tooltip title="ORDENAR">
                <IconButton className="sort-icon" disableRipple
                  onClick={() => {
                    order === "asc" ? setOrder("desc") : setOrder("asc")
                  }}>
                  <IconButton onClick={createSortHandler(props.orderBy)} disableRipple>
                    {props.orderByTitle}
                    {order === "asc" ? <ArrowAsc /> : <ArrowDesc />}
                  </IconButton>
                </IconButton>
              </Tooltip>
              <div className="head-list-tools">
                {props.headActions.map((action: IHeadAction) => {
                  return (
                    <Tooltip title={action.tooltip}>
                      {!action.text ?
                        <IconButton className="tool-icon" onClick={() => action.handle?.(selected)}>
                          {action.link ? <NavLink to={action.link}>{action.icon}</NavLink> : <>{action.icon}</>}
                        </IconButton> :
                        <Button startIcon={action.icon} className="tool-button" onClick={() => action.handle?.(selected)}>
                          {action.link ? <NavLink to={action.link}>{action.text}</NavLink> : <>{action.text}</>}
                        </Button>}
                    </Tooltip>
                  )
                })}
              </div>
            </Toolbar>
          </div>
          :
          <div className="list-head checkbox-option">
            <Toolbar className="tool-bar">
              <Tooltip title="SELECIONAR TODOS">
                <Checkbox
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checked
                  }}
                  className="select-all-checkbox"
                  icon={<ImRadioUnchecked />}
                  checkedIcon={<FaCheckCircle />}
                  color="primary"
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllCheckboxClick}
                />
              </Tooltip>
              <span className="num-selected">
                {`${numSelected} selecionado(s)`}
              </span>
              <div className="head-list-tools">
                {props.headActions.map((action: IHeadAction) => {
                  return action.visibleInCheckboxAction &&
                    <>
                      <Tooltip title={action.tooltip}>
                        <IconButton className="tool-icon" onClick={() => action.handle?.(selected)}>
                          {action.link ? <NavLink to={action.link}>{action.icon}</NavLink> : <>{action.icon}</>}
                        </IconButton>
                      </Tooltip>
                    </>
                })}
              </div>
            </Toolbar>
          </div>
      }
      <div className="list-content">
        <List>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + (enablePagination ? rowsPerPage : rowCount))
            .map((row: any, index: number) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              const data = handleData(row);

              const handleClick = () => {
                return data.onClick?.(row.id, data.param);
              }

              return (
                <Paper variant="elevation" className="list-item">
                  {checkbox &&
                    <Checkbox
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked
                      }}
                      icon={<ImRadioUnchecked />}
                      checkedIcon={<FaCheckCircle />}
                      color="primary"
                      onClick={(event) => handleCheckboxClick(event, row.id)}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />}
                  <ListItem
                    tabIndex={-1}
                    key={row.id}
                    onClick={handleClick}
                  >
                    <ListItemAvatar>
                      <Avatar className={avatarBackground ? "list-item-avatar" : "list-item-avatar no-backgroud"}>
                        {data.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <div className="list-item-text">
                      <span>{data.title}</span>
                      <p>{data.subTitle}</p>
                      <p>{data.description}</p>
                    </div>
                  </ListItem>
                  <div className='list-item-options'>
                    <Options
                      actions={data.actions}
                      id={row.id}
                    />
                  </div>
                </Paper>
              )
            })}
        </List>
        {enablePagination && <>
          <TablePagination
            className="pagination"
            rowsPerPageOptions={[5, 10, 25, { value: rowCount, label: "Todas" }]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>}
      </div>
    </div>
  )
}

export default DataList;
