import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, NavLinkProps } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, FormControl, FormControlLabel, Switch, InputLabel, Select, MenuItem } from '@material-ui/core';
import { maskCPF, maskPhoneNumber } from '../../utils/mask';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import validator from 'cpf-cnpj-validator';
import api from '../../services/api';
import './styles.scss';
import UploadFile from '../../components/UploadFile';
import { ModalMergePdf } from '../../components/ModalMergePdf';
import { findNameByCpfAndBirthDate } from '../../services/hubDevCpf';
import { HandlePdf } from '../../services/HandlePdf';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '&.true': {
        display: 'none'
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '&.true': {
        display: 'none'
      },
      '& > *': {
        margin: '30px 8px !important',
        width: '33.33%',
        '& > .flagPhone': {
          top: '15px'
        },
        '& > .MuiFormControl-root': {
          'margin': '0 !important'
        },
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }),
);

interface Medico {
  id: string;
  fullName: string;
  crm: string;
  url: string;
  file: any;
  signature: string;
  medicoDefault: boolean;
  clinica: {
    id: string;
  };
}

interface LocationState extends NavLinkProps {
  id: string;
  cpf: string;
  nome: string;
  dataNascimento?: string;
  telefone?: string;
}

interface State {
  cpf: string;
}

function Exame() {
  const handlePdf = new HandlePdf()

  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const uploadModalRef = useRef<HTMLDivElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [validFiles, setValidFiles] = useState<any[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<any[]>([]);
  const [values, setValues] = React.useState<State>({
    cpf: '',
  });
  const [nome, setNome] = useState('');
  const [sobrenome, setSobreNome] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState<string>('');
  const [dataNascimento, setDataNascimento] = useState<Date | null>(null);
  const [telefone, setTelefone] = useState('');
  const [isPaciente, setIsPaciente] = useState(true);
  const [cliente, setCliente] = useState([]);
  const [dependente, setDependente] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [loadingName, setLoadingName] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();

  const Joi = require('@hapi/joi').extend(validator);
  const cpfSchema = Joi.document().cpf().required();
  const [multiple, setMultiple] = useState(true);
  const [id, setId] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [disabledCpf, setDisabledCpf] = useState(false);
  const location = useLocation<LocationState>();

  const [signPdf, setSignPdf] = useState(true);

  const [medicos, setMedicos] = useState<Medico[]>([]);
  const [medicoFullName, setMedicoFullName] = useState('');
  const [medicoCrm, setMedicoCrm] = useState('')
  const [medicoUrl, setMedicoUrl] = useState('')
  const [medicoFile, setMedicoFile] = useState<any>('');
  const [clinicaName, setClinicaName] = useState('');
  const [pickedMedico, setPickedMedico] = useState('');

  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.name === 'cpf' ? maskCPF(event.target.value) : event.target.value,
    });
  };

  const handleOutCpf = async () => {
    setCliente([]);
    setDependente([]);

    try {
      await cpfSchema.validateAsync(values.cpf);
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'CPF inválido!',
        icon: 'error',
      });
      setNomeCompleto('');
      setButtonLoading(true);
      return;
    }

    try {
      const userTokens = await Auth.currentSession();
      const clienteExist = await api.get(`/usuario/cpf/${values.cpf.replace(/\D/g, '')}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      if (Object.keys(clienteExist.data).length > 0) {
        setIsPaciente(true);
        setCliente(clienteExist.data)
        setButtonLoading(false)
      } else {
        const dependenteExist = await api.get(`/dependente?cpf=${values.cpf.replace(/\D/g, '')}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });

        if (dependenteExist.data.length > 0) {
          setIsPaciente(true);
          setDependente(dependenteExist.data[0]);
          setButtonLoading(false);
        } else {
          setIsPaciente(false);
          setButtonLoading(false);
        }
      }
    } catch (error) {
      setIsPaciente(false);
      setButtonLoading(false);
    }
    try {
      if (values.cpf && dataNascimento && !disabled && !isPaciente) {
        setLoadingName(true);
        setDisabled(true)
        const name = await findNameByCpfAndBirthDate(values.cpf, new Date(dataNascimento))
        setNomeCompleto(name);
      } else {
        setDisabled(false)
      }
    } catch (error) {
      if (error.message === "Network Error") {
        setDisabled(false);
      } else {
        Swal.fire({
          title: 'Erro!',
          text: error.message,
          icon: 'error',
        });
        setDataNascimento(null);
        setNomeCompleto('');
      }

    } finally {
      setLoadingName(false);
    }
  };

  useEffect(() => {
    const getMedicos = async () => {
      const idClinica = (await Promise.all(await Auth.userAttributes(await Auth.currentUserPoolUser()))).find(attr => attr.getName() === 'custom:clinica')?.getValue();
      const foundMedicos: Medico[] = (await api.get(`/clinica/${idClinica}/medicos`)).data;

      const ordenedMedicos = foundMedicos.sort((a, b) => a.fullName.localeCompare(b.fullName));
      setMedicos(ordenedMedicos);

      const medicoDefault = ordenedMedicos.find((medico) => medico.medicoDefault === true);

      if (medicoDefault) {
        setPickedMedico(medicoDefault.id);
      }

      if (foundMedicos === []) {
        setSignPdf(false);
      }
    }

    const getClinica = async () => {
      const idClinica = (await Promise.all(await Auth.userAttributes(await Auth.currentUserPoolUser()))).find(attr => attr.getName() === 'custom:clinica')?.getValue()
      const clinica = await api.get(`/clinica/${idClinica}`)
      setClinicaName(clinica.data.nomeFantasia)
    }

    if (!id) {
      if (location.state !== null && location.state !== undefined) {
        const result = async () => {
          const userTokens = await Auth.currentSession();
          const response = await api.get(`/exame/${location.state.id}`, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          });

          setId(response.data.id);
          setValues({
            ...values,
            cpf: response.data.cpf,
          });
          setIsPaciente(false);
          setButtonLoading(false);
          setNomeCompleto(response.data.dadosPessoaExame.nome);

          let arrName = response.data.dadosPessoaExame.nome.split(" ");
          setNome(arrName[0]);
          arrName.shift()
          setSobreNome(arrName.join(' '));

          const dataNascimento = response.data.dadosPessoaExame.dataNascimento || undefined;
          if (dataNascimento !== undefined) {
            setDataNascimento(response.data.dadosPessoaExame.dataNascimento);
          }

          const telefone = response.data.dadosPessoaExame.telefone || undefined;
          if (telefone !== undefined) {
            setTelefone(response.data.dadosPessoaExame.telefone.substr(2));
          }
        }


        result();
      }
      getMedicos();
      getClinica();
    }
  }, []);

  useEffect(() => {
    setIsPaciente(false);
    setDisabled(false);
    setNomeCompleto('');
    setDataNascimento(null);                
  }, [values.cpf])

  const closeModal = () => {
    if (modalRef.current) modalRef.current.style.display = "none";
    if (modalImageRef.current) modalImageRef.current.style.backgroundImage = 'none';
  }

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader && reader.result) resolve(reader.result.toString().replace(/^data:.+;base64,/, ''));
    }
    reader.onerror = error => reject(error);
  });

  const uploadProgress = (progressEvent: any) => {
    const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
    if (progressRef.current) {
      progressRef.current.innerHTML = `${uploadPercentage}%`;
      progressRef.current.style.width = `${uploadPercentage}%`;
    }

    if (uploadPercentage === 100) {
      if (uploadRef.current) {
        uploadRef.current.innerHTML = 'Exame cadastrado com sucesso!';

        Swal.fire({
          title: 'Exame cadastrado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/exames');
          }
        });
      }
      setValidFiles([...validFiles]);
      setSelectedFiles([...validFiles]);
      setUnsupportedFiles([...validFiles]);
    }
  }

  const uploadFiles = async (pdf?: any[]) => {
    if (!values.cpf) {
      Swal.fire({
        title: 'Digite o cpf!',
        icon: 'warning',
      });
      return;
    }

    const [cleanNome, cleanSobrenome] = nomeCompleto.trim().match(/^\S*\s*(.*)$/) || [];

    if (!isPaciente && (!cleanNome || !cleanSobrenome)) {
      let field = 'Sobrenome';
      if (!cleanNome) field = 'Nome Completo';

      Swal.fire({
        title: `Digite o ${field}`,
        icon: 'warning'
      });
      return;
    }

    if (!pickedMedico && signPdf) {
      Swal.fire({
        title: `Favor selecionar o médico para assinar o exame!`,
        icon: 'warning'
      });
      return;
    }

    if (uploadModalRef.current) uploadModalRef.current.style.display = 'block';
    if (uploadRef.current) uploadRef.current.innerHTML = 'Subindo exame...';
    const ativo = true;

    let atributosExame = {};
    let dadosPessoaExame = {};

    if (Object.keys(dependente).length > 0) {
      atributosExame = {
        dependente,
      };
    } else {
      if (Object.keys(cliente).length > 0) {
        atributosExame = {
          cliente
        };
      }
    }

    if (!isPaciente || (isPaciente && id)) {
      dadosPessoaExame = {
        dadosPessoaExame: {
          nome: isPaciente ? `${cleanNome}` : nomeCompleto,
          dataNascimento: dataNascimento ? dataNascimento : null,
          telefone: telefone ? '55' + telefone.replace(/[^0-9]+/g, '') : null
        }
      };
    }

    const userTokens = await Auth.currentSession();

    try {
      const info = await getMedico(pickedMedico);

      let files = pdf || validFiles;


      const signedPdf: File[] = signPdf && info ? await Promise.all(files.map(async file => await handlePdf.signPdf(file, info))) : files

      if (id) {
        await api.patch(`exame/reupload/${id}`, {
          cpf: values.cpf.replace(/\D/g, ''),
          arquivoBinario: {
            fileName: signedPdf[0].name,
            content: await toBase64(signedPdf[0])
          },
          ...dadosPessoaExame
        }, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          },
          onUploadProgress: (progressEvent: any) => {
            uploadProgress(progressEvent);
          },
        });
      } else {
        const promises = signedPdf.map(async (file) => {
          return {
            id: uuidv4(),
            ...atributosExame,
            ...dadosPessoaExame,
            arquivoBinario: {
              fileName: file.name,
              content: await toBase64(file)
            },
            ativo,
            cpf: values.cpf.replace(/\D/g, ''),
            clinica: {
              id: (await Auth.userAttributes(await Auth.currentUserPoolUser())).find(attr => attr.getName() === 'custom:clinica')?.getValue()
            }
          }
        });

        const bodyUpload = await Promise.all(promises);

        await api.put('exame', {
          concatImages: false,
          exames: bodyUpload
        }, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          },
          onUploadProgress: (progressEvent: any) => {
            uploadProgress(progressEvent);
          },
        });
      }
    } catch (err) {
      if (uploadRef.current) {
        uploadRef.current.innerHTML = `<span class="error">Erro ao subir exame!</span>`;
        Swal.fire({
          title: 'Erro ao subir exame!',
          icon: 'error',
        });
      }
      if (progressRef.current) progressRef.current.style.backgroundColor = 'red';
    }
  }

  async function getMedico(id: string) {
    const selectedMedico = medicos.find(medico => medico.id === id) as Medico
    if (selectedMedico) {
      const medicoSign: Medico = (await api.get(`clinica/medico/${selectedMedico.id}`)).data
      const info = {
        clinicaName,
        medicoName: selectedMedico.fullName.substring(0, 15),
        medicoCrm: selectedMedico.crm,
        medicoUrlAssinatura: medicoSign.url,
        medicoAssinatura: medicoSign.file.data
      }

      return info;
    }
    return undefined;
  }

  const handleChangeMedico = async (event: any) => {

    setPickedMedico(event.target.value);
  }

  const startMergePdf = async () => {
    if (validFiles.length <= 1) {
      uploadFiles();
      return;
    }

    if (id) {
      setOpen(true);
      return;
    }

    const { isConfirmed } = await Swal.fire<any>({
      title: 'Deseja juntar todos os PDFs selecionados em um único exame?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não"
    })

    if (isConfirmed) {
      setOpen(true);
      return;
    } else {
      uploadFiles();
      return
    }

  }

  const closeModalMergePdf = () => {
    setOpen(false);
  }

  const closeUploadModal = () => {
    if (uploadModalRef.current) uploadModalRef.current.style.display = 'none';
  }

  return (
    <main className="uploadFiles">

      <div className="grid-box grid-one">
        <div className="card">
          <div className="card-body">
            <div>
              <h1>Upload Exame</h1>
              <div className={classes.grid}>
                <TextField
                  id="outlined-basic"
                  disabled={disabledCpf}
                  label="CPF"
                  variant="outlined"
                  required
                  className="cpf"
                  name="cpf"
                  value={values.cpf}
                  onChange={handleChange}
                  onBlur={handleOutCpf}
                />
              </div>

              <div className={classes.grid3 + ' ' + isPaciente}>

              <FormControl fullWidth>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      inputVariant="outlined"
                      openTo="year"
                      format="dd/MM/yyyy"
                      label="Data de nascimento"
                      views={["year", "month", "date"]}
                      value={dataNascimento}
                      onBlur={handleOutCpf}
                      onChange={(value) => {
                        setDataNascimento(value);
                      }}
                      invalidDateMessage={"Inserir uma data válida"}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label={
                      !loadingName ? <span>Nome completo</span> : <i>Buscando nome . . . </i>
                    }
                    variant="outlined"
                    name="nome"
                    disabled={disabled}
                    value={nomeCompleto}
                    onChange={(e) => { setNomeCompleto(e.target.value) }}
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Telefone"
                    variant="outlined"
                    className="telefone"
                    name="telefone"
                    value={maskPhoneNumber(telefone)}
                    onChange={(e) => {
                      setTelefone(maskPhoneNumber(e.target.value));
                    }}
                  />
                </FormControl>
              </div>
              <div className={classes.grid3}>
                <div />
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">Médico</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={handleChangeMedico}
                    label="Médico"
                    value={pickedMedico}
                    required={signPdf}
                    disabled={!signPdf}
                  >
                    {
                      medicos.map((row: any, index) => {
                        return (
                          <MenuItem value={row.id}>{row.crm} - {row.fullName}</MenuItem>
                        )
                      })
                    }

                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={signPdf}
                      onChange={(e) => { setSignPdf(!signPdf) }}
                      color="primary"
                      name="ativo"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Assinar Exame?"
                />
              </div>
              <UploadFile
                submit={buttonLoading}
                multiple={multiple}
                modalRef={modalRef}
                modalImageRef={modalImageRef}
                uploadModalRef={uploadModalRef}
                uploadRef={uploadRef}
                progressRef={progressRef}
                selectedFiles={selectedFiles}
                unsupportedFiles={unsupportedFiles}
                validFiles={validFiles}
                setValidFiles={setValidFiles}
                setSelectedFiles={setSelectedFiles}
                setUnsupportedFiles={setUnsupportedFiles}
                uploadFiles={startMergePdf}
                validTypes={['application/pdf']}
                type={'clinic'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal" ref={modalRef}>
        <div className="overlay"></div>
        <span className="close" onClick={(() => closeModal())}>X</span>
        <div className="modal-image" ref={modalImageRef}></div>
      </div>

      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay"></div>
        <div className="progress-container">
          <div className="close" onClick={(() => closeUploadModal())}>X</div>
          <span ref={uploadRef}></span>
          <div className="progress">
            <div className="progress-bar" ref={progressRef}></div>
          </div>
        </div>
      </div>
      <ModalMergePdf
        isOpen={open}
        closeModal={closeModalMergePdf}
        documents={validFiles}
        setValidFiles={setValidFiles}
        uploadFiles={uploadFiles}
      />
    </main>
  );
}

export default Exame;
