import React, { useState, useEffect, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import { UIStore } from '../../UIStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      backgroundColor: "#00546B;",
      height: "100vh",
      ['@media (min-width:780px) and (max-width: 1024px)']: { // eslint-disable-line no-useless-computed-key
        height: "120vh",
      },
      ['@media (min-width:455px) and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
        height: "145vh",
      },
      ['@media (max-width: 450px)']: { // eslint-disable-line no-useless-computed-key
        height: '200vh',
      }
    },
    terms: {
      marginTop: "5px",
      width: '55%',
      margin: 'auto',
      ['@media (max-width: 691px)']: {
        width: "80%",
      }
    },
    logo: {
      padding: "0 1rem",
      zIndex: 5,
    },
    img: {
      width: "200px",
      height: "auto",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: "60px",
      paddingBottom: "2px",
    },
    message: {
      color: '#fff',
      fontFamily: 'Raleway',
      fontWeight: 500,
      textAlign: 'left',
      fontSize: "18px"

    },
    button: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      width: "55%",
      margin: "0 auto",

      ['@media (max-width: 991px)']: {
        width: "95%",
      }
    },
    buttonHome: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      '&:hover': {
        filter: "brightness(0.8) !important"
      }
    },
    buttonHelp: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      '&:hover': {
        filter: "brightness(0.8) !important"
      }
    },

    grid1: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: '1fr',
      gridGap: '16px',
      marginBottom: '16px'
    },
    grid2: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(1, 1fr)',
      gridGap: '16px'
    }
  })
);

export default function Agradecimento() {
  const classes = useStyles();
  const history = useHistory();

  const firstSignIn = async () => {
    const userTokens = await Auth.currentSession();
    const email = userTokens.getIdToken().payload["email"];
    const cliente = await api.get(`usuario/${email}`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      }
    });
    const cpf = cliente.data.cpf;

    const checkLogin = () => {
      return api.put(`/login`, {
        cliente: cliente.data
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
    }

    const assignExams = () => {
      return api.put(`/exame/atribuir/${cpf}`, {}, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
    }

    Promise.all([checkLogin(), assignExams()]);
  }

  const handleForward = async (open: boolean) => {
    try {
      await firstSignIn();
      const userTokens = await Auth.currentSession();
      let username = userTokens.getIdToken().payload.email;

      const name = `${userTokens.getIdToken().payload["name"]} ${userTokens.getIdToken().payload["family_name"]}`;
      UIStore.update(s => {
        s.signed = true;
        s.nivelUser = 'paciente';
        s.username = username;
        s.name = name;
      });
      localStorage.setItem('signed', 'true');
      localStorage.setItem('name', name);
      localStorage.setItem('nivelUser', 'paciente');

      const now = new Date();
      const expirationTime = now.setHours(now.getHours() + 24);
      localStorage.setItem('expirationTime', String(expirationTime));
      history.push({
        pathname: "/home",
      });

      history.push({
        pathname: '/home',
        state: {
          openGuide: open
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Atenção!',
        text: 'Ocorreu um erro ao executar a sua solicitação!'
      }).then(async () => {
        await Auth.signOut();
        history.push('/');
      })
    }
  }

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="c47aqvspo3wi7rm0zh3qiww6jzmdug" />
      </Helmet>
      <div className={classes.main}>
        <div className={classes.logo}>
          <img className={classes.img} src={LogoTextoClipo} />
        </div>
        <div className={classes.terms}>
          <h3 style={{ textAlign: "center", fontFamily: "Raleway", color: '#fff' }}>Bem vinda(o) à Clipo Saúde</h3>
          <p className={classes.message} style={{ paddingTop: "10px" }}>
            A partir de agora cuidar da sua saúde ficou mais fácil. Todos seus documentos consolidados em um único lugar!
            <br />
          </p>
          <p className={classes.message}>
            Agora você possui um e-mail pessoal da saúde que pode ser consultado no seu perfil! Com ele poderá solicitar para que o lugar onde realizou o exame envie o resultado direto para sua gaveta digital e nós cuidaremos do restante, organizando e avisando você quando o exame estiver disponível na sua plataforma.
            <br />
            <br />
            Facilidade e Autonomia para você cuidar da sua saúde.
            <br />
            <br />
            Clipo Saúde
          </p>
          <div className={classes.button}>
            <Button
              className={classes.buttonHome}
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: "#01A3FF",
                color: "white"
              }}
              onClick={() => handleForward(false)}
              size="large"
              startIcon={<HomeIcon />}
            >
              Página inicial
            </Button>
            <Button
              className={classes.buttonHelp}
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: "#FF8F23",
                color: "white",
                visibility: "hidden"
              }}
              size="large"
              onClick={() => handleForward(true)}
              startIcon={<HelpIcon />}
            >
              Aqui tem uma explicação para você!
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
