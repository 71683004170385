import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel, FormControl } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import { cnpj } from 'cpf-cnpj-validator';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import { maskPhoneNumber, maskCNPJ } from '../../utils/mask';
import api from '../../services/api';
import axios from 'axios';
import { convertTo64 } from '../../services/convertTo64';

import './styles.scss';
import { NameValidator } from '../../services/nameValidator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '25%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    span: {
      padding: "15px",
      color: "#FFFFFF",
      backgroundColor: "#00546B",
      borderRadius: "10px",
      margin: "15px 8px 15px 8px",
      '@media screen and (max-width: 991px)': {
        margin: "15px 0 15px 0",
      }
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    inputFile: {
      shrink: true
    }
  }),
);

interface Clinica {
  id: string;
  cnpj: string;
  responsavel: string;
}

interface ClinicaDTO {
  id: string;
}

interface CepMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CepMask(props: CepMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$"
    />
  );
}

interface State {
  cep: string;
}

function Clinica() {
  const classes = useStyles();
  const history = useHistory();
  const [id, setId] = useState("");
  const location = useLocation<ClinicaDTO>();

  const [ativo, setAtivo] = useState(true);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [email, setEmail] = useState('');
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState('');
  const [qtdeExames, setQtdeExames] = useState<number | null>(null);
  const [qtdeUsuarios, setQtdeUsuarios] = useState<number | null>(null);
  const [descricao, setDescricao] = useState('');
  const [visibility, setVisibility] = useState<VisibilityState>("hidden");
  const [values, setValues] = React.useState<State>({
    cep: '',
  });
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [CNPJ, setCnpj] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      const getData = async () => {

        const clinica = await api.get('/clinica/' + location.state.id);

        setId(clinica.data.id);
        setValues({
          ...values,
          cep : clinica.data.cep,
        });
        setCnpj(maskCNPJ(clinica.data.cnpj));
        setTelefoneComercial(clinica.data.telefoneComercial)
        setRazaoSocial(clinica.data.razaoSocial);
        setNomeFantasia(clinica.data.nomeFantasia);
        setInscricaoEstadual(clinica.data.inscricaoEstadual);
        setInscricaoMunicipal(clinica.data.inscricaoMunicipal);
        setResponsavel(clinica.data.responsavel);
        setEndereco(clinica.data.endereco);
        setBairro(clinica.data.bairro);
        setCidade(clinica.data.cidade);
        setEstado(clinica.data.estado);
        setEmail(clinica.data.email);
        setAtivo(clinica.data.ativo);
        setDescricao(clinica.data.descricao);
        setNumero(clinica.data.numero);
        setComplemento(clinica.data.complemento);
        setQtdeExames(clinica.data.qtdeExames);
        setQtdeUsuarios(clinica.data.qtdeUsuarios);
      };

      getData();
    }
  }, []);

  function buscaCep() {
    const numberPattern = /\d+/g;
    if (values.cep) {
      const cepNumber = values.cep.replace('.', '').replace('-', '');
      axios.get(`https://api.postmon.com.br/v1/cep/${cepNumber}`)
        .then(response => {
          console.log(response);
          if (response.status == 200) {
            setEndereco(response.data.logradouro);
            setBairro(response.data.bairro);
            setCidade(response.data.cidade);
            setEstado(response.data.estado);
          }
        })
        .catch(error => {
          console.log(error)
        });
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const nameValidator = new NameValidator();

    try {
      if (!CNPJ) {
        Swal.fire({
          title: 'Digite o cnpj!',
          icon: 'warning',
        });
        return;
      }

      if (!cnpj.isValid(CNPJ)) {
        Swal.fire({
          title: 'Número de CNPJ inválido!',
          icon: 'warning',
        });
        return;
      }

      let mensagem = "";


      const result = async () => {
        if (id !== "") {
          mensagem = "Registro atualizado com sucesso";
          const updatedClinica = await api.patch(`/clinica/${id}`, {
            id,
            cnpj: CNPJ,
            razaoSocial,
            nomeFantasia,
            inscricaoEstadual,
            inscricaoMunicipal,
            responsavel,
            cep: values.cep,
            endereco,
            bairro,
            cidade,
            estado,
            email,
            telefoneComercial: telefoneComercial,
            ativo,
            numero,
            complemento,
            qtdeExames,
            qtdeUsuarios,
          })

          return updatedClinica;

        } else {
          mensagem = "Cadastro realizado com sucesso";
          const createdClinica = await api.put(`/clinica`, {
            cnpj: CNPJ,
            razaoSocial,
            nomeFantasia,
            inscricaoEstadual,
            inscricaoMunicipal,
            responsavel,
            cep: values.cep,
            endereco,
            bairro,
            cidade,
            estado,
            email,
            telefoneComercial: telefoneComercial,
            ativo,
            numero,
            complemento,
            qtdeExames,
            qtdeUsuarios,
          })
          return createdClinica;

        }
      }

      await result();

      Swal.fire({
        title: mensagem,
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/clinicas');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Clínica</h1>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="hidden"
                    name="id"
                    value={id}
                  />
                  <div className={classes.span}>
                    <span>Dados Gerais</span>
                  </div>
                  <div className={classes.grid4}>
                    <TextField
                      id="outlined-basic"
                      label="CNPJ"
                      variant="outlined"
                      required
                      name="cnpj"
                      value={maskCNPJ(CNPJ)}
                      onChange={(e) => setCnpj(maskCNPJ(e.target.value))}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Razão Social"
                      variant="outlined"
                      required
                      name="razaoSocial"
                      value={razaoSocial}
                      onChange={(e) => { setRazaoSocial(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Nome Fantasia"
                      variant="outlined"
                      name="nomeFantasia"
                      required
                      value={nomeFantasia}
                      onChange={(e) => { setNomeFantasia(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Inscrição Estadual"
                      variant="outlined"
                      name="inscricaoEstadual"
                      required
                      value={inscricaoEstadual}
                      onChange={(e) => { setInscricaoEstadual(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid4}>
                    <TextField
                      id="outlined-basic"
                      required
                      label="Inscrição Municipal"
                      variant="outlined"
                      name="inscricaoMunicipal"
                      value={inscricaoMunicipal}
                      onChange={(e) => { setInscricaoMunicipal(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      required
                      label="Responsável"
                      variant="outlined"
                      name="responsavel"
                      value={responsavel}
                      onChange={(e) => { setResponsavel(e.target.value) }}
                    />
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Quantidade de exames"
                      variant="outlined"
                      name="qtdeExames"
                      required
                      value={qtdeExames}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => { setQtdeExames(Number(e.target.value)) }}
                    />
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Quantidade de usuários"
                      variant="outlined"
                      name="qtdeUsuarios"
                      required
                      value={qtdeUsuarios}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => { setQtdeUsuarios(Number(e.target.value)) }}
                    />
                  </div>
                  <div className={classes.span}>
                    <span>Endereço</span>
                  </div>
                  <div className={classes.grid3}>
                    <TextField
                      id="outlined-basic"
                      label="CEP"
                      variant="outlined"
                      required
                      name="cep"
                      InputProps={{
                        inputComponent: CepMask as any,
                      }}
                      value={values.cep}
                      onChange={handleChange}
                      onBlur={() => { buscaCep() }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Endereço"
                      variant="outlined"
                      disabled
                      name="endereco"
                      value={endereco}
                      onChange={(e) => { setEndereco(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Bairro"
                      variant="outlined"
                      disabled
                      name="bairro"
                      value={bairro}
                      onChange={(e) => { setBairro(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid4}>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Número"
                      variant="outlined"
                      required
                      name="numero"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={numero}
                      onChange={(e) => { setNumero(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Complemento"
                      variant="outlined"
                      name="complemento"
                      value={complemento}
                      onChange={(e) => { setComplemento(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Cidade"
                      variant="outlined"
                      disabled
                      name="cidade"
                      value={cidade}
                      onChange={(e) => { setCidade(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Estado"
                      variant="outlined"
                      disabled
                      name="estado"
                      value={estado}
                      onChange={(e) => { setEstado(e.target.value) }}
                    />
                  </div>
                  <div className={classes.span}>
                    <span>Contato</span>
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      id="outlined-basic"
                      label="E-mail"
                      type="email"
                      variant="outlined"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        label="Telefone Comercial"
                        variant="outlined"
                        required
                        name="telefoneComercial"
                        value={maskPhoneNumber(telefoneComercial)}
                        onChange={(e) => setTelefoneComercial(maskPhoneNumber(e.target.value))}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.button}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={ativo}
                          onChange={(e) => { setAtivo(!ativo) }}
                          color="primary"
                          name="ativo"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Status"
                    />
                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/clinicas') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Clinica;
