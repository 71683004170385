import React, { SVGProps, useState } from 'react';
import { Auth } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import { UIStore } from '../../UIStore';
import Swal from 'sweetalert2';

import './styles.scss';
import ModalExam from '../ModalExam';
import UserGuide from '../UserGuide';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

interface SideMenuProps extends SVGProps<SVGElement> {
  device: string;
  options: Option[];
}

interface Option {
  id?: string;
  title: string;
  class?: string;
  link: string;
  icon: SVGProps<SVGElement>
  button?: boolean;
  submenu?: SubMenuOption[]
}

interface SubMenuOption {
  title: string;
  link: string;
}

type ClickMenu = (device: string, title: string) => void;

const SideMenu: React.FC<SideMenuProps> = ({ device, options }) => {

  const nivel = UIStore.useState(s => s.nivelUser);
  const [menu, setMenu] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);
  const history = useHistory();
  

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpenGuide = () => {
    handleClose();
    setOpenGuide(true);
  }

  const handleCloseGuide = () => {
    setOpenGuide(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }


  const Logout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
      window.location.href = '/';
    } catch (error) {
      console.log('error signing out: ', error);
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao fazer logout, por favor tente novamente!',
        icon: 'error',
      });
    }
  }

  const clickMenu = (device: string, title: string) => {
    if (title == 'Sair') {
      Logout();
    }
    if (title == 'Tutorial') {
      history.push('/home');
      handleOpen();
    }
    setMenu(!menu);    
    if (device == 'mobile') {
      const container = document.getElementsByClassName("app-container")[0].id = '';
    }
  }

  const clickToggle = (menu: boolean) => {
    setMenu(menu);
    if (menu == true) {
      const container = document.getElementsByClassName("app-container")[0].id = '';
    } else {
      const container = document.getElementsByClassName("app-container")[0].id = 'compact-menu';
    }
  }

  const SubmenuOption = (option: SubMenuOption, index: number, clickMenu: ClickMenu, device: string) => (
    <ul className="submenu" key={index}>
      <li className="itemSubmenu">
        <NavLink
          activeClassName="active"
          onClick={() => { clickMenu(device, option.title) }}
          to={option.link}>
          <span>{option.title}</span>
        </NavLink>
      </li>
    </ul>
  );

  const Option = (option: Option, index: number, clickMenu: ClickMenu, device: string) => (
    <li className={`itemMenu + ${option.class ? option.class : ""}`} key={index}>
      {option.button === undefined &&
        <NavLink
          id={option.id !== undefined ? option.id : ""}
          activeClassName="active"
          className={option.submenu !== undefined ? "disable-link" : ""}
          aria-disabled={true}
          onClick={() => { clickMenu(device, option.title) }}
          to={option.link}>{option.icon}
          <span>{option.title}</span>
        </NavLink>
      }

      {option.button &&
        <Button
          id={option.id !== undefined ? option.id : ""}
          className={option.submenu !== undefined ? "disable-link" : ""}
          aria-disabled={true}
          onClick={() => { clickMenu(device, option.title) }}
        >
          {option.icon}
          <span>{option.title.toLowerCase()}</span>
        </Button>
      }



      {option.submenu?.map((option, index) => SubmenuOption(option, index, clickMenu, device))}
    </li>
  );


  return (
    <>
      <button className="toggle" onClick={() => clickToggle(!menu)}><i></i></button>
      <aside className={`side-menu${nivel === 'paciente' ? ' side-menu-paciente' : ''}`}>
        <div className="menu-content">
          <ul>
            {options.map((option, index) =>
              Option(option, index, clickMenu, device)
            )}
          </ul>
        </div>
      </aside>
      <ModalExam
        src=""
        user={true}
        url=""
        open={open}
        id=""
        arquivo=""
        handleOpen={handleOpenGuide}
        handleClose={handleClose}
        body=""
      />
      <UserGuide
        open={openGuide}
        handleClose={handleCloseGuide}
        handleCloseTopMenu={handleCloseGuide}
        step={0}
      />
    </>
  );
}

export default SideMenu;
