import React from 'react';
import { CircularProgress, Modal } from '@material-ui/core';

import './styles.scss';

export const WindowLoading = (props: {loading: boolean}) => {
  return (
    <div>
      <Modal open={props.loading} ><div className="window-loading"><CircularProgress className="progress" size={'60px'} /></div></Modal>
    </div>
  )
}
