import React, { useState, useEffect, FormEvent, SelectHTMLAttributes } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import MaskedInput from 'react-text-mask';
import validator from 'cpf-cnpj-validator';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import { format, formatISO } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';

import api from '../../services/api';

import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface DataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function DataMask(props: DataMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface Dependente {
  id: string;
  nome: string;
  sobrenome: string;
  responsavel: string;
  corPredominante: string;
  tipoSanguineo: string;
  peso: number;
  familia: object;
}

interface ParamTypes {
  id: string;
}

interface CpfMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CpfMask(props: CpfMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface State {
  peso: number;
  cpf: string;
  tipoSanguineo: string;
  fatorRh: string;
}

function DependenteVisualizacao() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idDependente, setIdDependente] = useState({} as Dependente);
  const [aprovado, setAprovado] = useState<boolean | null>(null)
  const [nome, setNome] = useState('');
  const [sobrenome, setSobreNome] = useState('');
  const [tipoSanguineo, setTipoSanguineo] = useState('');
  const [fatorRh, setFatorRh] = useState('');
  const [sexo, setSexo] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [parentescoId, setParentescoId] = React.useState('');
  const [parentescos, setParentescos] = React.useState([]);
  const [dataNascimento, setDataNascimento] = useState('');
  const [nomeResponsavel, setNomeResponsavel] = useState('');
  const [cpfResponsavel, setCpfResponsavel] = useState('');
  const [emailResponsavel, setEmailResponsavel] = useState('');

  const [values, setValues] = React.useState<State>({
    peso: 0,
    cpf: '',
    tipoSanguineo: '',
    fatorRh: '',
  });

  const Joi = require('@hapi/joi').extend(validator);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
    }
  }

  useEffect(() => {
    const getParentescos = async () => {
      const parentescos = await api.get('/parentesco');
      setParentescos(parentescos.data);
    };

    getParentescos();

    if (id) {
      const getData = async () => {
        const userTokens = await Auth.currentSession();

        const response = await api.get(`/dependente/${id}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });
        setIdDependente(response.data.id);
        setNome(response.data.nome);
        setSobreNome(response.data.sobrenome);
        setValues({
          ...values,
          cpf: response.data.cpf,
          peso: response.data.peso,
        });
        setTipoSanguineo(response.data.tipoSanguineo);
        setFatorRh(response.data.fatorRh);
        setDataNascimento(format(new Date(response.data.dataNascimento), 'dd/MM/yyyy', { locale: dateFnsPtBR }));
        setParentescoId(response.data.parentesco.id);
        setSexo(response.data.sexo);
        setAtivo(response.data.ativo);
        setAprovado(response.data.aprovado);

        const responsavel = await api.get(`/usuario/cpf/${response.data.responsavel.cpf}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });
        setNomeResponsavel(`${responsavel.data.name} ${responsavel.data.family_name}`);
        setCpfResponsavel(responsavel.data.cpf);
        setEmailResponsavel(responsavel.data.email);
      };

      getData();
    }
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    Swal.fire({
      title: 'Erro!',
      text: 'Você não pode editar um dependente!',
      icon: 'error',
    });
    return;

  }

  const handleAprovar = async (id: string) => {
    const userTokens = await Auth.currentSession();

    Swal.fire({
      title: 'Você tem certeza que deseja aprovar o dependente!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Aprovar!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const aprovarConfirmed = async () => {
            const result = await api.post(`/dependente/${id}/aprovar`, {
              aprovado: true,
            }, {
              headers: {
                'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
              }
            });
            const response = await api.get('/dependente', {
              headers: {
                'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
              }
            });
            history.push('/dependentes')
          }

          aprovarConfirmed();
        } catch (error) {
          console.log(`erroou: ${error}`);
        }
      }
    });
  };

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Titular</h1>
                <div className={classes.grid3}>
                  <TextField
                    id="outlined-basic"
                    label="Nome Completo"
                    variant="outlined"
                    required
                    name="nomeResponsavel"
                    value={nomeResponsavel}
                    disabled
                  />
                  <TextField
                    id="outlined-basic"
                    label="CPF"
                    variant="outlined"
                    required
                    name="cpfResponsavel"
                    InputProps={{
                      inputComponent: CpfMask as any,
                    }}
                    value={cpfResponsavel}
                    disabled
                  />
                  <TextField
                    id="outlined-basic"
                    label="E-mail"
                    variant="outlined"
                    required
                    name="emailResponsavel"
                    value={emailResponsavel}
                    disabled
                  />
                </div>
                <h1>Dependente</h1>
                <form onSubmit={handleSubmit}>
                  <div className={classes.grid3}>
                    <TextField
                      id="outlined-basic"
                      label="Nome"
                      variant="outlined"
                      required
                      name="nome"
                      value={nome}
                      disabled
                    />
                    <TextField
                      id="outlined-basic"
                      label="Sobrenome"
                      variant="outlined"
                      required
                      name="sobrenome"
                      value={sobrenome}
                      disabled
                    />
                    <TextField
                      id="outlined-basic"
                      label="CPF"
                      variant="outlined"
                      required
                      name="cpf"
                      InputProps={{
                        inputComponent: CpfMask as any,
                      }}
                      value={values.cpf}
                      disabled
                    />
                  </div>
                  <div className={classes.grid3}>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Peso"
                      variant="outlined"
                      name="peso"
                      value={values.peso}
                      disabled
                    />
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Tipo Sanguineo</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoSanguineo}
                        disabled
                        label="Tipo Sanguineo"
                      >
                        <MenuItem value="A">A</MenuItem>
                        <MenuItem value="B">B</MenuItem>
                        <MenuItem value="AB">AB</MenuItem>
                        <MenuItem value="AB">O</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Fator RH</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={fatorRh}
                        disabled
                        label="Fato RH"
                      >
                        <MenuItem value="+">+</MenuItem>
                        <MenuItem value="-">-</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.grid}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Data de Nascimento"
                        variant="outlined"
                        className="dataNascimento"
                        name="dataNascimento"
                        InputProps={{
                          inputComponent: DataMask as any,
                        }}
                        value={dataNascimento}
                        disabled
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Parentesco</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={parentescoId}
                        disabled
                        label="Parentesco"
                      >
                        {
                          parentescos.map((row: any, index) => {
                            return (
                              <MenuItem value={row.id}>{row.nome}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Sexo</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={sexo}
                        disabled
                        label="Gênero"
                      >
                        <MenuItem value="Masculino">Masculino</MenuItem>
                        <MenuItem value="Feminino">Feminino</MenuItem>
                        <MenuItem value="Outro">Outro</MenuItem>
                        <MenuItem value="Prefiro Não Responder">Prefiro Não Responder</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.button}>
                    {aprovado === null &&
                      <Button
                        variant="contained"
                        color="primary"
                        className="green-button"
                        size="large"
                        startIcon={<SaveIcon />}
                        onClick={() => handleAprovar(id)}
                      >
                        Aprovar
                      </Button>
                    }
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/usuario/dependentes') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default DependenteVisualizacao;
