import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Modal } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { IconButton } from '@material-ui/core';
import { useStyles } from './styles';

interface ModalProps {
  open: boolean;
  url: string;
  onClose: () => void;
  onOpen?: () => void;
}

export const ViewerPDFModal = (props: ModalProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(isMobile ? 0.6 : 1);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPageNumber(1);
    setOpen(props.open);
  }, [props.open]);

  function handleLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function handleZoomIn() {
    let zoomIn = scale + 0.5;
    setScale(zoomIn);
  }

  function handleZoomOut() {
    let zoomOut = scale - 0.5;
    setScale(zoomOut);
  }

  const handleLoad = () => (
    <div>
      <h3 className={classes.loading}>Carregando arquivo...</h3>
    </div>
  );

  const handleError = () => (
    <div>
      <h3 className={classes.loading}>Falha ao carregar o arquivo!</h3>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={props.onClose}
      aria-labelledby="document-title"
      aria-describedby="document-description"
    >
      <>
        <IconButton onClick={props.onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <div
          id="document-description"
          className={classes.modal}
          style={{
            maxHeight: '80%',
          }}
        >
          <Document
            file={props.url}
            loading={handleLoad}
            noData={handleLoad}
            onLoadError={handleError}
            renderMode="svg"
            onLoadSuccess={handleLoadSuccess}
          >
            <Page
              scale={scale}
              className={classes.display}
              pageNumber={pageNumber}
            />
          </Document>
          <div className={classes.page}>
            <button
              className={classes.button}
              type="button"
              disabled={scale <= 0.5}
              onClick={handleZoomOut}
            >
              <ZoomOutIcon className={classes.iconButton} />
            </button>

            <button
              className={classes.button}
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              {'<'}
            </button>
            <span className={classes.pagination}>
              {pageNumber || '--'} de {numPages || '--'}
            </span>

            <button
              className={classes.button}
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              {'>'}
            </button>
            <button
              className={classes.button}
              type="button"
              onClick={handleZoomIn}
            >
              <ZoomInIcon className={classes.iconButton} />
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
