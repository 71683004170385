import React, { FormEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableData from '../../components/TableData';
import { Button, FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { states } from '../../utils/StatesJSON';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import api from '../../services/api';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    display_button: {
      display: "grid"
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    display: {
      display: "inline-block"
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridGap: '16px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    title: {
      padding: '5px 20px 0px 20px',
      gridRowStart: 1,
      gridRowEnd: 1
    },
    fields: {

    },
    container: {
      gridTemplateColumns: "[first] 40px [line2] 50px [line3] auto [col4-start] 50px [five] 40px [end]",
      gridTemplateRows: "[row1-start] 25% [row1-end] 100px [third-line] auto [last-line]",
      marginBottom: "10px",
      marginTop: "10px",
      backgroundColor: "#FFF",
      paddingTop: "10px",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
    select: {
      paddingRight: "0px !important"
    }
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}
interface Data {
  cnpj: string;
  nomeFantasia: string;
  email: string;
  opcoes: string;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}
interface CityDTO {
  id: number;
  nome: string;
}

interface StateDTO {
  code: number;
  uf: string;
  description: string;
}

export default function BuscaClinica() {
  const classes = useStyles();
  const [url, setUrl] = useState("/clinica");
  const [clinics, setClinics] = useState([]);
  const [cities, setCities] = useState<Array<CityDTO>>([]);
  const [nameSearch, setNameSearch] = useState("");
  const [cityDTO, setCityDTO] = useState<CityDTO | null>();
  const [stateDTO, setStateDTO] = useState<StateDTO>();
  const [stateSearch, setStateSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");

  const getData = async (url?: string) => {
    var apiUrl = url || '/clinica';

    const response = await api.get(apiUrl);
    setClinics(response.data);
  };

  const clinicsTable: HeadCell[] = [
    { id: 'cnpj', numeric: false, disablePadding: true, label: 'CNPJ' },
    { id: 'nomeFantasia', numeric: false, disablePadding: true, label: 'Nome Fantasia' },
    { id: 'email', numeric: false, disablePadding: true, label: 'E-mail' },
    { id: 'opcoes', numeric: false, disablePadding: true, label: '' },
  ];

  useEffect(() => {
    getData();
  }, []);

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Clínicas
        </Typography>
        <Tooltip title="INDIQUE UMA CLÍNICA">
          <IconButton aria-label="Cadastrar">
            <NavLink to="clinica"><AddIcon /></NavLink>
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  };

  const handleChangeState = async (value: StateDTO | null) => {
    setCities([]);
    if (value !== null) {
      console.log("aqui")
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value.code}/municipios`);
      const newArr = response.data.map((city: CityDTO) => {
        return city
      });
      setStateDTO(value);
      setStateSearch(value.uf);
      setCities(newArr);
    }
  }

  const handleChangeCity = (value: CityDTO | null) => {
    if (value !== null) {
      setCityDTO(value);
      setCitySearch(value.nome);
    }
  }

  const handleSearch = (event: FormEvent) => {
    console.log('aqui');
    event.preventDefault();

    const newUrl = `clinica?${nameSearch !== '' ? `nomeFantasia=${nameSearch}&` : ''}
    ${stateSearch !== '' ? `estado=${stateSearch}&` : ''}${citySearch !== '' ? `cidade=${citySearch}` : ''}`

    setUrl(newUrl);
  }

  return (
    <main>
      <div className={classes.root}>
        <div className={classes.container}>
          <form onSubmit={handleSearch}>
            <div className={classes.title}>
              <h1>Busque pelas clínicas da sua região</h1>
            </div>
            <div className={classes.filtrosForm}>
              <FormControl
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: 3
                }}
              >
                <InputLabel htmlFor="nameSearch">Digite o nome da clínica</InputLabel>
                <Input
                  className="nameSearch"
                  name="nameSearch"
                  id="nameSearch"
                  value={nameSearch}
                  onChange={(event) => setNameSearch(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  options={states}
                  getOptionLabel={(option) => option.description}
                  value={stateDTO}
                  onChange={(event, value) => handleChangeState(value)}
                  renderInput={(params) => <TextField {...params} label="Selecione o estado" />}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  options={cities}
                  value={cityDTO}
                  onChange={(event, value) => handleChangeCity(value)}
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => <TextField {...params} label="Selecione a cidade" />}
                />
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                className="no-text"
              >
                Pesquisar
              </Button>
            </div>
          </form>
        </div>
        <TableData
          type="clinics"
          url={url}
          headCells={clinicsTable}
          order='cnpj'
          EnhancedTableToolbar={EnhancedTableToolbar}
        />
      </div>
    </main>
  )
};
