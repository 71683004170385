import React, { useState, FormEvent, useEffect, Fragment } from 'react';
import Swal from 'sweetalert2';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import validator from 'cpf-cnpj-validator';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Termos from '../../components/Termos';
import { maskCPF, maskPhoneNumber, maskName } from '../../utils/mask'
import './styles.scss';
import grafismo from '../../assets/images/grafismo-user.png';
import { useHistory } from 'react-router-dom';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import { Input, Select, MenuItem, FormControl, InputLabel, Button, makeStyles, createStyles, Theme, CircularProgress } from '@material-ui/core';
import api from '../../services/api';
import SocialLogin from '../../components/SocialLogin';
import LoginScreenButton from '../../components/LoginScreenButton';
import { findNameByCpfAndBirthDate } from '../../services/hubDevCpf';
import { ErrorOutlineRounded } from '@material-ui/icons';
import { FaCheckCircle } from 'react-icons/fa';
import { ImRadioUnchecked } from 'react-icons/im';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      color: '#ccc',
      marginBottom: "40px",

      ['@media (max-width: 455px)']: {
        color: '#fff',
      }
    },
    checked: {
      "&$checked": { color: "#01A3FF" },
      ['@media (max-width: 455px)']: {
        "&$checked": { color: "#fff" }
      }
    }
  })
);


function CadastroPaciente() {
  const [username, setUsername] = useState('');
  const [confirmUsername, setConfirmUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [cpf, setCpf] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);
  const [confirmationPasswordIcon, setConfirmationPasswordIcon] = useState(<ShowEye />);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [loadingName, setLoadingName] = React.useState(false);
  const [disabled, setDisabled] = useState(true);
  const [planoId, setPlanoId] = useState('');
  const [loading, setLoading] = useState(false);

  const [signTermo, setSignTermo] = React.useState(false);
  const [signPolitica, setSignPolitica] = React.useState(false);
  const [tipo, setTipo] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const Joi = require('@hapi/joi').extend(validator);
  const cpfSchema = Joi.document().cpf().required();
  const classes = useStyles()

  const handleOpen = (tipo: string) => {
    setTipo(tipo);
    setOpen(true);
  };

  const handleClose = () => {
    setTipo("");
    setOpen(false);
  };

  useEffect(() => {

    const getPlan = async () => {
      const response = await api.get('/plano/familiar');
      setPlanoId(response.data.id);
    }
    getPlan();
  }, []);

  const checkPasswordLength = (password: string) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  }

  const checkSpecialCharacters = (password: string) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  }

  const checkUppercase = (password: string) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  }

  const checkLowercase = (password: string) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }
  }

  const checkNumber = (password: string) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  }

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkLowercase(event.target.value);
    checkNumber(event.target.value);
  };

  const history = useHistory();

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  function handleConrimationPassword() {
    setShowConfirmationPassword(!showConfirmationPassword);
    if (showConfirmationPassword) {
      setConfirmationPasswordIcon(<ShowEye />);
    } else {
      setConfirmationPasswordIcon(<HideEye />);
    }
  }

  async function handleName() {
    setFullName('');
    try {
      if (cpf && birthdate) {
        setLoadingName(true);
        const name = await findNameByCpfAndBirthDate(cpf, birthdate);
        setFullName(name);
        setDisabled(true);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        setDisabled(false);
      } else {
        // setCpf('');
        // setBirthdate(null);
        setDisabled(false);
        setFullName('');
        Swal.fire({
          title: 'Erro!',
          text: error.message,
          icon: 'error',
        });
      }
    } finally {
      setLoadingName(false);
    }
  }

  async function signUp(e: FormEvent) {
    e.preventDefault();

    try {
      await cpfSchema.validateAsync(cpf);
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'CPF inválido!',
        icon: 'error',
      });
      return;
    }

    if (fullName.trim() === '') {
      Swal.fire({
        title: 'Erro!',
        text: 'É necessário que o campo de nome completo esteja preenchido!',
        icon: 'error',
      });
      return;
    }

    if (confirmUsername !== username) {
      Swal.fire({
        title: 'Erro!',
        text: 'É necessário que o email seja idêntico a confirmação de email!',
        icon: 'error',
      });
      return;
    }

    if (confirmationPassword !== password) {
      Swal.fire({
        title: 'Erro!',
        text: 'É necessário que a senha seja idêntica a confirmação de senha!',
        icon: 'error',
      });
      return;
    }

    if (String(birthdate) === 'Invalid Date') {
      Swal.fire({
        title: 'Erro!',
        text: 'Data de nascimento informada não é inválida!',
        icon: 'error',
      });
      return;
    }

    if (phoneNumber.length !== 0 && phoneNumber.replace(/\D/g, '').length !== 11 && phoneNumber.replace(/\D/g, '').length !== 10) {
      return Swal.fire({
        title: 'Erro!',
        text: 'Telefone inválido!',
        icon: 'error',
      });
    }

    if (password.match(/^(?=.*\d)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/) === null) {
      return Swal.fire({
        title: 'Erro!',
        text: 'Sua senha não contém os caracteres necessários!',
        icon: 'error',
      });
    }

    if (!signPolitica || !signTermo) {
      Swal.fire({
        title: 'Erro!',
        text: 'É necessário estar de acordo com as condições dos Termos de Uso e da Política de Privacidade para continuar.',
        icon: 'error',
      });
      return;
    }

    if (phoneNumber) {
      const confirmPhone = await Swal.fire({
        title: 'Confirmar número de telefone',
        html: `Para sua segurança precisamos confirmar o número do seu celular. Este número está correto? <br><br> <b>${phoneNumber}</b>`,
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#34af23',
        cancelButtonColor: '#F4A39D',
        cancelButtonText: 'Corrigir'
      });
      
      if (!confirmPhone.isConfirmed) {
        return;
      }
    }


    try {
      const nameArray = fullName.split(' ');
      const name = nameArray.shift();
      const family_name = nameArray.join(' ');
      setLoading(true);

      await api.post('/usuario', {
        password,
        cpf: cpf.replace(/\D/g, ''),
        email: username,
        birthdate,
        name,
        family_name,
        phone_number: phoneNumber !== '' ? "+55" + phoneNumber.replace(/\D/g, '') : '',
      });

      localStorage.setItem('emailPaciente', username);
      localStorage.setItem('cpfPaciente', cpf.replace(/\D/g, ''));

      await api.put('consentimento', {
        termos: true,
        politica: true,
        email: username
      });
      history.push('/confirma');
    } catch (error) {
      console.log(error.response);
      Swal.fire({
        title: 'Erro!',
        text: error.response.data.detail,
        icon: 'error',
      });
      
      setLoading(false)
      localStorage.removeItem('emailPaciente');
    }
  }
  return (
    <>
      <LoginScreenButton />
      <div id="cadastroPaciente">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <a href="/">
                <img src={LogoTextoClipo} className="logo"></img>
              </a>
              <div className="info-login">
                <span className="message-social">Cadastre-se gratuitamente usando suas redes sociais</span>
              </div>
              <div className="social-login">
                <SocialLogin />
              </div>
              <div className="separator info-login"><span>ou</span></div>
              <div className="info-login">
                <span className="message-social"> Cadastre suas informações no formulário abaixo gratuitamente</span>
              </div>
              <form action="" className="materializeForm" onSubmit={signUp}>
                <div className="container-pass">
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="cpf">CPF</InputLabel>
                    <Input
                      fullWidth
                      required
                      className="cpf"
                      name="cpf"
                      id="cpf"
                      value={cpf}
                      onBlur={handleName}
                      onChange={(e) => { setCpf(maskCPF(e.target.value)) }}
                    />
                  </FormControl>
                  <FormControl fullWidth required>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableFuture
                        className="birthDate"
                        openTo="year"
                        format="dd/MM/yyyy"
                        label="Data de nascimento"
                        views={["year", "month", "date"]}
                        value={birthdate}
                        onBlur={handleName}
                        onChange={(value) => {
                          setBirthdate(value);
                          setError(false);
                        }}
                        invalidDateMessage={"Inserir uma data válida"}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </div>
                <div className="container-pass">
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="name">{
                      !loadingName ? <span>{
                        disabled ? <>Nome completo</> : <>Nome completo (sem abreviações)</>
                      }</span> : <i className="loading-name">Buscando nome. Por favor, aguarde . . . </i>
                    }</InputLabel>
                    <Input
                      type="text"
                      fullWidth
                      disabled={disabled}
                      required
                      className="name"
                      name="name"
                      id="name"
                      value={fullName}
                      onChange={(e) => { setFullName(maskName(e.target.value)) }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="phone_number">Telefone celular</InputLabel>
                    <Input
                      fullWidth
                      className="phone_number"
                      name="phone_number"
                      id="phone_number"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(`${maskPhoneNumber(e.target.value)}`);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="container-pass">
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="username">E-mail</InputLabel>
                    <Input
                      fullWidth
                      required
                      className="username"
                      name="username"
                      id="username"
                      value={username}
                      onChange={(e) => { setUsername(e.target.value) }}
                      type="email"
                    />
                  </FormControl>
                </div>
                <div className="container-pass">
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="confirmUsername">Confirmação de email</InputLabel>
                    <Input
                      fullWidth
                      required
                      className="confirmUsername"
                      name="confirmUsername"
                      id="confirmUsername"
                      value={confirmUsername}
                      onChange={(e) => { setConfirmUsername(e.target.value) }}
                      type="email"
                    />
                  </FormControl>
                </div>
                <div className="container-pass">
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="password">Senha</InputLabel>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      id="password"
                      value={password}
                      autoComplete="off"
                      onChange={(e) => { handlePasswordChange(e) }}
                      required
                    />
                    <a onClick={(e) => { handlePassword() }}>{passwordIcon}</a>
                  </FormControl>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="confirmationPassword">Confirmação de senha</InputLabel>
                    <Input
                      type={showConfirmationPassword ? 'text' : 'password'}
                      name="confirmationPassword"
                      id="confirmationPassword"
                      value={confirmationPassword}
                      autoComplete="off"
                      onChange={(e) => { setConfirmationPassword(e.target.value) }}
                      required
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                    <a onClick={(e) => { handleConrimationPassword() }}>{confirmationPasswordIcon}</a>
                  </FormControl>
                </div>
                <div className="validation">
                  <div className="validator">
                    {charNumberValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">Mínimo 8 caracteres</p>
                  </div>
                  <div className="validator">
                    {specialCharValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 caracter especial</p>
                  </div>
                  <div className="validator">
                    {uppercaseValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 letra maiúscula</p>
                  </div>
                  <div className="validator">
                    {lowercaseValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 letra minúscula</p>
                  </div>
                  <div className="validator">
                    {numberValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 número</p>
                  </div>
                </div>
                <div>
                  <div className="termosSpan">
                  <Checkbox
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked
                      }}
                      icon={<ImRadioUnchecked />}
                      checkedIcon={<FaCheckCircle />}
                    checked={signTermo}
                    onChange={(e) => { setSignTermo(!signTermo) }}
                    name="termo"
                    color="primary"
                  />
                  <span>
                    Declaro que li e estou de acordo com as condições dos <a onClick={() => handleOpen("termos")}>Termos de Uso.</a>
                  </span>
                </div>
                </div>
                <div>
                  <div className="termosSpan">
                  <Checkbox
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked
                      }}
                      icon={<ImRadioUnchecked />}
                      checkedIcon={<FaCheckCircle />}
                    checked={signPolitica}
                    onChange={(e) => { setSignPolitica(!signPolitica) }}
                    name="termo"
                    color="primary"
                  />
                  <span>
                    Declaro que li e estou de acordo com as condições da <a onClick={() => handleOpen("politica")}>Política de Privacidade.</a>
                  </span>
                  <Termos
                    tipo={tipo}
                    handleClose={handleClose}
                    open={open}
                  />
                </div>
                </div>
                {loading ? 
                  <button className="submitButton" disabled><CircularProgress size={24} /></button>
                :
                  <button className="submitButton">Cadastrar</button>
                }
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CadastroPaciente;
