import React, { FormEvent, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ViewIcon from '@material-ui/icons/Visibility';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import { Button, FormControl, FormGroup, Input, InputLabel } from '@material-ui/core';
import { maskCPF } from '../..//utils/mask';

import { NavLink, useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

import api from '../../services/api';
import ModalExam from '../../components/ModalExam';

interface Data {
  nome: string;
  sobrenome: string;
  cpf: string;
  status: string;
  opcoes: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'cpf', numeric: false, disablePadding: true, label: 'CPF' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'opcoes', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selecionado(s)
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Dependentes
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : ""}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitSpacing:{
      marginTop: "0px",
      paddingTop: "0px",
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridGap: '16px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    container: {
      marginBottom: "10px",      
      paddingTop: "10px",
      backgroundColor: "#FFF",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
    filterButton: {
      width: "100%",
      margin: "theme.spacing(1)"
    },
    label: {
      color: "#000000"
    },
  }),
);

export default function DependentesAprovacao() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('nome');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState('');
  const [arquivo, setArquivo] = React.useState('');
  const [body, setBody] = React.useState('');
  const [src, setSrc] = React.useState("");

  const [rows, setRows] = React.useState([]);

  const [nameSearch, setNameSearch] = React.useState('');
  const [cpfSearch, setCpfSearch] = React.useState('');
  const [dependentesAprovados, setDependentesAprovados] = React.useState(false);
  const [dependentesReprovados, setDependentesReprovados] = React.useState(false);
  const [dependentesPendentes, setDependentesPendentes] = React.useState(false);

  useEffect(() => {
    const getData = async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get('/dependente', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      if (response.data.length > 0) {
        setRows(response.data);
      } else {
        setRows([]);
      }
    };

    getData();
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleAprovar = async (id: string, cpf: string) => {
    const userTokens = await Auth.currentSession();
    Swal.fire({
      title: 'Você tem certeza que deseja aprovar o dependente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Aprovar!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const approveUser = () => {
            return api.post(`/dependente/${id}/aprovar`, {
              aprovado: true,
            }, {
              headers: {
                'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
              }
            });
          }

          const assignExams = () => {
            return api.put(`/exame/atribuir/${cpf.replace(/\D/g, '')}`, {}, {
              headers: {
                'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
              }
            });
          }

          Promise.all([approveUser(), assignExams()])
            .then(function (results) {
              Swal.fire({
                title: "Dependente aprovado com sucesso!",
                icon: 'success',
                focusConfirm: false,
                confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Ocorreu um erro ao executar a ação solicitada!",
                icon: 'error',
                focusConfirm: false,
                confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            });
        } catch (error) {
          Swal.fire({
            title: 'Erro!',
            text: error,
            icon: 'error',
          });
        }
      }
    });
  };

  const handleReprovar = async (id: string) => {
    const userTokens = await Auth.currentSession();

    Swal.fire({
      title: 'Por que deseja reprovar o dependente?',
      icon: 'warning',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Reprovar!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          api.post(`/dependente/${id}/reprovar`, {
            mensagem: result.value,
            aprovado: false,
          }, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          })
            .then(function (response) {
              Swal.fire({
                title: "Dependente reprovado com sucesso!",
                icon: 'success',
                focusConfirm: false,
                confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Ocorreu um erro ao executar a ação solicitada!",
                icon: 'error',
                focusConfirm: false,
                confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            });
        } catch (error) {
          Swal.fire({
            title: 'Erro!',
            text: error,
            icon: 'error',
          });
        }
      }
    });
  };

  const handleOpen = (id: string, arquivo: string): void => {
    setOpen(true);
    setId(id);
    setArquivo(arquivo);
  }

  const handleClose = () => {
    setOpen(false);
    setId("");
    setArquivo("");
    setBody("");
    setSrc("");
  }

  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();
    const userTokens = await Auth.currentSession();

    const url = `/dependente?${nameSearch !== '' ? `nome=${nameSearch}&` : ''}` +
      `${cpfSearch !== '' ? `cpf=${cpfSearch.replace(/\D/g, '')}&` : ''}` +
      `${dependentesAprovados ? `aprovado=true&` : ``}` +
      `${dependentesReprovados ? `reprovado=true&` : ``}` +
      `${dependentesPendentes ? `pendente=true&` : ``}`;

    const response = await api.get(url, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      }
    });

    if (response.data.length > 0) {
      setRows(response.data);
    } else {
      setRows([]);
    }
  }

  return (
    <main className={classes.fitSpacing}>
      <div className={classes.root}>
        <div className={classes.container}>
          <form className={classes.filtrosForm} onSubmit={handleSearch}>
            <FormControl>
              <InputLabel htmlFor="cpfSearch">Digite o nome do dependente</InputLabel>
              <Input
                name="nameSearch"
                id="nameSearch"
                value={nameSearch}
                onChange={(e) => { setNameSearch(e.target.value) }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="cpfSearch">Digite o CPF</InputLabel>
              <Input
                name="cpfSearch"
                id="cpfSearch"
                value={cpfSearch}
                onChange={(e) => { setCpfSearch(maskCPF(e.target.value)) }}
              />
            </FormControl>
            <FormControl>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value={true}
                  control={<Checkbox color="primary" />}
                  label="Aprovados"
                  labelPlacement="end"
                  classes={{
                    root: classes.label
                  }}
                  onChange={() => setDependentesAprovados(!dependentesAprovados)}
                />
                <FormControlLabel
                  value={true}
                  control={<Checkbox color="primary" />}
                  label="Reprovados"
                  labelPlacement="end"
                  classes={{
                    root: classes.label
                  }}
                  onChange={() => setDependentesReprovados(!dependentesReprovados)}
                />
                <FormControlLabel
                  value={true}
                  control={<Checkbox color="primary" />}
                  label="Pendentes"
                  labelPlacement="end"
                  classes={{
                    root: classes.label
                  }}
                  onChange={() => setDependentesPendentes(!dependentesPendentes)}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <Button
                className={classes.filterButton}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SearchIcon />}
                type="submit"
              >Pesquisar</Button>
            </FormControl>
          </form>
        </div>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> */}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.nome + " " + row.sobrenome}
                        </TableCell>
                        <TableCell>
                          {row.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
                        </TableCell>
                        <TableCell>
                          {row.aprovado ? 'Aprovado' : (row.aprovado === null ? 'Aguardando Aprovação' : 'Reprovado')}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="VISUALIZAR DOCUMENTO">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<DescriptionIcon />}
                              onClick={() => handleOpen(row.id, row.arquivo)}
                              className="no-text"
                            ></Button>
                          </Tooltip>
                          <NavLink to={"/usuario/dependente/" + row.id}>
                            <Tooltip title="VISUALIZAR">
                              <Button
                                variant="contained"
                                color="primary"
                                className="no-text"
                                startIcon={<ViewIcon />}
                              ></Button>
                            </Tooltip>
                          </NavLink>
                          {row.aprovado === null &&
                            <>
                              <Tooltip title="REPROVAR">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="no-text red-button"
                                  startIcon={<CancelIcon />}
                                  onClick={() => handleReprovar(row.id)}
                                ></Button>
                              </Tooltip>
                              <Tooltip title="APROVAR">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="no-text green-button"
                                  startIcon={<CheckIcon />}
                                  onClick={() => handleAprovar(row.id, row.cpf)}
                                ></Button>
                              </Tooltip>
                            </>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows, display: 'none' }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <ModalExam
        src={src}
        user={true}
        open={open}
        id={id}
        url="/dependente/:id/documento"
        arquivo={arquivo}
        handleClose={handleClose}
        body={body}
      />
    </main>
  );
}
