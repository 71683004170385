import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../services/api';

import './styles.scss';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface Categoria {
  id: string;
  nome: object;
}

interface ParamTypes {
  id: string;
}

export default function ParteCorpo() {
  const params = useParams<ParamTypes>();
  const [id, setId] = useState(params.id);
  const [idCategoria, setIdCategoria] = useState({} as Categoria);
  const [nome, setNome] = useState('');
  const classes = useStyles();
  const history = useHistory();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const userTokens = await Auth.currentSession();
    try {
      if (!nome) {
        Swal.fire({
          title: 'Digite o nome!',
          icon: 'warning',
        });
        return;
      }
      if (id) {
        await api.put(
          `/parte-do-corpo/${id}`,
          {
            nome,
          },
          {
            headers: {
              'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
            },
          },
        );
      } else {
        await api.post(
          '/parte-do-corpo',
          {
            nome,
          },
          {
            headers: {
              'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
            },
          },
        );
      }

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos',
      }).then(result => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/partesdocorpo');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }
  }

  useEffect(() => {
    if (id) {
      const getData = async () => {
        const userTokens = await Auth.currentSession();

        const response = await api.get('/parte-do-corpo/' + id, {
          headers: {
            'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
          },
        });
        setIdCategoria(response.data.id);
        setNome(response.data.nome);
      };

      getData();
    }
  }, []);

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Parte do Corpo</h1>
                <form onSubmit={handleSubmit}>
                  <Input type="hidden" name="id" value={idCategoria} />
                  <div className={classes.root}>
                    <TextField
                      id="outlined-basic"
                      label="Parte do Corpo"
                      variant="outlined"
                      name="nome"
                      value={nome}
                      onChange={e => {
                        setNome(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => {
                        history.push('/partesdocorpo');
                      }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
