import { Button } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { Auth } from 'aws-amplify';
import React, { SVGProps, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import menu_icon from '../../assets/images/menu_icon.svg';
import { UIStore } from '../../UIStore';
import { whatsappUrl } from '../../utils/constants';
import { MenuIcon } from '../../utils/SvgIcons';
import './styles.scss';

interface SideMenuProps extends SVGProps<SVGElement> {
  device: string;
  options: Option[];
}

interface Option {
  id?: string;
  title: string;
  class?: string;
  link: string;
  externalLink?: string;
  icon: SVGProps<SVGElement>;
  button?: boolean;
  submenu?: SubMenuOption[];
}

interface SubMenuOption {
  title: string;
  link: string;
}

type ClickMenu = (device: string, title: string) => void;

const SideMenu: React.FC<SideMenuProps> = ({ device, options }) => {
  const nivel = UIStore.useState(s => s.nivelUser);
  const [menu, setMenu] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenGuide = () => {
    handleClose();
    setOpenGuide(true);
  };

  const handleCloseGuide = () => {
    setOpenGuide(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const Logout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
      window.location.href = '/';
    } catch (error) {
      console.log('error signing out: ', error);
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao fazer logout, por favor tente novamente!',
        icon: 'error',
      });
    }
  };

  const clickMenu = (device: string, title: string) => {
    if (title == 'Sair') {
      Logout();
    }
    if (title == 'Tutorial') {
      history.push('/home');
      handleOpen();
    }

    if (title == 'Ajuda') {
      window.open(whatsappUrl, '_blank');
    }
    setMenu(!menu);
    if (isMobile) {
      const container = (document.getElementsByClassName(
        'app-container',
      )[0].id = '');
    }
  };

  const clickToggle = (menu: boolean) => {
    setMenu(menu);
    if (menu == true) {
      document.getElementsByClassName('app-container')[0].id = '';
    } else {
      document.getElementsByClassName('app-container')[0].id = 'compact-menu';
    }
  };

  const SubmenuOption = (
    option: SubMenuOption,
    index: number,
    clickMenu: ClickMenu,
    device: string,
  ) => (
    <ul className="submenu" key={index}>
      <li className="itemSubmenu">
        <NavLink
          activeClassName="active"
          onClick={() => {
            clickMenu(device, option.title);
          }}
          to={option.link}
        >
          <span>{option.title}</span>
        </NavLink>
      </li>
    </ul>
  );

  const Option = (
    option: Option,
    index: number,
    clickMenu: ClickMenu,
    device: string,
  ) => (
    <li
      className={`itemMenu + ${option.class ? option.class : ''}`}
      key={index}
    >
      {option.button === undefined && (
        <NavLink
          id={option.id !== undefined ? option.id : ''}
          activeClassName="active"
          className={option.submenu !== undefined ? 'disable-link' : ''}
          aria-disabled={true}
          onClick={() => {
            if (option.title === 'Sair') {
              Swal.fire({
                icon: 'question',
                title: 'Deseja realmente sair?',
                showCancelButton: true,
              }).then(result => {
                if (result.isConfirmed) {
                  clickMenu(device, option.title);
                } else {
                  history.goBack();
                }
              });
            } else {
              clickMenu(device, option.title);
            }
          }}
          to={option.link}
        >
          {option.icon}
          <span>{option.title}</span>
        </NavLink>
      )}

      {option.button && (
        <Button
          id={option.id !== undefined ? option.id : ''}
          className={option.submenu !== undefined ? 'disable-link' : ''}
          aria-disabled={true}
          onClick={() => {
            clickMenu(device, option.title);
          }}
        >
          {option.icon}
          <img src={menu_icon} alt="" onClick={() => clickToggle(!menu)} />
          <span>{option.title.toLowerCase()}</span>
        </Button>
      )}

      {option.submenu?.map((option, index) =>
        SubmenuOption(option, index, clickMenu, device),
      )}
    </li>
  );

  return (
    <>
      <div className="menu-icon">
        <button onClick={() => clickToggle(!menu)}>
          <MenuIcon />
        </button>
      </div>
      <aside
        className={`new-side-menu${
          nivel === 'paciente' ? ' side-menu-paciente' : ''
        }`}
      >
        <div className="menu-content">
          <ul className="itensMenu">
            {options.map((option, index) =>
              Option(option, index, clickMenu, device),
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideMenu;
