import React, { FormEvent, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import ViewIcon from '@material-ui/icons/Visibility';
import { Button, FormControl, Input, InputLabel } from '@material-ui/core';
import { maskCNPJ } from '../../utils/mask';
import { NavLink } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import Swal from 'sweetalert2';

import api from '../../services/api';
import ModalDescription from '../../components/ModalDescription';
import { GiDoctorFace } from 'react-icons/gi';
import { FiUsers } from "react-icons/fi"

interface Data {
  cnpj: string;
  nomeFantasia: string;
  email: string;
  ativo: boolean;
  opcoes: string;
}

function createData(
  cnpj: string,
  nomeFantasia: string,
  email: string,
  opcoes: string,
  ativo: boolean
): Data {
  return { cnpj, nomeFantasia, email, ativo, opcoes };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'cnpj', numeric: false, disablePadding: true, label: 'CNPJ' },
  { id: 'nomeFantasia', numeric: false, disablePadding: true, label: 'Nome Fantasia' },
  { id: 'email', numeric: false, disablePadding: true, label: 'E-mail' },
  { id: 'ativo', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'opcoes', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitSpacing: {
      marginTop: "0px",
      paddingTop: "0px",
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '10px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridGap: '0px',
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    container: {
      marginBottom: "10px",      
      paddingTop: "10px",
      backgroundColor: "#FFF",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
    filterButton: {
      width: "100%",
      margin: "theme.spacing(1)"
    },
  }),
);

export default function Clinicas() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('cnpj');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [cnpjSearch, setCnpjSearch] = React.useState('');
  const [nomeFantasiaSearch, setNomeFantasiaSearch] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await api.get('/clinica');
      setRows(response.data);
    };

    getData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: 'Você tem certeza que deseja deletar o cadastro!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Deletar!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const deleteConfirmed = async () => {
            const result = await api.delete(`/clinica/${id}`);
            const response = await api.get('/clinica');
            setRows(response.data);
          }

          deleteConfirmed();
        } catch (error) {
          console.log(`erroou: ${error}`);
        }
      }
    });
  };

  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();

    const url = "/clinica?" +
      `${cnpjSearch !== '' ? `cnpj=${cnpjSearch}&` : ``}` +
      `${nomeFantasiaSearch !== '' ? `nomeFantasia=${nomeFantasiaSearch}&` : ``}`;

    const response = await api.get(url);
    setRows(response.data);
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Clínicas
        </Typography>
        <Tooltip title="Gerar relatório de consumo">
          <IconButton onClick={() => handleOpen()} aria-label="Gerar relatório de consumo">
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cadastrar">
          <IconButton aria-label="Cadastrar">
            <NavLink to="clinica"><AddIcon /></NavLink>
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  };

  return (
    <main className={classes.fitSpacing}>
      <div className={classes.root}>
        <div className={classes.container}>
          <form className={classes.filtrosForm} onSubmit={handleSearch}>
            <FormControl>
              <InputLabel htmlFor="nomeFantasiaSearch">Digite o nome da clínica</InputLabel>
              <Input
                name="nomeFantasiaSearch"
                id="nomeFantasiaSearch"
                value={nomeFantasiaSearch}
                onChange={(e) => { setNomeFantasiaSearch(e.target.value) }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="cnpjSearch">Digite o CNPJ</InputLabel>
              <Input
                name="cnpjSearch"
                id="cnpjSearch"
                value={cnpjSearch}
                onChange={(e) => { setCnpjSearch(maskCNPJ(e.target.value)) }}
              />
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                className={classes.filterButton}
                onClick={handleSearch}
              >Pesquisar</Button>
            </FormControl>
          </form>
        </div>
        <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {maskCNPJ(row.cnpj)}
                      </TableCell>
                      <TableCell align="left">
                        {row.nomeFantasia}
                      </TableCell>
                      <TableCell align="left">
                        {row.email}
                      </TableCell>
                      <TableCell align="left">
                        {row.ativo ? "Ativado": "Desativado"}
                      </TableCell>
                      <TableCell align="right">
                      <Tooltip title="VISUALIZAR MÉDICOS">
                          <NavLink to={"medicos-clinica/" + row.id}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="no-text"
                                startIcon={<GiDoctorFace />}
                              ></Button>
                          </NavLink>
                        </Tooltip>
                        <Tooltip title="VISUALIZAR USUÁRIOS">
                          <NavLink to={"usuarios-clinica/" + row.id}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="no-text"
                                startIcon={<FiUsers />}
                              ></Button>
                            </NavLink>
                          </Tooltip>
                          <Tooltip title="EDITAR">
                            <NavLink to={{
                              pathname: "/clinica",
                              state: {
                                id: row.id
                              }
                            }}>
                              <Button
                                variant="contained"
                                color="primary"
                                className="no-text"
                                startIcon={<EditIcon />}
                              ></Button>
                            </NavLink>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows, display: 'none' }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <ModalDescription
        open={open}
        id={""}
        handleClose={handleClose}
        cpf={""}
        type={"report"}
      />
    </main>

  );
}
