import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import infoIcon from '../../assets/images/info_icon.svg';
import api from '../../services/api';
import DataList from '../../components/DataList';
import ModalExam from '../../components/ModalExam';
import StepAddExamModal from '../../components/StepModal';
import { ViewerPDFModal } from '../../components/ViewerPDFModal';
import { WindowLoading } from '../../components/WindowLoading';
import SearchExames from '../../templates/SearchExames';
import { AddIcon, AddDocumentIcon } from '../../utils/SvgIcons';
import {
  getFileURL,
  handleDelete,
  handleDownload,
} from '../../utils/handleExam';
import { maskCPF } from '../../utils/mask';
import { DependenteDTO } from '../../dtos/exameDto';
import './styles.scss';

const EnhancedInfoStatus = (props: { info: string }) => {
  const infoStyle = props.info.toLowerCase();

  return (
    <div className="info-status">
      <p className={`${infoStyle}`}>{props.info}</p>
      {props.info === 'Reprovado' && <img src={infoIcon} alt="Informações" />}
    </div>
  );
};

const Dependentes = () => {
  const [rows, setRows] = useState([]);
  const [id, setId] = useState('');
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [archive, setArchive] = useState('');
  const [fileBody, setFileBody] = useState('');
  const [fileSrc, setFileSrc] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [windowLoading, setWindowLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModalExam = async (id: string, archive?: string) => {
    if (archive) {
      setWindowLoading(true);
      const ext = archive.split('.').pop();

      if (ext === 'pdf') {
        const url = `/dependente/${id}/documento`;

        try {
          const fileUrl = await getFileURL(url, archive);

          if (fileUrl) {
            setFileURL(fileUrl);
            setOpenFileModal(true);
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text:
              'Não foi possível buscar pelo documento. Por favor, tente novamente. Se o erro persistir, entre em contato com o suporte.',
          });
        } finally {
          setWindowLoading(false);
        }
      } else {
        setOpenImgModal(true);
        setId(id);
        setArchive(archive);
      }
    }
  };

  const handleCloseImgModal = () => {
    setOpenImgModal(false);
    setId('');
    setArchive('');
    setFileBody('');
    setFileSrc('');
  };

  const handleDataListRowProps = (row: DependenteDTO) => {
    const { aprovado, id, arquivo, nome, sobrenome, cpf } = row;
    const info =
      aprovado === null ? 'Pendente' : aprovado ? 'Aprovado' : 'Reprovado';

    const handleClick = () => {
      if (aprovado === false) {
        return Swal.fire({
          icon: 'warning',
          text:
            'O documento anexado para cadastrar este dependente não seguiu os critérios necessários. Você pode editar e enviar outro documento para avaliação que demonstre a filiação do dependente (como certidão de nascimento e RG). Ou se desejar, poderá excluir este dependente e fazer o cadastro novamente.',
        });
      }

      return handleOpenModalExam(id, arquivo);
    };

    return {
      title: `${nome} ${sobrenome}`,
      subTitle: maskCPF(cpf),
      description: <EnhancedInfoStatus info={info} />,
      onClick: handleClick,
      param: arquivo,
      actions: [
        {
          description: 'Baixar documento',
          tooltip: 'DOWNLOAD',
          icon: <DownloadIcon />,
          param: arquivo,
          url: `/dependente/${id}/documento`,
          handle: handleDownload,
        },
        {
          description: 'Visualizar documento',
          tooltip: 'VER',
          icon: <VisibilityIcon />,
          param: arquivo,
          handle: handleOpenModalExam,
        },
        {
          description: 'Editar perfil',
          tooltip: 'EDITAR',
          link: 'dependente/' + id,
          icon: <CreateIcon />,
        },
        {
          description: 'Excluir',
          tooltip: 'EXCLUIR',
          icon: <DeleteIcon />,
          param: 'dependente',
          url: `/dependente/${id}`,
          handle: handleDelete,
        },
      ],
    };
  };

  useEffect(() => {
    (async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get('/dependente', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });

      if (response.data.length > 0) {
        setRows(response.data);
      } else {
        setRows([]);
      }
    })();
  }, []);

  return (
    <main className="dependentes main-paciente">
      <SearchExames searchField={false} />
      <DataList
        handleRowProps={handleDataListRowProps}
        orderBy="nome"
        orderByTitle="Nome"
        rows={rows}
        pageName="Dependentes"
        checkbox={false}
        headActions={[
          {
            icon: <AddIcon />,
            text: 'Doc',
            tooltip: 'ADICIONAR DOCUMENTO',
            handle: () => setOpenModal(!openModal),
          },
          {
            icon: <AddIcon />,
            text: 'Novo',
            tooltip: 'CADASTRAR DEPENDENTE',
            link: 'dependente',
          },
        ]}
      />
      <ViewerPDFModal
        open={openFileModal}
        onClose={() => setOpenFileModal(false)}
        url={fileURL}
      />
      <ModalExam
        src={fileSrc}
        user={true}
        open={openImgModal}
        id={id}
        url="/dependente/:id/documento"
        arquivo={archive}
        handleClose={handleCloseImgModal}
        body={fileBody}
      />
      <WindowLoading loading={windowLoading} />
      <StepAddExamModal isOpen={openModal} openModal={setOpenModal} />
    </main>
  );
};

export default Dependentes;
