import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import HomeAdmin from '../pages/HomeAdmin';
import Categorias from '../pages/Categorias';
import Categoria from '../pages/Categoria';
import Clinicas from '../pages/Clinicas';
import Clinica from '../pages/Clinica';
import UsuarioClinica from '../pages/UsuarioClinica';
import UsuariosClinica from '../pages/UsuariosClinica';
import Especialidades from '../pages/Especialidades';
import Especialidade from '../pages/Especialidade';
import Pacientes from '../pages/Pacientes';
import Paciente from '../pages/Paciente';
import Planos from '../pages/Planos';
import Plano from '../pages/Plano';
import Familias from '../pages/Familias';
import Familia from '../pages/Familia';
import Parentescos from '../pages/Parentescos';
import Parentesco from '../pages/Parentesco';
import DependentesAprovacao from '../pages/DependentesAprovacao';
import DependenteVisualizacao from '../pages/DependenteVisualizacao';
import TopMenu from '../components/TopMenu';
import SideMenu from '../components/SideMenu';
import LoginAdmin from '../pages/LoginAdmin';
import NovaSenhaAdmin from '../pages/NovaSenhaAdmin';
import SetupMFAAdmin from '../pages/SetupMFAAdmin';
import MFAAdmin from '../pages/MFAAdmin';
import { UIStore } from '../UIStore';
import Protocolos from '../pages/Protocolos';
import Termo from '../pages/TermoDeUso';
import Termos from '../pages/TermosDeUso';
import Politica from '../pages/PoliticaDePrivacidade';
import Politicas from '../pages/PoliticasDePrivacidade';
import PrivateRoute from '../components/PrivateRoute';
import { Helmet } from 'react-helmet';
import MedicosClinica from '../pages/MedicosClinica';
import MedicoClinica from '../pages/MedicoClinica';
import PartesCorpo from '../pages/PartesDoCorpo';
import ParteCorpo from '../pages/ParteDoCorpo';
import TiposDeDocumento from '../pages/TiposDeDocumento';
import TipoDeDocumento from '../pages/TipoDeDocumento';
// Icons
import homeLegado from '../assets/images/home_legado.svg';
import clientLegado from '../assets/images/client_legado.svg';
import clinicasLegado from '../assets/images/clinicas_legado.svg';
import categoriasLegado from '../assets/images/categorias_legado.svg';
import tipoDocumentoLegado from '../assets/images/tipoDocumentos_legado.svg';
import parteCorpoLegado from '../assets/images/parteCorpo_legado.svg';
import especialidadesLegado from '../assets/images/especialidades_legado.svg';
import privacidadeLegado from '../assets/images/privacidade_legado.svg';
import protocolosLegado from '../assets/images/protocolos_legado.svg';
import logoutLegado from '../assets/images/logout_legado.svg';
function AdminRoutes({ useBasename }: { useBasename: boolean }) {
  const signed = UIStore.useState(s => s.signed);
  const nivelUser = UIStore.useState(s => s.nivelUser);
  const username = UIStore.useState(s => s.username);
  const name = UIStore.useState(s => s.name);
  const [device, setDevice] = useState('desktop');
  useEffect(() => {
    if (isMobile) {
      setDevice('mobile');
    }
  });
  return (
    <BrowserRouter basename={useBasename ? '/admin' : undefined}>
      <>
        <div className="app-container app-admin">
          {signed && nivelUser == 'admin' ? (
            <>
              <TopMenu name={name ? name : username} />
              <SideMenu
                device={device}
                options={[
                  {
                    title: 'Home',
                    link: '/home',
                    icon: <img src={homeLegado} />,
                  },
                  {
                    title: 'Clientes',
                    link: '/usuario',
                    icon: <img src={clientLegado} />,
                    submenu: [
                      {
                        title: 'Pacientes',
                        link: '/usuario/pacientes',
                      },
                      {
                        title: 'Dependentes',
                        link: '/usuario/dependentes',
                      },
                      {
                        title: 'Planos',
                        link: '/usuario/planos',
                      },
                    ],
                  },
                  {
                    title: 'Clínicas',
                    link: '/clinicas',
                    icon: <img src={clinicasLegado} />,
                  },
                  {
                    title: 'Cadastros',
                    link: '/usuario',
                    icon: <img src={categoriasLegado} />,
                    submenu: [
                      {
                        title: 'Categorias',
                        link: '/categorias',
                      },
                      {
                        title: 'Tipos de documentos',
                        link: '/tiposdedocumentos',
                      },
                      {
                        title: 'Partes do corpo',
                        link: '/partesdocorpo',
                      },
                      {
                        title: 'Especialidades',
                        link: '/especialidades',
                      },
                    ],
                  },
                  {
                    title: 'Privacidade',
                    link: '/privacidade',
                    icon: <img src={privacidadeLegado} />,
                    submenu: [
                      {
                        title: 'Políticas',
                        link: '/privacidade/politicas',
                      },
                      {
                        title: 'Termos',
                        link: '/privacidade/termos',
                      },
                    ],
                  },
                  {
                    title: 'Protocolos',
                    link: '/protocolos',
                    icon: <img src={protocolosLegado} />,
                  },
                  {
                    title: 'Sair',
                    class: 'logout',
                    link: 'logout',
                    icon: <img src={logoutLegado} />,
                  },
                ]}
              />
              <PrivateRoute timeToExtend={12} exact={true} path="/home">
                <HomeAdmin />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario/planos"
              >
                <Planos />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario/plano/:id?"
              >
                <Plano />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/categorias">
                <Categorias />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/partesdocorpo"
              >
                <PartesCorpo />
              </PrivateRoute>
              ​
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/partedocorpo/:id?"
              >
                <ParteCorpo />
              </PrivateRoute>
              ​
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/tiposdedocumentos"
              >
                <TiposDeDocumento />
              </PrivateRoute>
              ​
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/tipodedocumento/:id?"
              >
                <TipoDeDocumento />
              </PrivateRoute>
              ​
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/categoria/:id?"
              >
                <Categoria />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/clinicas">
                <Clinicas />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/clinica/:id?">
                <Clinica />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/medicos-clinica/:id?"
              >
                <MedicosClinica />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/medico-clinica/:id?"
              >
                <MedicoClinica />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuarios-clinica/:id"
              >
                <UsuariosClinica />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario-clinica/:id?"
              >
                <UsuarioClinica />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/especialidades"
              >
                <Especialidades />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/especialidade/:id?"
              >
                <Especialidade />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario/pacientes"
              >
                <Pacientes />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario/paciente/:id?"
              >
                <Paciente />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/familias">
                <Familias />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/familia/:id?">
                <Familia />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/parentescos">
                <Parentescos />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/parentesco/:id?"
              >
                <Parentesco />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario/dependentes"
              >
                <DependentesAprovacao />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/usuario/dependente/:id"
              >
                <DependenteVisualizacao />
              </PrivateRoute>
              <PrivateRoute timeToExtend={12} exact={true} path="/protocolos">
                <Protocolos />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/privacidade/termos"
              >
                <Termos />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/privacidade/termo"
              >
                <Termo />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/privacidade/politicas"
              >
                <Politicas />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={12}
                exact={true}
                path="/privacidade/politica"
              >
                <Politica />
              </PrivateRoute>
            </>
          ) : (
            <Switch>
              <Route path="/" exact component={LoginAdmin} />
              <Route path="/login" component={LoginAdmin} />
              <Route path="/nova-senha" component={NovaSenhaAdmin} />
              <Route path="/mfa" component={MFAAdmin} />
              <Route path="/setup-mfa" component={SetupMFAAdmin} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          )}
        </div>
      </>
    </BrowserRouter>
  );
}
export default AdminRoutes;
