import React, { useState, useEffect, FormEvent, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import validator from 'cpf-cnpj-validator';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import DateFnsUtils from '@date-io/date-fns';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  TextField,
  Input,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { maskCPF, maskName } from '../../utils/mask';
import api from '../../services/api';
import SearchExames from '../../templates/SearchExames';
import SubmitButton from '../../components/Button';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
  }),
);

interface DataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function DataMask(props: DataMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface Dependente {
  id: string;
  nome: string;
  sobrenome: string;
  responsavel: string;
  corPredominante: string;
  tipoSanguineo: string;
  peso: number;
  familia: object;
}

interface ParamTypes {
  id: string;
}

function Dependente() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idDependente, setIdDependente] = useState({} as Dependente);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [cpf, setCpf] = useState('');
  const [tipoSanguineo, setTipoSanguineo] = useState('');
  const [fatorRh, setFatorRh] = useState('');
  const [sexo, setSexo] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [aprovado, setAprovado] = useState<boolean | null>(null);
  const [parentescoId, setParentescoId] = useState('');
  const [parentescos, setParentescos] = React.useState([]);
  const [dataNascimento, setDataNascimento] = useState<Date | null>(null);
  const [tipoForm, setTipoForm] = useState('cadastrar');
  const [messageSuccess, setMessageSuccess] = useState(
    'Cadastro realizado com sucesso, agora é só aguardar a revisão da nossa equipe e assim que o cadastro for aprovado, você receberá uma notificação.',
  );
  const [ageError, setAgeError] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [validFiles, setValidFiles] = useState<any[]>([]);
  const [hover, setHover] = useState('');
  const [peso, setPeso] = useState("0");
  const [termo, setTermo] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const Joi = require('@hapi/joi').extend(validator);
  const cpfSchema = Joi.document().cpf().required();

  const handleChangeTipoSanguineo = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setTipoSanguineo(event.target.value as string);
  };

  const handleChangePeso = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeso(event.target.value);
  };

  const handleChangeFatorRh = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setFatorRh(event.target.value as string);
  };

  const handleChangeSelectParentesco = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setParentescoId(event.target.value as string);
  };

  const handleChangeSelectSexo = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setSexo(event.target.value as string);
  };

  const preventDefault = (e: FormEvent) => {
    e.preventDefault();
  };

  const dragOver = (e: any) => {
    preventDefault(e);
    setHover('hover');
  };

  const dragEnter = (e: any) => {
    preventDefault(e);
  };

  const dragLeave = (e: any) => {
    preventDefault(e);
    setHover('');
  };

  const validateFile = (file: any) => {
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];

    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const handleFiles = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setValidFiles([...validFiles, files[i]]);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Atenção!',
          text:
            'Por favor, selecione um arquivo com extensão válida (png, pdf ou jpg)!',
        });
      }
    }
  };

  const fileDrop = (e: any) => {
    setHover('');
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      if (fileInputRef.current.files.length) {
        handleFiles(fileInputRef.current.files);
      }
    }
  };

  const fileInputClicked = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const openImageModal = (file: File) => {
    const reader = new FileReader();
    if (modalRef.current) modalRef.current.style.display = 'none';
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      if (modalImageRef.current)
        modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  };

  const removeFile = (name: any) => {
    const index = validFiles.findIndex(e => e.name === name);
    validFiles.splice(index, 1);
    setValidFiles([...validFiles]);
  };

  const fileType = (fileName: any) => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const fileSize = (size: any) => {
    if (size === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const getParentescos = async () => {
      const parentescos = await api.get('/parentesco');
      setParentescos(parentescos.data);
    };

    getParentescos();

    if (params.id) {
      setTipoForm('editar');

      const getData = async () => {
        const userTokens = await Auth.currentSession();

        const { data } = await api.get(`/dependente/${params.id}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
          },
        });

        const message = data.aprovado
          ? 'Cadastro alterado com sucesso.'
          : 'Cadastro alterado com sucesso, agora é só aguardar a revisão da nossa equipe e assim que o cadastro for aprovado, você receberá uma notificação.';
        const fullName = `${data.nome} ${data.sobrenome}`;

        setMessageSuccess(message);
        setNomeCompleto(fullName);
        setId(data.id);
        setCpf(data.cpf);
        setPeso(data.peso);
        setTipoSanguineo(data.tipoSanguineo);
        setFatorRh(data.fatorRh);
        setDataNascimento(data.dataNascimento);
        setParentescoId(data.parentesco.id);
        setSexo(data.sexo);
        setAtivo(data.ativo);
        setAprovado(data.aprovado);
      };

      getData();
    }
  }, []);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader && reader.result)
          resolve(reader.result.toString().replace(/^data:.+;base64,/, ''));
      };
      reader.onerror = error => reject(error);
    });

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await cpfSchema.validateAsync(cpf);
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'CPF inválido!',
        icon: 'error',
      });
      
      setIsSubmitting(false);
      return;
    }

    if (
      (tipoForm == 'cadastrar' && !termo) ||
      (tipoForm == 'editar' && !aprovado && !termo)
    ) {
      Swal.fire({
        title: 'Erro!',
        text: 'É necessário ser responsável pelo dependente.',
        icon: 'error',
      });
      setIsSubmitting(false);
      return;
    }

    if (dataNascimento) {
      const currentDate = new Date();
      const birthdate = new Date(dataNascimento);
      const daysToBirthday = currentDate.getDate() < birthdate.getDate();
      const month = currentDate.getMonth() - birthdate.getMonth();
      let age = currentDate.getFullYear() - birthdate.getFullYear();
      if (month < 0 || (month === 0 && daysToBirthday)) {
        age--;
      }

      if (age >= 18) {
        setAgeError(true);
        Swal.fire({
          title: 'Erro!',
          text: 'É necessário que o dependente seja menor que 18 anos.',
          icon: 'error',
        });
        setIsSubmitting(false);
        return;
      }
    }

    if (validFiles.length === 0 && !aprovado) {
      Swal.fire({
        title: 'Erro!',
        text:
          'Para validação do cadastro é necessário o envio de um documento que comprove o parentesco do dependente a ser incluído como RG ou Certidão de Nascimento!',
        icon: 'error',
      });

      setIsSubmitting(false);
      return;
    }

    const userTokens = await Auth.currentSession();
    const responsavel = userTokens.getAccessToken().payload.sub;
    const ativo = true;

    let arquivoBinario = {};

    for (let i = 0; i < validFiles.length; i++) {
      arquivoBinario = {
        fileName: validFiles[i].name,
        content: await toBase64(validFiles[i]),
      };
    }

    const nameArray = nomeCompleto.split(' ');
    const name = nameArray.shift();
    const family_name = nameArray.join(' ');

    const result = () => {
      const requestData = {
        nome: name,
        sobrenome: family_name,
        cpf: cpf.replace(/\D/g, ''),
        responsavel,
        tipoSanguineo,
        fatorRh,
        dataNascimento,
        peso: Math.round(Number(peso)),
        parentesco: {
          id: parentescoId,
        },
        sexo,
        aprovado: aprovado,
        arquivoBinario,
        ativo,
      };

      const requestHeaders = {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      };

      if (id) {
        return api.put(`/dependente/${id}`, requestData, requestHeaders);
      }

      return api.post('/dependente', requestData, requestHeaders);
    };

    Promise.all([result()])
      .then(result => {
        Swal.fire({
          title: messageSuccess,
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/dependentes');
          }
        });
      })
      .catch(error => {
        const { data: message } = error.response;
        Swal.fire({
          title: 'Erro!',
          text: `${message}`,
          icon: 'error',
        });
      }).finally(() => setIsSubmitting(false));
  }

  return (
    <>
      <main className="dependente main-paciente">
        <SearchExames searchField={false} />
        <div className="dependentes-form">
          <h1>Dependente</h1>
          <form onSubmit={handleSubmit}>
            <div className={classes.grid3}>
              <Input
                type="hidden"
                style={{ display: 'none' }}
                name="id"
                value={idDependente}
              />
              <TextField
                id="nome"
                label="Nome completo (sem abreviações)"
                variant="standard"
                disabled={aprovado ? true : false}
                required
                name="nome"
                value={nomeCompleto}
                onChange={e => {
                  setNomeCompleto(maskName(e.target.value));
                }}
              />
              <TextField
                id="CPF"
                label="CPF"
                variant="standard"
                required
                disabled={aprovado ? true : false}
                name="cpf"
                value={maskCPF(cpf)}
                onChange={e => {
                  setCpf(maskCPF(e.target.value));
                }}
              />
            </div>
            <div className={classes.grid3}>
              <TextField
                type="number"
                id="peso"
                label="Peso"
                variant="standard"
                name="peso"
                value={peso}
                onChange={handleChangePeso}
              />
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo Sanguineo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="tipoSanguineo"
                  value={tipoSanguineo}
                  disabled={aprovado ? true : false}
                  onChange={handleChangeTipoSanguineo}
                  label="Tipo Sanguineo"
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="AB">AB</MenuItem>
                  <MenuItem value="O">O</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Fator RH
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="fatorRh"
                  value={fatorRh}
                  disabled={aprovado ? true : false}
                  onChange={handleChangeFatorRh}
                  label="Fato RH"
                >
                  <MenuItem value="+">+</MenuItem>
                  <MenuItem value="-">-</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.grid}>
              <FormControl fullWidth required>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    disabled={aprovado ? true : false}
                    inputVariant="standard"
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="Data de nascimento"
                    views={['year', 'month', 'date']}
                    value={dataNascimento}
                    error={ageError}
                    onChange={value => {
                      setDataNascimento(value);
                    }}
                    invalidDateMessage={
                      ageError
                        ? 'A idade do seu dependente não pode ser maior que 18 anos'
                        : 'Inserir uma data válida'
                    }
                    required
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Parentesco *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="parentesco"
                  variant="standard"
                  disabled={aprovado ? true : false}
                  value={parentescoId}
                  onChange={handleChangeSelectParentesco}
                  label="Parentesco"
                  required
                >
                  {parentescos.map((row: any, index) => {
                    return (
                      <MenuItem key={row.id} value={row.id}>
                        {row.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl required variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Sexo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="sexo"
                  value={sexo}
                  onChange={handleChangeSelectSexo}
                  label="Gênero"
                >
                  <MenuItem value="Masculino">Masculino</MenuItem>
                  <MenuItem value="Feminino">Feminino</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="status">
              <FormControlLabel
                control={
                  <Switch
                    checked={ativo}
                    onChange={e => {
                      setAtivo(!ativo);
                    }}
                    color="primary"
                    name="ativo"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Status"
              />
            </div>
            {tipoForm == 'cadastrar' || !aprovado ? (
              <div className="termos">
                <Checkbox
                  checked={termo}
                  onChange={e => {
                    setTermo(!termo);
                  }}
                  name="termo"
                  color="primary"
                />
                <span>
                  <a
                    onClick={() => {
                      setTermo(!termo);
                    }}
                  >
                    Declaro que sou responsável pelo dependente.
                  </a>
                </span>
              </div>
            ) : (
              ''
            )}
            <div
              className="container"
              style={{ display: aprovado ? 'none' : 'block' }}
            >
              <div
                className="drop-container"
                id={hover}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
              >
                <div className="drop-message">
                  <div className="upload-icon">
                    <PublishIcon />
                  </div>
                  <span style={{ display: 'block', lineHeight: '2rem' }}>
                    Arraste ou clique aqui para anexar um documento que comprove
                    o grau de parentesco como RG ou Certidão de Nascimento (PDF,
                    PNG ou JPG)
                  </span>
                </div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  onChange={filesSelected}
                  disabled={validFiles.length === 1 ? true : false}
                />
              </div>
              <div className="file-display-container">
                {validFiles.map((data, i) => (
                  <div className="file-status-bar" key={i}>
                    <div
                      onClick={
                        !data.invalid
                          ? () => openImageModal(data)
                          : () => removeFile(data.name)
                      }
                    >
                      <div className="file-type-logo"></div>
                      <div className="file-type">{fileType(data.name)}</div>
                      <span
                        className={`file-name ${
                          data.invalid ? 'file-error' : ''
                        }`}
                      >
                        {data.name}
                      </span>
                      <span className="file-size">({fileSize(data.size)})</span>
                    </div>
                    <div className="file-remove">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => removeFile(data.name)}
                      >
                        X
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-buttons">
              <SubmitButton
                isLoading={isSubmitting}
                disableElevation
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Salvar
              </SubmitButton>
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => {
                  history.push('/dependentes');
                }}
              >
                Voltar
              </Button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

export default Dependente;
