import React, { useEffect, useState } from 'react';
import Tour from 'reactour';
import { Route, useHistory, useLocation, withRouter } from 'react-router-dom';
import "./styles.scss";
import PerfilPaciente from '../../pages/PerfilPaciente';

interface UserGuideDTO {
  open: boolean;
  step?: number;
  handleClose: () => void;
  handleCloseTopMenu?: () => void;
}

const UserGuide = (props: UserGuideDTO) => {
  const history = useHistory();

  const handleParam = () => {

  }

  const tourConfig = [
    {
      selector: "#home",
      content: `Aqui você acessa a página principal, onde é possível visualizar
      alguns cards mostrando os seus dependentes aprovados e pets cadastrados`
    },
    {
      action: () => {
        if (props.handleCloseTopMenu !== undefined) {
          if (history.location.pathname !== '/exames') {

          }
          history.push({
            pathname: "/exames",
            state: {
              open: true,
              step: 1
            }
          });

          props.handleCloseTopMenu();
        } else {
          if (history.location.pathname !== '/exames') {
            history.push({
              pathname: "/exames",
              state: {
                open: true,
                step: 1
              }
            });
          }
        }
      },
      selector: "#exames",
      content: `Aqui você acessa o módulo de exames, onde é possível visualizar todos
      os exames cadastrados e cadastrar novos exames.`,
    },
    {
      action: () => {
        if (props.handleCloseTopMenu !== undefined) {
          history.push({
            pathname: "/exames",
            state: {
              open: true,
              step: 2
            }
          });

          props.handleCloseTopMenu();
        } else {
          if (history.location.pathname !== '/exames') {
            console.log("aqui");
            history.push({
              pathname: "/exames",
              state: {
                open: true,
                step: 2
              }
            });
          }
        }
      },
      selector: "#cadastrar_exame",
      content: "Aqui você acessa o formulário para cadastrar um novo exame."
    },
    {
      action: () => {
        if (history.location.pathname !== '/dependentes') {
          history.push({
            pathname: "/dependentes",
            state: {
              open: true,
              step: 3
            }
          })
        }
      },
      selector: "#dependentes",
      content: `Aqui você acessa o módulo de dependentes, onde é possível visualizar todos
      os seus dependentes cadastrados e cadastrar novos dependentes.`
    },
    {
      action: () => {
        if (history.location.pathname !== '/dependentes') {
          history.push({
            pathname: "/dependentes",
            state: {
              open: true,
              step: 4
            }
          });
        }
      },
      selector: "#cadastrar_dependentes",
      content: `Aqui você acessa o formulário para cadastrar um novo dependente.`
    },
    {
      action: () => {
        if (history.location.pathname !== '/pets') {
          history.push({
            pathname: "/pets",
            state: {
              open: true,
              step: 5
            }
          });
        }
      },
      selector: "#pets",
      content: `Aqui você acessa o módulo de pets, onde é possível visualizar todos
      os pets e exames de pets cadastrados e cadastrar novos pets e exames.`
    },
    {
      action: () => {
        if (history.location.pathname !== '/pets') {
          history.push({
            pathname: "/pets",
            state: {
              open: true,
              step: 6
            }
          });
        }
      },
      selector: "#exame_pet",
      content: `Aqui você acessa o formulário para cadastrar um novo exame para seu pet.`
    }, {
      action: () => {
        if (history.location.pathname !== '/pets') {
          history.push({
            pathname: "/pets",
            state: {
              open: true,
              step: 7
            }
          });
        }

      },
      selector: "#pet",
      content: `Aqui você acessa o formulário para cadastrar um novo pet.`
    },
    {
      action: () => {
        if (history.location.pathname !== '/perfil') {
          history.push({
            pathname: "/perfil",
            state: {
              open: true,
              step: 8,
              selectedTab: "perfil"
            }
          });
        } else {
          history.replace({
            state: {
              open: true,
              step: 8,
              selectedTab: "perfil"
            }
          });
        }
      },
      selector: "#tabPerfil",
      content: `Essa é a sua página de perfil, aqui você pode alterar suas informações pessoais como nome,
      e-mail, data de nascimento, telefone e contato preferencial.`
    },
    {
      action: () => {
        if (history.location.pathname !== '/perfil') {
          history.push({
            pathname: "/perfil",
            state: {
              open: true,
              step: 9,
              selectedTab: "plano"
            }
          });
        } else {
          history.replace({
            pathname: "/perfil",
            state: {
              open: true,
              step: 9,
              selectedTab: "plano"
            }
          });
        }
      },
      selector: ".emailSaude",
      content: `Esse é o seu email da Clipo Saúde, você pode solicitar para as clínicas enviarem
      os seus exames ou de seus dependentes para esse email que eles estarão disponíveis na listagem de exames.`
    },
  ];

  return (
    <Tour
      steps={tourConfig}
      isOpen={props.open}
      onRequestClose={props.handleClose}
      goToStep={props.step}
      className="helper"
      accentColor="#21ACFC"
      rounded={5}
      startAt={props.handleCloseTopMenu !== undefined ? 0 : props.step}
    />
  )
}

export default UserGuide;
