import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { ImSearch } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import ShareIcon from '@material-ui/icons/Share';
import StarIcon from '@material-ui/icons/Star';
import { Button } from '@material-ui/core';

import api from '../../services/api';
import filePngIcon from '../../assets/images/png_file_symbol.png';
import filePdfIcon from '../../assets/images/pdf_file_symbol.png';
import fileJpgIcon from '../../assets/images/jpg_file_symbol.png';
import DataList from '../../components/DataList';
import Filter from '../../components/Filter';
import ModalExam from '../../components/ModalExam';
import ModalDescription from '../../components/ModalDescription';
import {
  handleDelete,
  handleDownload,
  getFileURL,
  handleShare,
  handleExams,
} from '../../utils/handleExam';
import { WindowLoading } from '../../components/WindowLoading';
import SearchExames from '../../templates/SearchExames';
import { ViewerPDFModal } from '../../components/ViewerPDFModal';
import StepAddExamModal from '../../components/StepModal';
import { getExams } from '../../services/api/user';
import { CategoriasDTO, ExameDTO } from '../../dtos/exameDto';
import './styles.scss';

interface IExams {
  data: [];
}

export const ExamesPaciente = () => {
  const [dataUrl, setDataUrl] = useState('/exame');
  const [rows, setRows] = useState<any>([]);
  const [categories, setCategories] = useState<any>('');
  const [clinicas, setClinicas] = useState<Array<string>>(['']);
  const [ownersExam, setOwnersExam] = useState<any>([]);
  const [unchangedRows, setUnchangedRows] = useState<any>([]);
  const [id, setId] = useState('');
  const [cpf, setCpf] = useState('');
  const [type, setType] = useState<'rate' | 'categories' | 'report'>('rate');
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileURL, setFileURL] = useState('');
  const [openModalDesc, setOpenModalDesc] = useState(false);
  const [archive, setArchive] = useState('');
  const [fileBody, setFileBody] = useState('');
  const [fileSrc, setFileSrc] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [windowLoading, setWindowLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { state } = useLocation<IExams>();

  const handleOpenExam = async (id: string, archive?: string) => {
    if (archive) {
      const ext = archive.split('.').pop();

      if (ext === 'pdf') {
        setWindowLoading(true);
        const url = `/exame/${id}/arquivo`;

        const fileUrl = await getFileURL(url, archive);
        setWindowLoading(false);
        if (fileUrl) setFileURL(fileUrl);
        setOpenFileModal(true);
      } else {
        setOpenImgModal(true);
        setId(id);
        setArchive(archive);
      }
    }
  };

  const handleOpenModalRate = (id: string, cpf: string): void => {
    setOpenModalDesc(true);
    setId(id);
    setCpf(cpf);
    setType('rate');
  };

  const handleOpenModalCateg = (id: string, cpf: string): void => {
    setOpenModalDesc(true);
    setId(id);
    setCpf(cpf);
    setType('categories');
  };

  const handleCloseModalExam = () => {
    setOpenImgModal(false);
    setId('');
    setArchive('');
    setFileBody('');
    setFileSrc('');
  };

  const handleCloseModalDesc = () => {
    setOpenModalDesc(false);
    setId('');
    setCpf('');
  };

  const handleData = (examsRes: ExameDTO[], categoriesRes: CategoriasDTO) => {
    const { reduceOwners, reduceClinics } = handleExams(examsRes);

    setCategories(categoriesRes);
    setRows(examsRes);
    setOwnersExam(reduceOwners);
    setClinicas(reduceClinics);

    if (examsRes.length > 0) {
      !unchangedRows.length && setUnchangedRows(examsRes);
    } else {
      if (rows.length) {
        Swal.fire({
          title: 'Oops!',
          text: 'Nenhum dado foi encontrado!',
          icon: 'warning',
        });
      }

      setRows([]);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: categoriesRes } = await api.get('categoria');

        if (state) {
          if (state?.data.length) {
            handleData(state.data, categoriesRes);
            return;
          } else {
            await Swal.fire({
              title: 'Oops!',
              text: 'Nenhum dado foi encontrado!',
              icon: 'warning',
            });
          }
        }

        const examsRes = await getExams(dataUrl);
        handleData(examsRes, categoriesRes);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: error.message,
        });
      }
    })();
  }, [dataUrl]);

  const handleDataListRowProps = (row: any) => {
    const ext = row.arquivo.split('.').pop();
    const avatar =
      ext === 'pdf' ? filePdfIcon : ext === 'png' ? filePngIcon : fileJpgIcon;
    const visible = row.clinica ? true : false;

    return {
      avatar: <img style={{ height: '50px' }} src={avatar} alt="avatar" />,
      title: row.dadosPessoaExame.nome,
      subTitle: format(new Date(row.dataCadastro), 'dd/MM/yyyy HH:mm:ss', {
        locale: dateFnsPtBR,
      }),
      description: row.clinica && `${row.clinica.nomeFantasia}`,
      onClick: handleOpenExam,
      param: row.arquivo,
      actions: [
        {
          description: 'Avaliar clínica',
          tooltip: 'AVALIAR CLÍNICA',
          icon: <StarIcon />,
          visible,
          param: row.cpf,
          handle: handleOpenModalRate,
        },
        {
          description: 'Baixar documento',
          tooltip: 'DOWNLOAD',
          icon: <DownloadIcon />,
          param: row.arquivo,
          url: `/exame/${row.id}/arquivo`,
          handle: handleDownload,
        },
        {
          description: 'Visualizar documento',
          tooltip: 'VER',
          icon: <VisibilityIcon />,
          param: row.arquivo,
          handle: handleOpenExam,
        },
        {
          description: 'Editar perfil',
          tooltip: 'ATRIBUIR DESCRIÇÃO AO EXAME',
          icon: <CreateIcon />,
          param: row.cpf,
          handle: handleOpenModalCateg,
        },
        {
          description: 'Excluir',
          tooltip: 'EXCLUIR',
          icon: <DeleteIcon />,
          url: `/exame/${row.id}`,
          param: 'exame',
          handle: handleDelete,
          visible: !row.clinica
        },
      ],
    };
  };

  const handleFilterByOwner = (value: string) => {
    if (value === 'all') {
      setDataUrl('/exame');
      setRows(unchangedRows);
    } else {
      const newRows = unchangedRows.filter((data: any) => {
        return data.dadosPessoaExame.nome === value;
      });

      setRows(newRows);
    }
  };

  const handleFilter = (url: string) => {
    setDataUrl(url);
  };

  return (
    <main className="exames-paciente main-paciente">
      <SearchExames searchField={false} />
      <div className="filter-exam-by-owner">
        <Button
          href={`#Todos`}
          id={`Todos`}
          variant="contained"
          disableElevation
          onClick={() => handleFilterByOwner('all')}
        >
          Todos
        </Button>
        {ownersExam.map((value: any) => {
          return (
            !value.isDependente && (
              <Button
                href={`#${value.fullName}`}
                id={`${value.fullName}`}
                variant="contained"
                disableElevation
                onClick={() => handleFilterByOwner(value.fullName)}
              >
                Meus
              </Button>
            )
          );
        })}
        {ownersExam.map((value: any) => {
          return (
            value.isDependente && (
              <Button
                href={`#${value.fullName}`}
                id={`${value.fullName}`}
                variant="contained"
                disableElevation
                onClick={() => handleFilterByOwner(value.fullName)}
              >
                {value.firstName}
              </Button>
            )
          );
        })}
      </div>
      <Filter
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        options={[
          {
            value: 'categorias',
            inputDescription: 'Selecione as categorias',
            placeHolder: 'Categorias',
            autoComplete: categories,
          },
          {
            value: 'descricao',
            inputDescription: 'Digite a descrição',
            placeHolder: 'Identificação',
          },
          {
            value: 'clinica',
            inputDescription: 'Escolha a clínica',
            placeHolder: 'Clínica',
            selects: clinicas,
          },
          {
            value: 'protocolo',
            inputDescription: 'Digite o protocolo',
            placeHolder: 'Protocolo',
          },
        ]}
        handle={handleFilter}
      />
      <DataList
        handleRowProps={handleDataListRowProps}
        avatarBackground={false}
        orderBy="dataCadastro"
        orderByTitle="Datas"
        pagination={true}
        rows={rows}
        headActions={[
          {
            icon: (
              <a href={'#'}>
                <ImSearch />
              </a>
            ),
            tooltip: 'PESQUISAR',
            handle: () => setOpenFilter(true),
          },
          {
            icon: <ShareIcon />,
            tooltip: 'COMPARTILHAR',
            visibleInCheckboxAction: true,
            handle: handleShare,
          },
          {
            icon: <AddCircle />,
            tooltip: 'CADASTRAR',
            handle: () => setOpenModal(!openModal),
          },
        ]}
      />
      <ViewerPDFModal
        open={openFileModal}
        onClose={() => setOpenFileModal(false)}
        url={fileURL}
      />
      <ModalExam
        src={fileSrc}
        user={true}
        url="exame/:id/arquivo"
        open={openImgModal}
        id={id}
        arquivo={archive}
        handleClose={handleCloseModalExam}
        body={fileBody}
      />
      <ModalDescription
        open={openModalDesc}
        id={id}
        handleClose={handleCloseModalDesc}
        cpf={cpf}
        type={type}
      />
      <WindowLoading loading={windowLoading} />
      <StepAddExamModal isOpen={openModal} openModal={setOpenModal} />
    </main>
  );
};

export default ExamesPaciente;
