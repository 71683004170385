import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

import api from '../../services/api';

import './styles.scss';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { couldStartTrivia } from 'typescript';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface State {
  id: string;
  view: boolean;
}

function Termo() {
  const [id, setId] = useState('');
  const [conteudo, setConteudo] = useState('');
  const [descricao, setDescricao] = useState("");
  const location = useLocation<State>();
  const [view, setView] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (location.state === undefined || location.state === null) {
      const response = async () => {
        const response = await api.get("/termo/atual");

        setId("");
        setDescricao("");
        setConteudo(response.data.conteudo);
      }

      response();
    } else {
      setView(location.state.view);
      const getData = async () => {
        const userTokens = await Auth.currentSession();

        const response = await api.get(`/termo/${location.state.id}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });

        setId(response.data.id);
        setDescricao(response.data.descricao);
        setConteudo(response.data.conteudo);
      };

      getData();
    }
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {

      const userTokens = await Auth.currentSession();
      const responsavel = userTokens.getAccessToken().payload.sub;
      let mensagem = "";
      const result = async () => {
        if (id !== '') {
          mensagem = "Registro atualizado com sucesso";
          return await api.patch(`termo/${id}`, {
            descricao: descricao,
            conteudo: conteudo,
          }, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          })
        } else {
          mensagem = "Cadastro realizado com sucesso";
          return await api.put("termo", {
            descricao: descricao,
            conteudo: conteudo,
            dataCadastro: new Date()
          }, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          })
        }
      }

      await result();

      Swal.fire({
        title: mensagem,
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/privacidade/termos');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }
  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Termo de uso</h1>
                <form onSubmit={handleSubmit}>
                  <div style={{ marginTop: "20px" }}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        disabled={view}
                        type="text"
                        onChange={(e) => setDescricao(e.target.value)}
                        value={descricao}
                        variant="outlined"
                        label="Descrição"
                      />
                    </FormControl>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <SimpleMDE
                      value={conteudo}
                      onChange={(value) => setConteudo(value)}
                      options={{
                        toolbar: ["heading-1", "heading-2", "heading-3", "|", "bold", "italic", "strikethrough", "|", "unordered-list", "ordered-list"],
                        minHeight: "11em",
                      }} />
                  </div>
                  <div className={classes.button}>
                    {!view &&
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                      </Button>
                    }
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/privacidade/termos') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Termo;
