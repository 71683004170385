import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  TextField,
  Input,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  AppBar,
  Tabs,
  Tab,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MaskedInput from 'react-text-mask';
import validator from 'cpf-cnpj-validator';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

import api from '../../services/api';

import './styles.scss';

import brazil from '../../assets/images/brazil.svg';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { TransitionProps } from '@material-ui/core/transitions';
import ReactMarkdown from 'react-markdown';
import Termos from '../../components/Termos';
import { UIStore } from '../../UIStore';
import Planos from '../Planos';
import UserGuide from '../../components/UserGuide';
import { maskCPF, maskName, maskPhoneNumber } from '../../utils/mask';
import SearchExames from '../../templates/SearchExames';

const translate = (term: string) => {
  const terms: { [key: string]: string } = {
    'Incorrect username or password.': 'Nome de usuário ou senha incorretos.',
    'Attempt limit exceeded, please try after some time.':
      'Limite de tentativas excedido, por favor tente mais tarde!',
  };
  if (Object.keys(terms).includes(term)) {
    return terms[term];
  }
  return term;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    tabs: {
      background: 'none',
      boxShadow: 'none',
      marginBottom: '32px',
      '& .MuiTab-root': {
        padding: '6px 8px',
      },
      '& .MuiTabs-scroller': {
        '& > span': {
          backgroundColor: '#00546B',
        },
      },
    },
    tab: {
      color: '#00546B',
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32) !important',
      },
    },

    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    grid2: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
          flexDirection: 'column',
        },
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    grid4: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '25%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    backButton: {
      background: '#F4A39D',
      color: '#FFFFFF',
      '&:hover': {
        background: '#FF2d2d',
      },
    },
    card_body: {
      flex: '1 1 auto',
      '& > h1': {
        textTransform: 'uppercase',
        margin: '0 .5rem',
      },
    },
  }),
);

interface Perfil {
  id: string;
  nome: string;
  sobrenome: string;
}

interface ParamTypes {
  id: string;
}
interface Plano {
  id: string;
  nome: string;
}

interface DataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function DataMask(props: DataMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface PhoneMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function PhoneMask(props: PhoneMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '5', '5', ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface State {
  peso: number;
  cpf: string;
}

interface UserGuideDTO {
  open: boolean;
  step: number;
  selectedTab: string;
}

function PerfilPaciente() {
  const history = useHistory<UserGuideDTO>();
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idPerfil, setIdPerfil] = useState({} as Perfil);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [contatoPreferencial, setContatoPreferencial] = useState('');
  const [selectedTab, setSelectedTab] = useState('perfil');
  const [consentimento, setConsentimento] = useState<{
    termo: number | false;
    politica: number | false;
  }>();
  const [termos, setTermos] = useState('');
  const [politica, setPolitica] = useState('');
  const [tipo, setTipo] = useState('');
  const [open, setOpen] = React.useState(false);
  const [planos, setPlanos] = React.useState([]);
  const [planoId, setPlanoId] = React.useState('');
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPasswordIcon, setNewPasswordIcon] = useState(<ShowEye />);
  const [confirmNewPasswordIcon, setConfirmNewPasswordIcon] = useState(<ShowEye />);
  const [oldPasswordIcon, setOldPasswordIcon] = useState(<ShowEye />);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);
  const [step, setStep] = useState(0);

  const handleChangeContato = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setContatoPreferencial(event.target.value as string);
  };

  const [values, setValues] = React.useState<State>({
    peso: 0,
    cpf: '',
  });

  const handleOpen = (tipo: string) => {
    setTipo(tipo);
    setOpen(true);
  };

  const handleClose = () => {
    setTipo('');
    setOpen(false);
  };

  const Joi = require('@hapi/joi').extend(validator);
  const cpfSchema = Joi.document().cpf().required();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const classes = useStyles();

  const getData = async () => {
    const userTokens = await Auth.currentSession();
    const sub = userTokens.getIdToken().payload.sub;
    const user = await api.get(`usuario/${sub}`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
      },
    });

    setId(user.data.id);
    setNomeCompleto(`${user.data.name} ${user.data.family_name}`);
    setValues({
      ...values,
      cpf: user.data.cpf,
    });
    setPlanoId(user.data.plano_usuario?.id);
    setContatoPreferencial(user.data.contato_preferencial);
    setEmail(user.data.email);
    setBirthdate(new Date(user.data.birthdate));
    setPhoneNumber(user.data.phone_number.replace('+55', ''));
  };

  const getPlanos = async () => {
    const planosResponse = await api.get('/plano');
    setPlanos(planosResponse.data);
  };

  const getTermosPoliticasAndConsetimento = async () => {
    const userTokens = await Auth.currentSession();
    const sub = userTokens.getIdToken().payload['sub'];
    const consentimentoResponse = await api.get(`/consentimento/${sub}`, {
      headers: {
        'X-Cognito-Id-Token': userTokens.getIdToken().getJwtToken(),
      },
    });
    const termosUsoResponse = await api.get('/termo/atual');
    const politicaResponse = await api.get('/politica/atual');
    setTermos(termosUsoResponse.data.conteudo);
    setPolitica(politicaResponse.data.conteudo);
    setConsentimento(consentimentoResponse.data);
  };

  useEffect(() => {
    getData();
    getPlanos();
    getTermosPoliticasAndConsetimento();
  }, []);

  useEffect(() => {
    const changeTabUserGuide = async () => {
      if (
        history.location.state !== undefined &&
        history.location.state !== null
      ) {
        if (
          history.location.state.open !== undefined &&
          history.location.state.step !== undefined
        ) {
          setOpenGuide(true);
          setStep(history.location.state.step);
        }

        if (history.location.state.selectedTab !== undefined) {
          setSelectedTab(history.location.state.selectedTab);
        }
      }
    };

    changeTabUserGuide();
  }, [history.location.state]);

  const handleCloseGuide = () => {
    setOpenGuide(false);
  };

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const userTokens = await Auth.currentSession();
      const nameArray = nomeCompleto.split(' ');
      const nome = nameArray.shift();
      const sobrenome = nameArray.join(' ');

      const response = await api.put(
        `usuario/${values.cpf}`,
        {
          name: nome,
          family_name: sobrenome,
          email: email,
          birthdate: birthdate,
          phone_number:
            phoneNumber !== '' ? '+55' + phoneNumber.replace(/\D/g, '') : '',
          contato_preferencial: contatoPreferencial,
        },
        {
          headers: {
            'X-Cognito-Access-Token': userTokens.getAccessToken().getJwtToken(),
          },
        },
      );
      Swal.fire({
        title: 'Perfil alterado com sucesso!',
        icon: 'success',
      });
      localStorage.setItem('name', nomeCompleto);
      UIStore.update(s => {
        s.signed = true;
        s.nivelUser = 'paciente';
        s.username = email;
        s.name = nomeCompleto;
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }
  }

  const handleTabChange = (e: ChangeEvent<{}>, value: any) => {
    setSelectedTab(value);
  };

  const handleDeleteAccount = async () => {
    Swal.fire({
      title: 'Você está prestes a excluir a sua conta da Clipo!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar!',
      confirmButtonColor: '#418107',
      confirmButtonText: 'Entendo e desejo proseguir!',
      html: `<span>Ao clicar em "Entendo e desejo prosseguir", você concorda que:</span>
        <ul style="list-style-type: circle; text-align: justify;">
          <li>Todos os seus dados pessoais serão excluídos da plataforma;</li>
          <li>Todos os dados pessoais de seus dependentes serão excluídos da plataforma;</li>
          <li>Todos os dados de seus pets serão excluídos da plataforma;</li>
          <li>Todos os registros de consentimento serão excluídos da plataforma;</li>
        </ul>
      `,
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Você tem certeza que deseja excluir sua conta da Clipo?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#418107',
          confirmButtonText: 'Sim, Deletar!',
          cancelButtonText: 'Cancelar!',
        }).then(async result => {
          if (result.isConfirmed) {
            try {
              const userTokens = await Auth.currentSession();
              const email = userTokens.getIdToken().payload.email;

              await api.delete(`usuario/${email}`, {
                headers: {
                  'X-Access-ID-Token': userTokens
                    .getAccessToken()
                    .getJwtToken(),
                },
              });

              Swal.fire({
                icon: 'success',
                title: 'Conta excluída com sucesso',
                html:
                  'Sua conta foi excluída junto com todos os cadastros de documentos, dependentes e pets',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              }).then(async result => {
                localStorage.clear();
                UIStore.replace({
                  signed: false,
                  nivelUser: '',
                  username: '',
                  name: '',
                });
                await Auth.signOut();
                window.location.href = '/';
              });
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro ao excluir a sua conta!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              });
            }
          } else {
            Swal.close();
          }
        });
      } else {
        Swal.close();
      }
    });
  };

  const handleSubmitPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (confirmNewPassword !== newPassword) {
        return Swal.fire({
          title: 'Erro!',
          text: 'A confirmação de senha não coincide com a nova senha!',
          icon: 'error',
        });
      }

      if (
        newPassword.match(
          /^(?=.*\d)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/,
        ) === null
      ) {
        return Swal.fire({
          title: 'Erro!',
          text: 'Sua senha não contém os caracteres necessários!',
          icon: 'error',
        });
      }

      const userTokens = await Auth.currentSession();
      const cliente = userTokens.getIdToken().payload['sub'];
      const response = await api.patch(
        `cognito/cliente/senha/${cliente}`,
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            'X-Access-ID-Token': userTokens.getAccessToken().getJwtToken(),
          },
        },
      );

      Swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: 'success',
        title: 'Sucesso!',
        text: translate(response.data.message),
      }).then(result => {
        if (result.isConfirmed) {
          history.push('/home');
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Atenção!',
        text: translate(error.response.data.message),
      });
    }
  };

  function handlePassword(type: string) {
    if (type === 'new') {
      setShowNewPassword(!showNewPassword);

      if (showNewPassword) setNewPasswordIcon(<ShowEye />);
      else setNewPasswordIcon(<HideEye />);
    } else if (type === 'old') {
      setShowOldPassword(!showOldPassword);

      if (showOldPassword) setOldPasswordIcon(<ShowEye />);
      else setOldPasswordIcon(<HideEye />);
    } else {
      setShowConfirmNewPassword(!showConfirmNewPassword);

      if (showConfirmNewPassword) setConfirmNewPasswordIcon(<ShowEye />);
      else setConfirmNewPasswordIcon(<HideEye />);
    }
  }

  const handleDownloadData = async () => {
    Swal.fire({
      title: 'Deseja solicitar um arquivo com todos os seus dados?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não!',
      confirmButtonColor: '#418107',
      confirmButtonText: 'Sim, solicitar!',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const userTokens = await Auth.currentSession();
          const sub = userTokens.getIdToken().payload['sub'];
          const response = await api.get(`/usuario/solicitar/${sub}`, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
            },
          });
          Swal.fire({
            icon: 'success',
            title: response.data.title,
            text: response.data.message,
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Atenção!',
            text: translate(error.response.data.message),
          });
        }
      } else {
        Swal.close();
      }
    });
  };

  const checkPasswordLength = (password: string) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  const checkSpecialCharacters = (password: string) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  const checkUppercase = (password: string) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  const checkLowercase = (password: string) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }
  };

  const checkNumber = (password: string) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkLowercase(event.target.value);
    checkNumber(event.target.value);
  };

  return (
    <>
      <main className="main-paciente">
        <SearchExames searchField={false} />
        <div className="grid-box grid-one">
          <div className="card-paciente">
            <div className={`${classes.card_body} perfil-paciente-form`}>
              <AppBar className={classes.tabs} position="static">
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab className={classes.tab} label="Perfil" value="perfil" />
                  <Tab className={classes.tab} label="Senha" value="senha" />
                  <Tab
                    className={classes.tab}
                    label="Privacidade"
                    value="privacidade"
                  />
                </Tabs>
              </AppBar>
              <div
                id="tabPerfil"
                className="tab-container "
                style={{ display: selectedTab === 'perfil' ? 'block' : 'none' }}
              >
                <form onSubmit={handleSubmit} className="form-perfil">
                  <div className={classes.grid2}>
                    <TextField
                      id="outlined-basic"
                      label="Nome completo"
                      variant="standard"
                      required
                      name="nomeCompleto"
                      value={nomeCompleto}
                      onChange={e => {
                        setNomeCompleto(maskName(e.target.value));
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="CPF"
                      variant="standard"
                      required
                      disabled
                      name="cpf"
                      value={maskCPF(values.cpf)}
                    />
                  </div>
                  <div className={classes.grid2}>
                    <TextField
                      type="email"
                      id="outlined-basic"
                      label="E-mail"
                      variant="standard"
                      required
                      name="email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      disabled
                    />
                    <FormControl fullWidth required>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableFuture
                          inputVariant="standard"
                          openTo="year"
                          format="dd/MM/yyyy"
                          label="Data de nascimento"
                          views={['year', 'month', 'date']}
                          value={birthdate}
                          onChange={value => {
                            setBirthdate(value);
                          }}
                          invalidDateMessage={'Inserir uma data válida'}
                          required
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </div>
                  <div className={classes.grid2}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        label="Telefone"
                        variant="standard"
                        required
                        name="phoneNumber"
                        value={`${maskPhoneNumber(phoneNumber)}`}
                        onChange={e => {
                          setPhoneNumber(`${maskPhoneNumber(e.target.value)}`);
                        }}
                        disabled
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Como prefere conversar com a gente?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="contatoPreferencial"
                        label="Contato Preferencial"
                        value={contatoPreferencial}
                        onChange={handleChangeContato}
                      >
                        <MenuItem value="E-mail">E-mail</MenuItem>
                        <MenuItem value="Telefone">SMS</MenuItem>
                        <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                        <MenuItem value="Nenhum">Nenhuma das opções</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="buttons-form">
                    <Button
                      className={classes.backButton}
                      variant="contained"
                      size="large"
                      onClick={() => {
                        history.push('/home');
                      }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                    >
                      Salvar
                    </Button>
                  </div>
                </form>
              </div>
              <div
                id="tabSenha"
                className="tab-container"
                style={{ display: selectedTab === 'senha' ? 'block' : 'none' }}
              >
                <form className="formControl" onSubmit={handleSubmitPassword}>
                  <div className="form-password">
                    <FormControl variant="standard">
                      <TextField
                        fullWidth
                        id="old_password"
                        label="Senha Antiga"
                        variant="standard"
                        className="oldPassword"
                        name="oldPassword"
                        required
                        type={showOldPassword ? 'text' : 'password'}
                        onChange={e => {
                          setOldPassword(e.target.value);
                        }}
                      />
                      <a
                        onClick={e => {
                          handlePassword('old');
                        }}
                      >
                        {oldPasswordIcon}
                      </a>
                    </FormControl>
                    <FormControl variant="standard">
                      <TextField
                        fullWidth
                        id="new_password"
                        label="Nova senha"
                        variant="standard"
                        className="newPassword"
                        name="newPassword"
                        required
                        type={showNewPassword ? 'text' : 'password'}
                        onChange={handlePasswordChange}
                      />
                      <a
                        onClick={e => {
                          handlePassword('new');
                        }}
                      >
                        {newPasswordIcon}
                      </a>
                    </FormControl>
                  </div>
                  <div className="validation">
                    <div className="validator">
                      {charNumberValid ? (
                        <CheckIcon className="success" />
                      ) : (
                        <CloseIcon />
                      )}
                      <p className="validation-item">Mínimo 8 caracteres</p>
                    </div>
                    <div className="validator">
                      {specialCharValid ? (
                        <CheckIcon className="success" />
                      ) : (
                        <CloseIcon />
                      )}
                      <p className="validation-item">1 caracter especial</p>
                    </div>
                    <div className="validator">
                      {uppercaseValid ? (
                        <CheckIcon className="success" />
                      ) : (
                        <CloseIcon />
                      )}
                      <p className="validation-item">1 letra maiúscula</p>
                    </div>
                    <div className="validator">
                      {lowercaseValid ? (
                        <CheckIcon className="success" />
                      ) : (
                        <CloseIcon />
                      )}
                      <p className="validation-item">1 letra minúscula</p>
                    </div>
                    <div className="validator">
                      {numberValid ? (
                        <CheckIcon className="success" />
                      ) : (
                        <CloseIcon />
                      )}
                      <p className="validation-item">1 número</p>
                    </div>
                  </div>
                  <div className="form-password">
                    <FormControl variant="standard">
                      <TextField
                        fullWidth
                        id="email_saude"
                        label="Confirmação da senha"
                        variant="standard"
                        className="confirmNewPassword"
                        name="confirmNewPassword"
                        required
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        onChange={e => {
                          setConfirmNewPassword(e.target.value);
                        }}
                      />
                      <a
                        onClick={e => {
                          handlePassword('confirm');
                        }}
                      >
                        {confirmNewPasswordIcon}
                      </a>
                    </FormControl>
                  </div>

                  <div className="buttons-form">
                    <Button
                      className={classes.backButton}
                      variant="contained"
                      size="large"
                      onClick={() => {
                        history.push('/home');
                      }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                    >
                      Salvar
                    </Button>
                  </div>
                </form>
              </div>
              <div
                id="tabPlano"
                className="tab-container"
                style={{ display: selectedTab === 'plano' ? 'block' : 'none' }}
              >
                <div className={classes.grid2}>
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Plano de usuário
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="planoUsuario"
                      label="Plano de usuário"
                      value={planoId}
                      disabled
                    >
                      {planos.map((plano: Plano) => {
                        return (
                          <MenuItem key={plano.id} value={plano.id}>
                            {plano.nome}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="email_saude"
                      fullWidth
                      label="Email da saúde"
                      disabled
                      variant="standard"
                      className="emailSaude"
                      name="emailSaude"
                      value={`${values.cpf.replace(
                        /\D/g,
                        '',
                      )}@exames.cliposaude.com.br`}
                      required
                    />
                  </FormControl>
                </div>
              </div>
              <div
                id="tabPrivacidade"
                className="tab-container"
                style={{
                  display: selectedTab === 'privacidade' ? 'block' : 'none',
                }}
              >
                <ul
                  style={{
                    listStyleType: 'none',
                    paddingInlineStart: '0px',
                    fontSize: '18px',
                  }}
                >
                  {consentimento && (
                    <>
                      {consentimento.termo && (
                        <li style={{ margin: 8 }}>
                          Você consentiu com os termos de uso em{' '}
                          {format(consentimento.termo, 'dd/MM/yyyy HH:mm:ss')} -{' '}
                          <span
                            className="clickable-span"
                            onClick={() => handleOpen('termos')}
                          >
                            Clique para ler os termos de uso vigentes
                          </span>
                        </li>
                      )}
                      {consentimento.politica && (
                        <li style={{ margin: 8 }}>
                          Você consentiu com a política de privacidade em{' '}
                          {format(
                            consentimento.politica,
                            'dd/MM/yyyy HH:mm:ss',
                          )}{' '}
                          -{' '}
                          <span
                            className="clickable-span"
                            onClick={() => handleOpen('politica')}
                          >
                            Clique para ler a política de privacidade vigente
                          </span>
                        </li>
                      )}
                    </>
                  )}
                </ul>
                <div className="buttons-privacy">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'red', color: 'white' }}
                    size="large"
                    startIcon={<DeleteForeverIcon />}
                    onClick={handleDeleteAccount}
                  >
                    Excluir minha conta
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'orange', color: 'white' }}
                    size="large"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadData}
                  >
                    Baixar todos os meus dados
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Termos tipo={tipo} handleClose={handleClose} open={open} />
        <UserGuide
          open={openGuide}
          handleClose={handleCloseGuide}
          step={step}
        />
      </main>
    </>
  );
}

export default PerfilPaciente;
