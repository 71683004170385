import React from 'react';
import { createStyles, makeStyles, Checkbox } from '@material-ui/core';
import { ImRadioUnchecked } from 'react-icons/im';
import { FaCheckCircle } from 'react-icons/fa';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      color: '#ccc',
      padding: 0,
      margin: 'auto .6rem',
      transition: '.2s',

      '&>span': {
        display: 'flex',
        margin: '0'
      },

      '&:hover': {
        color: '#01A3FF',
      },

      ['@media (max-width: 455px)']: {
        color: '##01A3FF',
      },
    },
    checked: {
      '&$checked': { color: '#01A3FF' },
      ['@media (max-width: 455px)']: {
        '&$checked': { color: '#01A3FF' },
      },
    },
  }),
);

export const CircleCheckbox = (props: {
  checked: boolean;
  onClick?: () => any;
}) => {
  const classes = useStyles();

  return (
    <Checkbox
      classes={{
        root: classes.checkbox,
        checked: classes.checked,
      }}
      icon={<ImRadioUnchecked />}
      checkedIcon={<FaCheckCircle />}
      checked={props.checked}
      onClick={props.onClick}
    />
  );
};
