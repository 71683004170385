import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import { Button, FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { maskCPF } from '../../utils/mask'
import { NavLink } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

import Swal from 'sweetalert2';

import api from '../../services/api';
import { Auth } from 'aws-amplify';

interface Data {
  name: string;
  family_name: string;
  cpf: string;
  email: string;
  phone_number: string;
  opcoes: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'cpf', numeric: false, disablePadding: true, label: 'CPF' },
  { id: 'email', numeric: false, disablePadding: true, label: 'E-mail' },
  { id: 'phone_number', numeric: false, disablePadding: true, label: 'Telefone' },
  { id: 'opcoes', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selecionado(s)
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Pacientes
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : ""}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitSpacing: {
      marginTop: "0px",
      paddingTop: "0px",
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridGap: '16px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    container: {
      marginBottom: "10px",     
      paddingTop: "10px",
      backgroundColor: "#FFF",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
    filterButton: {
      width: "100%",
      margin: "theme.spacing(1)"
    },
  }),
);

export default function Pacientes() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [nameSearch, setNameSearch] = React.useState('');
  const [cpfSearch, setCpfSearch] = React.useState('');
  const [emailSearch, setEmailSearch] = React.useState('');
  const [ageSearch, setAgeSearch] = React.useState("")
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const getData = async () => {
      const userTokens = await Auth.currentSession();
      const response = await api.get('/usuario', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      setRows(response.data);
    };

    getData();
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSearch = async () => {
    const url = `/usuario?${nameSearch !== '' ? `nome=${nameSearch}&` : ''}` +
      `${cpfSearch !== '' ? `cpf=${cpfSearch.replace(/\D/g, '')}&` : ''}` +
      `${emailSearch !== '' ? `email=${emailSearch}&` : ''}` +
      `${ageSearch !== '' ? `idade=${ageSearch}&` : ''}`;

    try {
      const userTokens = await Auth.currentSession();
      const response = await api.get(url, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      setRows(response.data);
    } catch (error) {

    }
  }

  const deleteUser = async (email: String) => {
    const url = `/usuario/${email}`
    try {
      const userTokens = await Auth.currentSession();
      const response = await api.delete(url, {
        headers: {
          'X-Access-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      })
      const status = await response.status;
      if (response.status >= 200 || response.status < 300) {

        return Swal.fire("Sucesso", "Usuário excluído com sucesso", "success").then(() => {
          window.location.reload();
        });
      }
      else {
        return Swal.fire("Usuário não foi excluído",
          `Verifique com seu adminsitrador`
          , "error")
      }
    } catch (error) {
      return Swal.fire("Algo errado aconteceu!",
        `Operação não executada`
        , "error")
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <main className={classes.fitSpacing}>
      <div className={classes.root}>
        <div className={classes.container} >
          <form className={classes.filtrosForm} onSubmit={handleSearch}>
            <FormControl>
              <InputLabel htmlFor="cpfSearch">Digite o nome do paciente</InputLabel>
              <Input
                name="nameSearch"
                id="nameSearch"
                value={nameSearch}
                onChange={(e) => { setNameSearch(e.target.value) }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="cpfSearch">Digite o CPF</InputLabel>
              <Input
                name="cpfSearch"
                id="cpfSearch"
                value={cpfSearch}
                onChange={(e) => { setCpfSearch(maskCPF(e.target.value)) }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="emailSearch">Digite o email</InputLabel>
              <Input
                name="emailSearch"
                id="emailSearch"
                value={emailSearch}
                onChange={(e) => { setEmailSearch(e.target.value) }}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Faixa Etária</InputLabel>
              <Select
                value={ageSearch}
                onChange={(e) => setAgeSearch(e.target.value as string)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>0 a 18 anos</MenuItem>
                <MenuItem value={1}>19 a 30 anos</MenuItem>
                <MenuItem value={2}>31 a 40 anos</MenuItem>
                <MenuItem value={3}>41 a 50 anos</MenuItem>
                <MenuItem value={4}>51 a 60 anos</MenuItem>
                <MenuItem value={5}>61 a 70 anos</MenuItem>
                <MenuItem value={6}>Mais de 70 anos</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.filterButton}
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >Pesquisar</Button>
            </FormControl>
          </form>
        </div>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {/* Listagem de pacientes */}
                        <TableCell padding="checkbox">
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name + " " + row.family_name}
                        </TableCell>
                        <TableCell>
                          {row.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
                        </TableCell>
                        <TableCell>
                          {row.email}
                        </TableCell>
                        <TableCell>
                          {row.phone_number ? row.phone_number.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "$1 ($2) $3-$4") : ""}
                        </TableCell>
                        <TableCell align="right">{/* Botão Editar dos pacientes listados */}
                          <NavLink to={{
                            pathname: "/usuario/paciente",
                            state: {
                              cpf: row.cpf
                            }
                          }}>
                            <Tooltip title="EDITAR PLANO DO PACIENTE">
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CreateIcon />}
                                className="no-text"
                              ></Button>
                            </Tooltip>
                          </NavLink>
                        </TableCell>
                        {/* Botão Editar dos pacientes listados */}
                        <TableCell align="left">
                          <Tooltip title="EXCLUIR PACIENTE">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<DeleteIcon />}
                              className="no-text"
                              onClick={() => deleteUser(row.email)}
                            ></Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows, display: 'none' }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main>
  );
}
