class NameValidator {
  fullName(fullName: string): Boolean{
    const [cleanFullName , cleanLastName ] = fullName.trim().match(/^\S*\s*(.*)$/) || [];

    const isFullName = cleanFullName && cleanLastName ? true : false;

    return isFullName
  }
}

export { NameValidator }
