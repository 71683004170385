import React, { useEffect, FormEvent, useState } from 'react';
import { IconButton, Modal } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import api from '../../services/api';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    modal: {
      padding: '10px',
      margin: '20px 0',
      position: 'absolute',
      width: '615px',
      maxHeight: '100vh',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      display: 'block',
      outline: 0,
      overflowY: 'scroll',
      overFlowX: 'hidden',
      border: '5px solid #21ACFC',
      borderRadius: '10px',
      ['@media (min-width:150px) and (max-width: 459px)']: {
        ['@media (max-height: 680px)']: {
          height: 'min-content',
          maxHeight: '80%',
        },
        width: '85%',
      },
      ['@media (min-width:460px) and (max-width: 800px)']: {
        width: '80%',
      },
      ['@media (min-width:801px) and (max-width: 1024px)']: {
        width: '600px',
      },
    },
    display: {
      display: 'inline-block',
    },
    page: {
      width: '245px',
      position: 'sticky',
      bottom: '5%',
      background: '#e7e7e4',
      margin: '0 auto',
      opacity: 1,
      transition: 'opacity ease-in-out 0.2s',
      boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
      borderRadius: '4px',
    },
    pagination: {
      color: '#000',
      marginLeft: '3px',
      marginRight: '3px',
    },
    title: {
      justifyContent: 'center',
      textAlign: 'center',
      color: '#21ACFC',
    },
    button: {
      width: '44px',
      height: '44px',
      background: '#e7e7e4',
      border: 0,
      fontSize: '1em',
      borderRadius: '4px',
      color: 'black',
      fontWeight: 'bold',
      '&:hover': {
        background: '#cfcfc9',
        cursor: 'pointer',
      },
      '&:disabled': {
        cursor: 'default',
        background: '#e7e7e4',
        fontWeight: 'normal',
      },
      '&:focus': {
        outline: '0px',
      },
    },
    iconButton: {
      fontSize: '22px',
    },
    container: {
      width: '100%',
    },
    loading: {
      margin: 0,
      position: 'absolute',
      top: '45%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, 50%)',
    },
    text: {
      margin: 0,
      padding: '10px',
    },
    message: {
      textIndent: '2em',
      textAlign: 'justify',
      fontSize: '18px',
    },
    buttons: {
      width: '255px',
      margin: '0 auto',
    },
    finally: {
      color: '#fff',
      width: '100px',
      padding: '10px',
      backgroundColor: '#f0972b',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '1em',
      '&:hover': {
        background: '#cfcfc9',
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 0,
      },
    },
    toTheTour: {
      fontWeight: 800,
      lineHeight: 2.75,
      backgroundColor: '#FF8F23 !important',
      color: '#fff !important',
    },
    closeButton: {
      background: '#e7e7e4',
      position: 'absolute',
      right: 0,
      margin: '16px 16px 0 0',
    },
  }),
);

interface ModalProps {
  open: boolean;
  id: string;
  src: string;
  body: string;
  arquivo: string;
  url: string;
  user: boolean;
  senha?: number;
  handleClose: () => void;
  handleOpen?: () => void;
}

interface BodyDTO {
  senha: number | undefined;
}

const ModalExam = (props: ModalProps) => {
  const classes = useStyles();

  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(false);
  const [body, setBody] = useState('');
  const [ext, setExt] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [src, setSrc] = useState('');
  const [scale, setScale] = useState(1);

  const handleApi = async (
    url: string,
    user: boolean,
    body: BodyDTO,
    responseType:
      | 'arraybuffer'
      | 'blob'
      | 'document'
      | 'json'
      | 'text'
      | 'stream'
      | undefined,
  ) => {
    if (user) {
      const userTokens = await Auth.currentSession();
      const response = await api.get(url, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
        responseType: responseType,
      });

      return response.data;
    } else {
      const response = await api.post(url, body, {
        responseType: responseType,
      });

      return response.data;
    }
  };

  useEffect(() => {
    setPageNumber(1);
    setOpen(props.open);
    setBody(props.body);
    setId(props.id);
    setUser(props.user);
    const getBody = async () => {
      const ext = props.arquivo.substr(props.arquivo.length - 3);
      setExt(ext);
      setSrc('');
      const url = props.url.replace(/:id/gi, props.id);
      if (props.arquivo !== '') {
        if (user) {
          if (ext === 'pdf') {
            handleApi(url, user, { senha: undefined }, 'arraybuffer').then(
              setBody,
            );
          } else {
            handleApi(url, user, { senha: undefined }, 'blob').then(result => {
              const imageURL = URL.createObjectURL(result);
              setSrc(imageURL);
            });
          }
        } else {
          if (ext === 'pdf') {
            handleApi(url, user, { senha: props.senha }, 'arraybuffer').then(
              setBody,
            );
          } else {
            handleApi(url, user, { senha: props.senha }, 'blob').then(
              result => {
                const imageURL = URL.createObjectURL(result);
                setSrc(imageURL);
              },
            );
          }
        }
      }
    };

    getBody();
  }, [props.open, props.id]);

  function handleZoomIn() {
    let zoomIn = scale + 1;
    setScale(zoomIn);
  }

  function handleZoomOut() {
    let zoomOut = scale - 1;
    setScale(zoomOut);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function handleLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handleLoad = () => (
    <div>
      <h3 className={classes.loading}>Carregando arquivo...</h3>
    </div>
  );

  const handleError = () => (
    <div>
      <h3 className={classes.loading}>Falha ao carregar o arquivo!</h3>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={props.handleClose}
      aria-labelledby="document-title"
      aria-describedby="document-description"
    >
      <>
        <IconButton onClick={props.handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        {ext === 'pdf' && open === true ? (
          <div
            id="document-description"
            className={classes.modal}
            style={{
              height: '90%',
            }}
          >
            <Document
              file={body}
              loading={handleLoad}
              noData={handleLoad}
              onLoadSuccess={handleLoadSuccess}
              onLoadError={handleError}
              renderMode="svg"
            >
              <button
                className={classes.closeButton}
                onClick={props.handleClose}
              >
                X
              </button>
              <Page
                scale={scale}
                className={classes.display}
                pageNumber={pageNumber}
              ></Page>
              <div className={classes.page}>
                <button
                  className={classes.button}
                  type="button"
                  disabled={scale === 1}
                  onClick={handleZoomOut}
                >
                  <ZoomOutIcon className={classes.iconButton} />
                </button>
                <button
                  className={classes.button}
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  {'<'}
                </button>
                <span className={classes.pagination}>
                  {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                </span>

                <button
                  className={classes.button}
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  {'>'}
                </button>

                <button
                  className={classes.button}
                  type="button"
                  onClick={handleZoomIn}
                >
                  <ZoomInIcon className={classes.iconButton} />
                </button>
              </div>
            </Document>
          </div>
        ) : (
          <div className={classes.modal}>
            {!src ? <p>Carregando...</p> : <img src={src} />}
          </div>
        )}
      </>
    </Modal>
  );
};

export default ModalExam;
