import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';


import grafismo from '../../assets/images/grafismo-user.png';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import api from '../../services/api';
import LoginScreenButton from '../../components/LoginScreenButton';
import Termos from '../../components/Termos';
import { checkAccount } from '../../services/api/login';
import './styles.scss';

function EsqueciPaciente() {
  const [username, setUsername] = useState('');
  const [tipo, setTipo] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleModal = (tipo: string) => {
    setTipo(tipo);
    setOpen(!!tipo);
  };



  const history = useHistory();

  useEffect(() => {
    const emailPaciente = localStorage.getItem('emailPaciente');
    if (emailPaciente) {
      setUsername(emailPaciente);
    }
  }, []);

  const sendCode = async (e: FormEvent) => {
    e.preventDefault();

    try {
      await checkAccount(username);
      const response = await api.post('/usuario/forgot-password', {
        email: username
      });
      localStorage.setItem('emailPaciente', username);
      Swal.fire({
        title: response.data.title,
        text: response.data.message,
        icon: 'success',
      }).then(() => {
        history.push('/redefinir');
      });
    } catch (err) {
      localStorage.setItem('emailPaciente', username);

      if (err.message === 'Usuário não foi confirmado') {
        localStorage.setItem('emailPaciente', username.replace(/\s/g, ""));
        history.push('/confirma');
      } else {
        Swal.fire({
          title: err.response.data.title,
          text: err.response.data.message,
          icon: 'error',
        });
      }
    }
  }

  return (
    <>
      <div id="confirmaPaciente">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} alt="Grafismo" />
          </div>
          <div className="background-blue-dark dark-1 v-height">
            <LoginScreenButton />
            <div className="form">
              <a href="/">
                <img className="LogoTextoClipo" src={LogoTextoClipo}></img>
              </a>
              <form className="materializeForm" onSubmit={sendCode}>
                <label htmlFor="email">E-mail</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                  required
                />
                <button>Enviar</button>
              </form>
              <div className="termosEmail">
                <a onClick={() => handleModal("termos")}>Termos de uso</a>
                <a onClick={() => handleModal("politica")}>Política de privacidade</a>
                <Termos
                  handleClose={() => handleModal('')}
                  open={open} 
                  tipo={tipo} 
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EsqueciPaciente;
