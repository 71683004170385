import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import api from '../../services/api'
import ReactMarkdown from 'react-markdown'

interface TermosEPoliticas {
  open: boolean;
  handleClose: () => void;
  tipo: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Termos: React.FC<TermosEPoliticas> = (props: TermosEPoliticas) => {
  const [open, setOpen] = useState(false);
  const [conteudo, setConteudo] = useState("");
  const [title, setTitle] = useState("");


  useEffect(() => {
    setOpen(props.open);

    const termoAtual = async () => {
      return await api.get('termo/atual');
    }

    const politicaAtual = async () => {
      return await api.get('politica/atual');
    }

    Promise.all([termoAtual(), politicaAtual()])
      .then((results) => {
        if (props.tipo === "termos") {
          setConteudo(results[0].data.conteudo);
          setTitle("Termos de uso");
        } else {
          setConteudo(results[1].data.conteudo);
          setTitle("Políticas de privacidade");
        }
      })

  }, [props.open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-termos"
        aria-describedby="alert-dialog-slide-termos"
      >
        <DialogTitle id="alert-dialog-termos">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-termos">
            <ReactMarkdown>
              {conteudo}
            </ReactMarkdown>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Termos;
