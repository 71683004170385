import React, { useEffect, FormEvent } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, Modal, TextField, Input, Select, MenuItem, FormControl, InputLabel, FormGroup, CircularProgress } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { formatISO } from 'date-fns';
import { NavLink } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import CancelIcon from '@material-ui/icons/Cancel';
import DateFnsUtils from '@date-io/date-fns';
import Swal from 'sweetalert2';
import api from '../../services/api';
import TableData from '../../components/TableData';
import { maskCPF } from '../../utils/mask';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
interface CpfMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CpfMask(props: CpfMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface DataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function DataMask(props: DataMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface Data {
  nome: string;
  cpf: string;
  dataCadastro: string;
  protocolo: string;
  status: boolean;
  opcoes: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data,
  label: string;
  numeric: boolean;
  status: boolean;
}

const headCells: HeadCell[] = [
  { id: 'nome', numeric: false, status: false, disablePadding: true, label: 'Nome' },
  { id: 'cpf', numeric: false, status: false, disablePadding: true, label: 'CPF' },
  { id: 'dataCadastro', numeric: false, status: false, disablePadding: true, label: 'Data' },
  { id: 'protocolo', numeric: false, status: false, disablePadding: true, label: 'Protocolo' },
  { id: 'status', numeric: false, status: true, disablePadding: true, label: 'Status' },
  { id: 'opcoes', numeric: false, status: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : headCell.status ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    display_button: {
      display: "grid"
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    windowsLoading: {
      position: 'absolute',
      top: '50%',
      left: '45%',
    },
    modal: {
      position: 'absolute',
      width: "45%",
      top: '4%',
      left: '30%',
      right: '40%',
      bottom: '10%',
      backgroundColor: "#fff",
      display: "block",
      outline: 0,
      overflow: 'scroll',
      border: '2px solid #21ACFC',
      borderRadius: '3px'
    },
    danger: {
      color: '#fff',
      pointerEvents: 'visible',
      border: '1px solid #dc3545',
      backgroundColor: '#dc3545',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    warning: {
      color: '#fff',
      pointerEvents: 'visible',
      border: '1px solid #e8c305',
      backgroundColor: '#e8c305',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    success: {
      color: '#fff',
      pointerEvents: 'visible',
      backgroundColor: '#218838',
      border: '1px solid #218838',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    display: {
      display: "inline-block"
    },
    page: {
      position: "absolute",
      bottom: "15%",
      left: "46%",
      background: "#e7e7e4",
      opacity: 1,
      transition: "opacity ease-in-out 0.2s",
      boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
      borderRadius: "4px",
      ['@media (min-width:345px) and (max-width: 450px)']: { // eslint-disable-line no-useless-computed-key
        left: '37%'
      },
      ['@media (max-width: 340px)']: { // eslint-disable-line no-useless-computed-key
        left: '32%'
      }
    },
    pagination: {
      color: "#000",
      marginLeft: "3px",
      marginRight: "3px"
    },
    title: {
      flex: '1 1 100%',
      paddingLeft: "16px",
      paddingRight: "8px",
      color: "#000"
    },
    filterTitle: {
      padding: '0px 20px 0px 16px',
      marginTop: "0px",
      marginBottom: "5px",
    },
    closeButton: {
      float: 'right',
      marginRight: '5px',
      marginTop: '5px',
      backgroundColor: 'white',
      outline: 0,
      border: "0px",
      width: "20px",
      fontSize: "16px",
      color: "#828282",
      cursor: "pointer"
    },
    button: {
      width: "44px",
      height: "44px",
      background: "#e7e7e4",
      border: 0,
      fontSize: "0.8em",
      borderRadius: "4px",
      color: "black",
      fontWeight: "bold",
      '&:hover': {
        background: "#cfcfc9",
        cursor: "pointer",
      },
      '&:disabled': {
        cursor: "default",
        background: "#e7e7e4",
        fontWeight: "normal",
      },
      '&:focus': {
        outline: "0px",

      }
    },
    filterButton: {
      margin: "theme.spacing(1)"
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
      gridGap: '10px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      '@media screen and (max-width: 990px)': {
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    container: {
      gridTemplateColumns: "[first] 40px [line2] 50px [line3] auto [col4-start] 50px [five] 40px [end]",
      gridTemplateRows: "[row1-start] 25% [row1-end] 100px [third-line] auto [last-line]",
      marginBottom: "10px",
      marginTop: "0px",
      backgroundColor: "#FFF",
      paddingTop: "10px",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
    select: {
      paddingRight: "0px !important",
      width: "94%",
      '@media screen and (max-width: 1150px)': {
        width: "94%",
      },
      '@media screen and (max-width: 991px)': {
        width: "96%"
      }
    },
    cpfSearch: {
      gridColumnStart: 0,
      gridColumnEnd: 2,
      gridRowStart: 1,
      '@media screen and (max-width: 1150px)': {
        gridRowStart: 1,
        gridColumnStart: 0,
        gridColumnEnd: 1,
      }
    },
    searchValue: {
      gridColumnStart: 2,
      gridColumnEnd: 4,
      gridRowStart: 1,
      '@media screen and (max-width: 1150px)': {
        gridColumnStart: 1,
        gridColumnEnd: 2,
      }
    },
    clearButton: {
      gridRowStart: 2,
      gridColumnStart: 4,
      '@media screen and (max-width: 1150px)': {
        gridRowStart: 2,
        gridColumnStart: 2,
      }
    },
    searchButton: {
      gridRowStart: 2,
      gridColumnStart: 5,
      '@media screen and (max-width: 1150px)': {
        gridRowStart: 2,
        gridColumnStart: 3,
      }
    },
    clean: {
      background: "#ff0000",
      color: "#fff"
    },
    checkbox: {
      gridColumnStart: 5,
      gridColumnEnd: 6,
      gridRowStart: 1,
      '@media screen and (max-width: 1150px)': {
        gridRowStart: 1,
        gridColumnStart: 3,
        gridColumnEnd: 4,
      }
    },
    dataDeSearch: {
      '@media screen and (max-width: 1150px)': {
        gridRowStart: 2
      }
    },
    dataAteSearch: {
      '@media screen and (max-width: 1150px)': {
        gridRowStart: 2
      }
    },
    label: {
      color: "#000000"
    },
  }),
);

interface ISearch {
  [name: string]: string | MaterialUiPickersDate | boolean
}

export default function Exames() {
  const classes = useStyles();
  const [examesEntreguesSearch, setExamesEntreguesSearch] = React.useState(false);
  const [examesNaoEntreguesSearch, setExamesNaoEntreguesSearch] = React.useState(false);
  const [fetchParams, setFetchParams] = React.useState<ISearch>({
    entreguesSearch: examesEntreguesSearch,
    naoEntreguesSearch: examesNaoEntreguesSearch
  });
  const [page, setPage] = React.useState(0);
  const [url, setUrl] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = React.useState('grid');

  const [id, setId] = React.useState('');
  const [arquivo, setArquivo] = React.useState('');
  const [body, setBody] = React.useState('');
  const [src, setSrc] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [option, setOption] = React.useState('cpf');
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [windowsLoading, setWindowsLoading] = React.useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const handleChangeOption = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOption(event.target.value as string);
  }

  const handleFilter = () => {
    if (display === 'grid') {
      setDisplay('none');
    } else {
      setDisplay('grid');
    }
  }

  useEffect(() => {
    setUrl("/exame");

    window.addEventListener('resize', handleWindowSizeChange);

    if (width <= 768) {
      setDisplay('none');
    } else {
      setDisplay('grid');
    }

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [width]);

  interface EnhancedTableToolbarProps {
    numSelected: number;
    selected: string[];
    handleData?: () => void
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Exames
        </Typography>
        <Tooltip title="Atualizar dados">
          <IconButton onClick={props.handleData} aria-label="filter">
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filtro de dados">
          <IconButton onClick={handleFilter} aria-label="filter">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cadastrar">
          <IconButton aria-label="Cadastrar">
            <NavLink to="exame"><AddIcon /></NavLink>
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  };

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: 'Você tem certeza que deseja deletar o cadastro!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Deletar!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const deleteConfirmed = async () => {
            const userTokens = await Auth.currentSession();
            const result = await api.delete(`/exame/${id}`, {
              headers: {
                'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
              }
            });
            const response = await api.get('/exame', {
              headers: {
                'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
              }
            });
            setRows(response.data);
          }

          deleteConfirmed();
        } catch (error) {
          console.log(`erroou: ${error}`);
        }
      }
    });

  };

  const handleView = async (id: string, arquivo: string) => {
    const ext = arquivo.substr(arquivo.length - 3);
    let typeFile = ext == 'pdf' ? 'application' : 'image';

    const userTokens = await Auth.currentSession();

    const getFile = await api.get(`/exame/${id}/arquivo`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      }
    });

    await api.get(`/exame/${id}/arquivo`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      },
      responseType: 'blob'
    })
      .then(response => {
        const data = response.data;
        const file = new Blob(
          [data],
          { type: typeFile + '/' + ext }
        );

        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute(
          'download',
          `${arquivo}`
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleOpen = async (id: string, arquivo: string) => {
    try {
      setWindowsLoading(true);
      const ext = arquivo.substr(arquivo.length - 3);

      let typeFile = ext == 'pdf' ? 'application' : 'image';

      const userTokens = await Auth.currentSession();

      await api.get(`/exame/${id}/arquivo`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        },
        responseType: 'blob'
      })
        .then(response => {
          const data = response.data;
          const file = new Blob(
            [data],
            { type: typeFile + '/' + ext }
          );

          const fileURL = URL.createObjectURL(file);

          const pdfWindow = window.open() as Window;

          pdfWindow.location.href = fileURL;
        })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Não foi possível visualizar o exame.'
      })
    } finally {
      setWindowsLoading(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setId("");
    setArquivo("");
    setBody("");
    setSrc("");
  }

  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();

    const params = new URLSearchParams();

    Object.keys(fetchParams).forEach((key) => {
      params.append(key, fetchParams[key] as string);
    });

    setUrl(`/exame?${params.toString()}`);
    setPage(0);
  }

  const handleClear = () => {
    setOption('cpf');
    setFetchParams({
      entregues: false,
      naoEntregues: false
    })
    setExamesEntreguesSearch(false);
    setExamesNaoEntreguesSearch(false);
    setUrl('/exame');
  }

  return (
    <main>
      <div id="filtros" style={{ display: display, transition: "width 2s, height 4s", transitionDuration: "2s" }}>
        <div className={classes.container}>
          <form action="" onSubmit={handleSearch}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Filtro para os exames
            </Typography>
            <div className={classes.filtrosForm}>
              <FormControl
                classes={{
                  root: classes.cpfSearch
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Digite o CPF"
                  className="cpfSearch"
                  name="cpfSearch"
                  id="cpfSearch"
                  value={maskCPF(fetchParams['cpf'] !== undefined ? fetchParams['cpf'] as string : '')}
                  onChange={(e) => {
                    setFetchParams({
                      ...fetchParams,
                      cpf: maskCPF(e.target.value)
                    });
                  }}
                />
              </FormControl>
              <FormControl classes={{
                root: classes.searchValue
              }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Digite o nome"
                  className="nameSearch"
                  name="nameSearch"
                  id="nameSearch"
                  value={fetchParams['nome'] !== undefined ? fetchParams['nome'] as string : ''}
                  onChange={(e) => {
                    setFetchParams({
                      ...fetchParams,
                      nome: e.target.value
                    });
                  }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Digite o protocolo"
                  className="protocoloSearch"
                  name="protocoloSearch"
                  id="protocoloSearch"
                  value={fetchParams['protocolo'] !== undefined ? fetchParams['protocolo'] as string : ''}
                  onChange={(e) => {
                    setFetchParams({
                      ...fetchParams,
                      protocolo: e.target.value
                    })
                  }}
                />
              </FormControl >
              <FormControl fullWidth classes={{
                root: classes.dataDeSearch
              }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    inputVariant="outlined"
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="DE"
                    views={["year", "month", "date"]}
                    value={fetchParams['dataCadastroDe'] !== undefined ? fetchParams['dataCadastroDe'] as MaterialUiPickersDate : null}
                    onChange={(date) => {
                      setFetchParams({
                        ...fetchParams,
                        'dataCadastroDe': date
                      });
                    }}
                    invalidDateMessage={"Inserir uma data válida"}
                    required
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl fullWidth classes={{
                root: classes.dataAteSearch
              }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    inputVariant="outlined"
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="ATÉ"
                    views={["year", "month", "date"]}
                    value={fetchParams['dataCadastroAte'] !== undefined ? fetchParams['dataCadastroAte'] as MaterialUiPickersDate : null}
                    onChange={(date) => {
                      setFetchParams({
                        ...fetchParams,
                        'dataCadastroAte': date
                      });
                    }}
                    invalidDateMessage={"Inserir uma data válida"}
                    required
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl component="fieldset" className={classes.checkbox}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    checked={examesEntreguesSearch}
                    control={<Checkbox color="primary" />}
                    label="Entregues"
                    labelPlacement="end"
                    classes={{
                      root: classes.label
                    }}
                    onChange={() => {
                      setFetchParams({
                        ...fetchParams,
                        entregues: !examesEntreguesSearch
                      })
                      setExamesEntreguesSearch(!examesEntreguesSearch)
                    }}
                  />
                  <FormControlLabel
                    checked={examesNaoEntreguesSearch}
                    control={<Checkbox color="primary" />}
                    label="Não entregues"
                    labelPlacement="end"
                    classes={{
                      root: classes.label
                    }}
                    onChange={() => {
                      setFetchParams({
                        ...fetchParams,
                        naoEntregues: !examesNaoEntreguesSearch
                      })
                      setExamesNaoEntreguesSearch(!examesNaoEntreguesSearch)
                    }}
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                classes={{
                  root: classes.clearButton
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<CancelIcon />}
                  className={classes.clean}
                  onClick={handleClear}
                >Limpar pesquisa</Button>
              </FormControl>
              <FormControl
                classes={{
                  root: classes.searchButton
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SearchIcon />}
                  className={classes.filterButton}
                  onClick={handleSearch}
                >Pesquisar</Button>
              </FormControl>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.root}>
        <TableData
          order='dataCadastro'
          type="clinicExams"
          url={url}
          page={page}
          headCells={headCells}
          handleOpenDoc={handleOpen}
          handleView={handleView}
          handleDelete={handleDelete}
          EnhancedTableToolbar={EnhancedTableToolbar}
        />
      </div>
      <Modal open={windowsLoading} ><div className={classes.windowsLoading}><CircularProgress style={{ 'color': 'white' }} size={'50px'} /></div></Modal>
    </main>
  );
}
