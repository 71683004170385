import React, { useState, useEffect, FormEvent } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';

import grafismo from '../../assets/images/grafismo-user.png';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import { useHistory } from 'react-router-dom';
import LoginScreenButton from '../../components/LoginScreenButton';
import Termos from '../../components/Termos';
import { confirmSignUp } from '../../services/api/user';
import './styles.scss';

function ConfirmaPaciente() {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [autoFocus, setAutoFocus] = useState(false);
  const [tipo, setTipo] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (tipo: string) => {
    setTipo(tipo);
    setOpen(true);
  };

  const handleClose = () => {
    setTipo('');
    setOpen(false);
  };

  useEffect(() => {
    const emailPaciente = localStorage.getItem('emailPaciente');
    
    if (emailPaciente) {
      setUsername(emailPaciente);
      setAutoFocus(true);
    } else {
      setAutoFocus(false);
    }
  }, []);

  async function signUp(e: FormEvent) {
    e.preventDefault();

    try {
      await confirmSignUp(username, code);

      history.push('/');
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Código inválido. Tente novamente ou gere um novo código.',
        icon: 'error',
      });
    }
  }

  const sendCode = async () => {
    try {
      const response = await Auth.resendSignUp(username);
      Swal.fire({
        title: 'Sucesso!',
        html: `Se o seu e-mail está cadastrado em nossa base, você receberá um código para realizar a ativação da sua conta. Enviado para: <b>${response.CodeDeliveryDetails.Destination}</b>`,
        icon: 'success',
      });
    } catch (err) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao enviar o código. Por favor, tente novamente!',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <div id="confirmaPaciente">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} alt="Grafismo" />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <a href="/">
                <LoginScreenButton />
                <img src={LogoTextoClipo}></img>
              </a>
              <form action="" className="materializeForm" onSubmit={signUp}>
                <label htmlFor="email">E-mail</label>
                <input
                  id="email"
                  type="email"
                  name="username"
                  value={username}
                  onChange={e => {
                    setUsername(e.target.value);
                  }}
                />
                <label htmlFor="código">Código de Verificação</label>
                <input
                  id="código"
                  type="text"
                  autoFocus={autoFocus}
                  required
                  name="code"
                  value={code}
                  onChange={e => {
                    setCode(e.target.value);
                  }}
                />
                <button>VERIFICAR</button>

                <div className="warning-span">
                  <p>
                    Verifique o código que enviamos para seu telefone ou e-mail
                    cadastrados!
                  </p>
                </div>
                <a
                  className="reenviarcodigo"
                  onClick={e => {
                    sendCode();
                  }}
                >
                  Reenviar o código
                </a>
              </form>
              <div className="termos">
                <a onClick={() => handleOpen('termos')}>Termos de uso</a>
                <a onClick={() => handleOpen('politica')}>
                  Política de privacidade
                </a>
                <Termos tipo={tipo} handleClose={handleClose} open={open} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmaPaciente;
