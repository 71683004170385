import React, { useEffect, FormEvent } from 'react';
import { Document, Page } from 'react-pdf';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, Modal, TextField, Input, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { Auth } from 'aws-amplify';
import { format, formatISO } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import { NavLink } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { v4 as uuid } from 'uuid'

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Swal from 'sweetalert2';

import api from '../../services/api';
import ModalExam from '../../components/ModalExam';

interface CpfMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CpfMask(props: CpfMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface DataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function DataMask(props: DataMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface Data {
  descricao: string;
  opcoes: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data,
  label: string;
  numeric: boolean;
  status: boolean;
}

const headCells: HeadCell[] = [
  { id: 'descricao', numeric: false, status: false, disablePadding: true, label: 'Descricao' },
  { id: 'opcoes', numeric: false, status: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : headCell.status ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    modal: {
      position: 'absolute',
      width: "45%",
      top: '4%',
      left: '30%',
      right: '40%',
      bottom: '10%',
      backgroundColor: "#fff",
      display: "block",
      outline: 0,
      overflow: 'scroll',
      border: '2px solid #21ACFC',
      borderRadius: '3px'
    },
    danger: {
      color: '#fff',
      pointerEvents: 'visible',
      border: '1px solid #dc3545',
      backgroundColor: '#dc3545',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    warning: {
      color: '#fff',
      pointerEvents: 'visible',
      border: '1px solid #e8c305',
      backgroundColor: '#e8c305',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    success: {
      color: '#fff',
      pointerEvents: 'visible',
      backgroundColor: '#218838',
      border: '1px solid #218838',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    display: {
      display: "inline-block"
    },
    page: {
      position: "absolute",
      bottom: "15%",
      left: "46%",
      background: "#e7e7e4",
      opacity: 1,
      transition: "opacity ease-in-out 0.2s",
      boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
      borderRadius: "4px",
      ['@media (min-width:345px) and (max-width: 450px)']: { // eslint-disable-line no-useless-computed-key
        left: '37%'
      },
      ['@media (max-width: 340px)']: { // eslint-disable-line no-useless-computed-key
        left: '32%'
      }
    },
    pagination: {
      color: "#000",
      marginLeft: "3px",
      marginRight: "3px"
    },
    title: {
      justifyContent: 'center',
      textAlign: 'center',
      color: "#21ACFC"
    },
    filterTitle: {
      padding: '0px 20px 0px 16px',
      marginTop: "0px",
      marginBottom: "5px",
    },
    closeButton: {
      float: 'right',
      marginRight: '5px',
      marginTop: '5px',
      backgroundColor: 'white',
      outline: 0,
      border: "0px",
      width: "20px",
      fontSize: "16px",
      color: "#828282",
      cursor: "pointer"
    },
    button: {
      width: "44px",
      height: "44px",
      background: "#e7e7e4",
      border: 0,
      fontSize: "0.8em",
      borderRadius: "4px",
      color: "black",
      fontWeight: "bold",
      '&:hover': {
        background: "#cfcfc9",
        cursor: "pointer",
      },
      '&:disabled': {
        cursor: "default",
        background: "#e7e7e4",
        fontWeight: "normal",
      },
      '&:focus': {
        outline: "0px",

      }
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridGap: '16px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    container: {
      gridTemplateColumns: "[first] 40px [line2] 50px [line3] auto [col4-start] 50px [five] 40px [end]",
      gridTemplateRows: "[row1-start] 25% [row1-end] 100px [third-line] auto [last-line]",
    }
  }),
);

interface TutorialDTO {
  id: string;
  descricao: string;
  arquivo: string;
  opcoes: string;
}

export default function Tutoriais() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('descricao');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = React.useState('none');
  const [typeFile, setTypeFile] = React.useState('');

  const [id, setId] = React.useState('');
  const [arquivo, setArquivo] = React.useState('');
  const [body, setBody] = React.useState('');
  const [src, setSrc] = React.useState("");
  const [rows, setRows] = React.useState<Array<TutorialDTO>>([]);

  useEffect(() => {
    const tutoriais: TutorialDTO = {
      id: uuid(),
      arquivo: "https://assets-clipo.s3.amazonaws.com/Clipo+Sa%C3%BAde+-+Cadastro+de+Usu%C3%A1rios.pdf",
      descricao: "Tutorial - Cadastro de usuários",
      opcoes: ""
    };

    setRows([tutoriais]);
  }, []);

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selecionado(s)
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Tutoriais
          </Typography>
        )}
      </Toolbar>
    );
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleView = async (id: string, arquivo: string) => {
    window.open(arquivo);
  }

  return (
    <main>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        /> */}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.descricao}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="DOWNLOAD">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleView(row.id, row.arquivo)}
                              className="no-text"
                            ></Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows, display: 'none' }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main>
  );
}
