import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { UploadIcon } from '../../utils/SvgIcons';
import { IFileUpload } from './index.d';
import './styles.scss';

function GetFiles(props: IFileUpload) {
  const onDrop = useCallback(acceptedFiles => {
    props.onChange(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props.acceptedFiles,
    onDropRejected(fileRejections, event) {

      fileRejections.forEach(({file})=>{
        const [filename, extension] = file.name.split(".")
        if (extension) {
          toast.error(`Arquivo ${filename + "." + extension} não suportado`, {duration: 5000})
        } else {
          toast(()=>(
            <p>Observação: Arquivos armazenados no Google Drive ou OneDrive não são suportados.
              <b> Os arquivos precisam ser armazenados na memória/disco do seu dispositivo para serem enviados.</b>
            </p>
          ), {duration: 10000})
        }
      })
    },
  });

  const renderDragMessage = (dragActive: boolean, dragReject: boolean) => {
    if (!dragActive) {
      return (
        <div className="container-message">
          <span className="message">{props.message}</span>
          <span className="typefiles">{` Formato de arquivos aceitos: ${props.acceptedFilesMessage}`}</span>
        </div>
      );
    }

    if (dragReject) {
      return (
        <div className="container-message">
          <p className="message error">Arquivo(s) não suportado(s)</p>
        </div>
      );
    }

    return (
      <div className="container-message">
        <p className="message success">Solte o(s) arquivo(s) aqui...</p>
      </div>
    );
  };

  return (
    <div
      {...getRootProps({
        className: `dropzone-container ${
          isDragAccept ? 'accept' : isDragReject ? 'reject' : ''
        }`,
      })}
    >
      <UploadIcon
        color={`${
          isDragAccept ? '#00546B' : isDragReject ? '#ff2d2d' : '#00546B'
        }`}
      />
      <input {...getInputProps()} />

      {renderDragMessage(isDragActive, isDragReject)}
    </div>
  );
}

export default GetFiles;
