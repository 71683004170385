import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

localStorage.setItem('tabsOpened', ((Number(localStorage.getItem('tabsOpened')) || 0) + 1).toString());

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
