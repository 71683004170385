import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    modal: {
      padding: '10px',
      position: 'absolute',
      width: '615px',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      display: 'block',
      outline: 0,
      overflow: 'auto',
      border: '2px solid #21ACFC',
      borderRadius: '10px',
      ['@media (min-width:150px) and (max-width: 459px)']: {
        ['@media (max-height: 680px)']: {
          height: '70%',
        },
        width: '85%',
      },
      ['@media (min-width:460px) and (max-width: 800px)']: {
        width: '80%',
      },
      ['@media (min-width:801px) and (max-width: 1024px)']: {
        width: '600px',
      },
    },
    display: {
      display: 'inline-block',
    },
    page: {
      width: '245px',
      position: 'sticky',
      bottom: '5%',
      background: '#e7e7e4',
      margin: '0 auto',
      opacity: 1,
      transition: 'opacity ease-in-out 0.2s',
      boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    pagination: {
      color: '#000',
      marginLeft: '3px',
      marginRight: '3px',
    },
    title: {
      justifyContent: 'center',
      textAlign: 'center',
      color: '#21ACFC',
    },
    closeButton: {
      background: '#e7e7e4',
      position: 'absolute',
      right: 0,
      margin: '16px 16px 0 0',
    },
    button: {
      width: '44px',
      height: '44px',
      background: '#e7e7e4',
      border: 0,
      fontSize: '1em',
      borderRadius: '4px',
      color: 'black',
      fontWeight: 'bold',
      '&:hover': {
        background: '#cfcfc9',
        cursor: 'pointer',
      },
      '&:disabled': {
        cursor: 'default',
        background: '#e7e7e4',
        fontWeight: 'normal',
      },
      '&:focus': {
        outline: '0px',
      },
    },
    iconButton: {
      fontSize: '25px',
    },
    container: {
      width: '100%',
    },
    loading: {
      margin: 0,
      position: 'absolute',
      top: '45%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, 50%)',
    },
    text: {
      margin: 0,
      padding: '10px',
    },
    message: {
      textIndent: '2em',
      textAlign: 'justify',
      fontSize: '18px',
    },
    buttons: {
      width: '255px',
      margin: '0 auto',
    },
    finally: {
      color: '#fff',
      width: '100px',
      padding: '10px',
      backgroundColor: '#f0972b',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '1em',
      '&:hover': {
        background: '#cfcfc9',
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 0,
      },
    },
    toTheTour: {
      fontWeight: 800,
      lineHeight: 2.75,
      backgroundColor: '#FF8F23 !important',
      color: '#fff !important',
    },
  }),
);
