import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { NavLinkProps, useLocation } from 'react-router-dom';
import DataList from '../../components/DataList';
import api from '../../services/api';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { maskDate } from '../../utils/mask';
import {
  handleDelete,
  handleDownload,
  getFileURL,
} from '../../utils/handleExam';
import ModalExam from '../../components/ModalExam';
import { WindowLoading } from '../../components/WindowLoading';
import { ViewerPDFModal } from '../../components/ViewerPDFModal';

const ExamesPet = () => {
  const [rows, setRows] = useState([]);
  const [id, setId] = useState('');
  const [archive, setArchive] = useState('');
  const [fileBody, setFileBody] = useState('');
  const [fileSrc, setFileSrc] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [windowLoading, setWindowLoading] = useState(false);
  const [openImgModal, setOpenImgModal] = useState<boolean>(false);
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const location = useLocation<NavLinkProps>();

  const handleOpenModalExam = async (id: string, archive?: string) => {
    if (archive) {
      const ext = archive.split('.').pop();

      if (ext === 'pdf') {
        setWindowLoading(true);
        const url = `/examepet/${id}/arquivo`;
        const fileUrl = await getFileURL(url, archive);
        setWindowLoading(false);
        if (fileUrl) setFileURL(fileUrl);

        setOpenFileModal(true);
      } else {
        setOpenImgModal(true);
        setId(id);
        setArchive(archive);
      }
    }
  };

  const handleCloseImgModal = () => {
    setOpenImgModal(false);
    setId('');
    setArchive('');
    setFileBody('');
    setFileSrc('');
  };

  const handleDataListRowProps = (row: any) => {
    return {
      title: row.pet.nome,
      subTitle: maskDate(new Date(row.dataCadastro)),
      onClick: handleOpenModalExam,
      param: row.arquivo,
      actions: [
        {
          description: 'Visualizar documento',
          tooltip: 'VER',
          icon: <VisibilityIcon />,
          param: row.arquivo,
          handle: handleOpenModalExam,
        },
        {
          description: 'Baixar documento',
          tooltip: 'DOWNLOAD',
          icon: <DownloadIcon />,
          param: row.arquivo,
          url: `/examepet/${row.id}/arquivo`,
          handle: handleDownload,
        },
        {
          description: 'Excluir',
          tooltip: 'EXCLUIR',
          icon: <DeleteIcon />,
          url: `/examepet/${row.id}`,
          param: 'exame',
          handle: handleDelete,
        },
      ],
    };
  };

  useEffect(() => {
    (async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get(`/examepet?pet=${location.state}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });

      if (response.data.length > 0) {
        setRows(response.data);
      } else {
        setRows([]);
      }
    })();
  }, []);
  return (
    <main className="exame-pet">
      <DataList
        handleRowProps={handleDataListRowProps}
        checkbox={false}
        orderBy="nome"
        orderByTitle="Nome"
        rows={rows}
        pageName="Exames"
        headActions={[
          {
            icon: <NoteAddIcon />,
            tooltip: 'CADASTRAR EXAME',
            link: '/pets/exame/' + location.state,
          },
        ]}
      />
      <ViewerPDFModal
        open={openFileModal}
        onClose={() => setOpenFileModal(false)}
        url={fileURL}
      />
      <ModalExam
        src={fileSrc}
        user={true}
        url="/examepet/:id/arquivo"
        open={openImgModal}
        id={id}
        arquivo={archive}
        handleClose={handleCloseImgModal}
        body={fileBody}
      />
      <WindowLoading loading={windowLoading} />
    </main>
  );
};

export default ExamesPet;
