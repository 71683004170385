import { Box, Modal } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Toaster } from 'react-hot-toast';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import api from '../../services/api';
import {
  BodyPartStep,
  PacientsStep,
  DocumentTypeStep,
  ExamTypeStep,
  UploadStep,
} from '../../templates/addExamModal';
import { cpfToSomeFormat } from '../../utils/cpfToSomeFormat';
import './styles.scss';

interface Props {
  isOpen: boolean;
  openModal: any;
}
interface ExameDTO {
  exame: {
    id: string;
  };
  edit: boolean;
}

interface IDadosPessoaExame {
  dependente?: {
    id?: string;
  };
  cliente?: string;
  cpf?: string;
  tipoDeDocumento?: string;
  parteDoCorpo?: string;
  categorias?: any;
}

export default function StepAddExamModal({ isOpen, openModal }: Props) {
  const [step, setStep] = useState(0);
  const [documentsType, setDocumentsType] = useState<any[]>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<any>();
  const [selectedPacient, setSelectedPacient] = useState<any>();
  const [bodyParts, setBodyParts] = useState<any[]>();
  const [selectedBodyPart, setSelectedBodyPart] = useState<any>();
  const [examTypes, setExamTypes] = useState<any[]>();
  const [selectedExamType, setSelectedExamType] = useState<any>();
  const progressRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const uploadModalRef = useRef<HTMLDivElement>(null);
  const [dadosPessoaExame, setDadosPessoaExame] = useState<IDadosPessoaExame>(
    {},
  );
  const [nomePaciente, setNomePaciente] = useState('');
  const [cpfPaciente, setCpfPaciente] = useState('');
  const [dependentes, setDependentes] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [validFiles, setValidFiles] = useState<any[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<any[]>([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState('');
  const history = useHistory();
  const { state } = useLocation<ExameDTO>();

  const handleChangeCpf = async () => {
    try {
      const cpfConsult = selectedPacient.cpf.replace(/\D/g, '');

      const userTokens = await Auth.currentSession();
      const dependenteExist = await api.get(`/dependente?cpf=${cpfConsult}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });

      const clienteExist = await api.get(`usuario/cpf/${cpfConsult}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });

      if (Object.keys(clienteExist.data).length > 0) {
        setDadosPessoaExame({
          cliente: clienteExist.data.id,
          tipoDeDocumento: selectedDocumentType?.nome,
          parteDoCorpo: selectedBodyPart?.nome,
          categorias: [selectedExamType],
        });
      } else if (dependenteExist.data.length > 0) {
        setDadosPessoaExame({
          dependente: {
            id: dependenteExist.data[0].id,
          },
          cpf: cpfConsult,
          tipoDeDocumento: selectedDocumentType?.nome,
          parteDoCorpo: selectedBodyPart?.nome,
          categorias: [selectedExamType],
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (state) {
      setEdit(state.edit);
      setId(state.exame?.id);
    }

    const getPaciente = async () => {
      const userTokens = await Auth.currentSession();
      const sub = userTokens.getIdToken().payload.sub;
      const user = await api.get(`/usuario/${sub}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });

      setNomePaciente(user.data.name + ' ' + user.data.family_name);
      setCpfPaciente(
        cpfToSomeFormat(user.data.cpf)
      );
    };

    getPaciente();

    const getDependentes = async () => {
      const userTokens = await Auth.currentSession();

      const { data: dependentes } = await api.get('/dependente', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });
      if (dependentes.length > 0) {
        setDependentes(dependentes);
      } else {
      }
    };

    getDependentes();

    const getPets = async () => {
      const userTokens = await Auth.currentSession();

      const { data: pets } = await api.get('/pet', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });
      if (pets.length > 0) {
        setPets(pets);
        return
      }
      setPets([]);
    };

    getPets();

    const getDocumentsType = async () => {
      const userTokens = await Auth.currentSession();
      const { data: documentsType } = await api.get('/tipo-de-documento', {
        headers: {
          'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
        },
      });
      setDocumentsType(documentsType);
    };

    getDocumentsType();

    const getBodyParts = async () => {
      const userTokens = await Auth.currentSession();
      const { data: bodyParts } = await api.get('/parte-do-corpo', {
        headers: {
          'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
        },
      });
      setBodyParts(bodyParts);
    };

    getBodyParts();

    const getExamTypes = async () => {
      const response = await api.get('/categoria');
      setExamTypes(response.data);
    };

    getExamTypes();
  }, []);


  useEffect(() => {
    setSelectedBodyPart(null);
    setSelectedExamType(null);

  }, [selectedDocumentType])

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result)
          resolve(reader.result.toString().replace(/^data:.+;base64,/, ''));
      };
      reader.onerror = error => reject(error);
    });

  const uploadProgress = (progressEvent: ProgressEvent) => {
    const uploadPercentage = Math.floor(
      (progressEvent.loaded / progressEvent.total) * 100,
    );
    if (progressRef.current) {
      progressRef.current.innerHTML = `${uploadPercentage}%`;
      progressRef.current.style.width = `${uploadPercentage}%`;
    }

    if (uploadPercentage === 100) {
      if (uploadRef.current) {
        uploadRef.current.innerHTML = 'Exame cadastrado com sucesso!';
        if (uploadModalRef.current) uploadModalRef.current.style.display = 'none';

        Swal.fire({
          title: 'Exame cadastrado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          } else {
            history.push('/exames');
          }
        });
      }
      setValidFiles([...validFiles]);
      setSelectedFiles([...validFiles]);
      setUnsupportedFiles([...validFiles]);
    }
  };

  const uploadFiles = async () => {
    openModal(!isOpen);
    if (uploadModalRef.current) uploadModalRef.current.style.display = 'block';
    if (uploadRef.current) uploadRef.current.innerHTML = 'Subindo exame...';

    try {
      let concatImages = false;
      const userTokens = await Auth.currentSession();
      const nomeExame = '';
      const ativo = true;

      let extensionsAreAllImagesExtensions = validFiles.every(file => (
        ['pdf', 'PDF'].includes(file.name.split('.').reverse()[0]) ? false : true
      ));

      if (extensionsAreAllImagesExtensions) {
        await Swal.fire({
          title: 'Deseja juntar todas as imagens em um único arquivo PDF?',
          icon: 'question',
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sim',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Não',
        }).then(result => {
          if (result.isConfirmed) {
            concatImages = true;
          } else {
            concatImages = false;
          }
        });
      }

      const promises = validFiles.map(async file => ({
        id: uuidv4(),
        nomeExame,
        ...dadosPessoaExame,
        arquivoBinario: {
          fileName: file.name,
          content: await toBase64(file),
        },
        ativo,
        cpf: selectedPacient.cpf.replace(/\D/g, ''),
      }));

      const bodyUpload = await Promise.all(promises);

      await api.put(
        'exame',
        {
          concatImages,
          exames: bodyUpload,
        },
        {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            uploadProgress(progressEvent);
          },
        },
      );
    } catch (err) {
      if (uploadRef.current) {
        uploadRef.current.innerHTML = `<span class="error">Erro ao subir exame!</span>`;
        Swal.fire({
          title: 'Erro ao subir exame!',
          icon: 'error',
        });
      }
      if (progressRef.current)
        progressRef.current.style.backgroundColor = 'red';
    }
  };

  const onChangeFile = async (filesUploaded: any[]) => {
    await handleChangeCpf();
    setValidFiles([...validFiles, filesUploaded].flat());
  };

  const removeFile = (index: number) => {
    const newFiles = [...validFiles];
    newFiles.splice(index, 1);
    setValidFiles(newFiles);
  };

  const stepsTypes = {
    defaultSteps: [
      <DocumentTypeStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        documentsType={documentsType}
        selectedDocumentType={selectedDocumentType}
        setSelectedDocumentType={setSelectedDocumentType}
      />,
      <PacientsStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        dependents={dependentes}
        paciente={{ cpf: cpfPaciente, nome: nomePaciente }}
        selectedPacient={selectedPacient}
        setSelectedPacient={setSelectedPacient}
      />,
      <UploadStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        onChange={onChangeFile}
        files={validFiles}
        removeFile={removeFile}
        uploadFiles={uploadFiles}
      />,
    ],
    examSteps: [
      <DocumentTypeStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        documentsType={documentsType}
        selectedDocumentType={selectedDocumentType}
        setSelectedDocumentType={setSelectedDocumentType}
      />,
      <PacientsStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        dependents={dependentes}
        paciente={{ cpf: cpfPaciente, nome: nomePaciente }}
        selectedPacient={selectedPacient}
        setSelectedPacient={setSelectedPacient}
      />,
      <BodyPartStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        bodyParts={bodyParts}
        selectedBodyPart={selectedBodyPart}
        setSelectedBodyPart={setSelectedBodyPart}
      />,
      <ExamTypeStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        examTypes={examTypes}
        selectedExamType={selectedExamType}
        setSelectedExamType={setSelectedExamType}
      />,
      <UploadStep
        isOpen={isOpen}
        openModal={openModal}
        step={step}
        setStep={setStep}
        onChange={onChangeFile}
        files={validFiles}
        removeFile={removeFile}
        uploadFiles={uploadFiles}
      />,
    ],
  };

  const stepsModal = selectedDocumentType?.nome.toUpperCase() === "EXAMES" || selectedDocumentType?.nome.toUpperCase() === "EXAME"
    ? stepsTypes.examSteps
    : stepsTypes.defaultSteps;

  const Component = () => {
    return <div className="modal-stepper">{stepsModal[step]}</div>;
  };

  const closeUploadModal = () => {
    if (uploadModalRef.current) uploadModalRef.current.style.display = 'none';
  }

  return (
    <>
      <Modal open={isOpen}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Component />
        </Box>
      </Modal>

      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay"></div>
        <div className="progress-container">
          <div className="close" onClick={(() => closeUploadModal())}>X</div>
          <span ref={uploadRef}></span>
          <div className="progress">
            <div className="progress-bar" ref={progressRef}></div>
          </div>
        </div>
      </div>
      <Toaster position='top-right' toastOptions={{style: {color: "red"}}}/>
    </>
  );
}
