import React, { useEffect, FormEvent, useState } from 'react';
import { Button, Modal, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import api from '../../services/api';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/CloseOutlined'
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import '../ModalDescription/style.scss'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: "auto",
      width: "50%",
      border: "2px solid #21ACFC",
      display: "block",
      outline: "0",
      borderRadius: "20px",
      backgroundColor: "#fff",
      padding: "20px",
      ['@media (min-width:150px) and (max-width: 450px)']: {
        width: "80%"
      },
      ['@media (min-width:451px) and (max-width: 768px)']: {
        width: "75%"
      }
    },
    header: {
      textAlign: "center",
    },
    textArea: {
      fontFamily: "Proxima Nova",
      border: "1px solid #c0c0c0",
      minHeight: "6em",
      cursor: "text",
      margin: "0 auto"
    },
    formControl: {
      width: "100%"
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    root: {
      position: "relative",
      left: "50%",
      transform: "translate(-50%, 50%)"
    },
    root_guide: {
      textAlign: "center",
      marginTop: "10px"
    },
    button: {
      width: "317px",
      margin: "0 auto",
      ['@media (min-width:451px) and (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
        width: '325px'
      },
      ['@media (max-width: 450px)']: { // eslint-disable-line no-useless-computed-key
        width: '150px'
      }
    }
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ModalDescProps {
  open: boolean;
  id: string;
  handleClose: () => void;
  cpf: string;
  type: "rate" | "categories" | "report";
}

interface CategoriasDTO {
  id: string;
  nome: string;
  ativo: boolean;
}

const labels: { [index: string]: string } = {
  1: "Muito insatisfeito",
  2: "Insatisfeito",
  3: "Neutro",
  4: "Satisfeito",
  5: "Muito satisfeito"
}

const ModalDescription = (props: ModalDescProps) => {
  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const [categorias, setCategorias] = useState<Array<CategoriasDTO>>([]);
  const [id, setId] = useState("");
  const [categoria, setCategoria] = useState<Array<CategoriasDTO>>([]);
  const [rating, setRating] = useState<number | null>(0);
  const [descricao, setDescricao] = useState("");
  const [cpf, setCpf] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [type, setType] = useState<"" | "rate" | "categories" | "report">("");
  const [rate, setRate] = useState(false);
  const [hover, setHover] = React.useState(-1);
  const [open, setOpen] = useState(false);

  const handleCategoria = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoria(event.target.value as CategoriasDTO[]);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const userTokens = await Auth.currentSession();

      const response = await api.patch(`exame/classificar/${id}`, {
        cpf: cpf,
        categorias: categoria,
        descricao: descricao,
        notaClinica: rating
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      props.handleClose();
      Swal.fire({
        title: 'Alterações salvas com sucesso!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: "#418107",
        confirmButtonText: "OK!",
      }).then((result) => {
        history.push('/exames');
      });
    } catch (err) {
      console.log(err)
    }
  }

  const handleGenerateReport = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const userTokens = await Auth.currentSession();
      const response = await api.post(`/clinica/report`, {
        referenceDate: date
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        },
        responseType: 'blob'
      });

      if (String(date) === 'Invalid Date') {
        throw Error('A data informada é inválida!');
      }

      if (date !== null && date > new Date()) {
        throw Error('Não há dados cadastrados!');
      }

      if (date !== null) {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );

        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute(
          'download',
          `relatorioMensalDeClinicas_${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : 0 + date.getMonth() + 1}/${date?.getFullYear()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);

        props.handleClose();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: `O relatório do mês ${date !== null && date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date?.getFullYear()} foi gerado com sucesso!`
        });
      }


    } catch (error) {
      props.handleClose();
      Swal.fire({
        icon: 'error',
        title: 'Atenção!',
        text: error.message
      });
    }
  }

  const onChange = (value: string) => {
    setDescricao(value);
  }

  useEffect(() => {
    setId(props.id);
    setOpen(props.open);
    setCpf(props.cpf);
    setType(props.type);

    const fetchData = async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get(`exame/${props.id}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      if (response.data.descricao !== null) {
        setDescricao(response.data.descricao);
        setCategoria(response.data.categorias);
      } else {
        setDescricao("");
        setCategoria([]);
        setRating(0);
      }

      const value = response.data.notaClinica !== null ? response.data.notaClinica : 0;
      setRating(value);
    }

    const fetchCategorias = async () => {
      const result = await api.get('categoria');

      setCategorias(result.data);
    }

    if (props.open) {
      fetchCategorias();
      fetchData();
    }

    return () => {
      setId("");
      setCpf("");
      setDescricao("");
      setCategoria([]);
      setRating(0);
      setDate(null);
    };

  }, [props.open, props.id]);

  const handleChangeState = async (value: Array<CategoriasDTO> | null) => {
    if (value !== null) {
      setCategoria(value);
    }
  }

  return (
    <Modal
      open={open}
      onClose={props.handleClose}
    >
      <div className={classes.modal}>
        {type === 'rate' && <h1 className={classes.header}>Avalie a clínica!</h1>}
        {type === 'categories' && <h1 className={classes.header}>Anotações do documento</h1>}
        {type === 'report' && <h1 className={classes.header}>Gerar relatório mensal</h1>}

        <form onSubmit={type === 'report' ? handleGenerateReport : handleSubmit}>
          {type === 'categories' &&
            <>
              <div>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    multiple
                    options={categorias}
                    getOptionLabel={(option) => option.nome}
                    value={categoria}
                    onChange={(event, value) => handleChangeState(value)}
                    renderInput={(params) => <TextField {...params} label="Selecione as categorias" />}
                  />
                </FormControl>
              </div>

              <div style={{ marginTop: "20px" }}>
                <SimpleMDE
                  value={descricao}
                  onChange={onChange}
                  options={{
                    toolbar: ["bold", "italic", "strikethrough", "|", "unordered-list", "ordered-list"],
                    minHeight: "11em",
                    placeholder: "Identificação do documento (Aqui você pode colocar uma descrição sobre seu documento facilitando suas buscas futuras)."
                  }} />
              </div>
            </>
          }

          {type === 'rate' &&
            <div style={{ marginTop: "60px" }}>
              <Box component="fieldset" mb={5}>
                <Typography style={{ margin: 0 }} component="legend">Avaliação da clínica</Typography>
                <Rating
                  classes={{
                    root: classes.root,
                  }}
                  name="customized-icons"
                  onChange={(event, value) => {
                    setRating(value);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  value={rating}
                  size="large"

                />
                {rating !== null && rating !== undefined && <Box className={classes.root_guide} ml={2}>{labels[hover !== -1 ? hover : rating]}</Box>}
              </Box>
            </div>
          }

          {type === 'report' &&
            <div style={{ marginTop: "60px" }}>
              <FormControl required>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    inputVariant="outlined"
                    openTo="year"
                    format="MM/yyyy"
                    label="Mês de referência"
                    views={["year", "month"]}
                    value={date}
                    onChange={(value) => {
                      setDate(value);
                    }}
                    invalidDateMessage={"Inserir uma data válida!"}
                    maxDateMessage={"Não é possível gerar relatório de datas futuras!"}
                    required
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
          }
          <div className={classes.button} >
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<CloseIcon />}
              onClick={props.handleClose}
            >
              Cancelar
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalDescription;
