import React from 'react';
import { useHistory } from 'react-router-dom';
import xIcon from '../../assets/images/x.svg';
import GetFiles from '../../components/GetFiles/';

interface Props {
  isOpen: boolean;
  openModal: any;
  step: number;
  setStep: any;

  documentsType?: any[];
  selectedDocumentType?: any;
  setSelectedDocumentType?: any;

  paciente?: any;
  dependents?: any[];
  selectedPacient?: any;
  setSelectedPacient?: any;
  handleChangeCpf?: (e: any) => void;

  bodyParts?: any[];
  selectedBodyPart?: any;
  setSelectedBodyPart?: any;

  examTypes?: any[];
  selectedExamType?: any;
  setSelectedExamType?: any;
}
interface IUploadFiles {
  openModal: any;
  isOpen: boolean;
  setStep: any;
  step: number;
  files: any[];
  onChange: (...event: any[]) => void;
  uploadFiles: (props?: any) => void;
  removeFile: (index: number) => void;
}

export const DocumentTypeStep = ({
  documentsType,
  selectedDocumentType,
  setSelectedDocumentType,
  openModal,
  isOpen,
  setStep,
  step,
}: Props) => {
  return (
    <>
      <img
        src={xIcon}
        className="icon-close-modal"
        onClick={() => openModal(!isOpen)}
      />
      <span>Que tipo de documento deseja subir?</span>
      <ul className="list-document-type-modal">
        {documentsType?.map(documentType => (
          <li
            key={documentType.id}
            onClick={() => setSelectedDocumentType(documentType)}
            style={
              selectedDocumentType?.nome === documentType.nome
                ? { background: '#77838F', color: '#FFFFFF' }
                : { background: '#F2F2F2', color: '#000000' }
            }
          >
            {documentType.nome.toUpperCase()}
          </li>
        ))}
      </ul>
      <div className="modal-add-exam-buttons">
        <button
          className="modal-back-button"
          onClick={() => openModal(!isOpen)}
        >
          VOLTAR
        </button>
        <button
          className="modal-save-button"
          onClick={() => setStep(step + 1)}
          disabled={selectedDocumentType ? false : true}
          style={
            !selectedDocumentType
              ? { background: '#CCCCCC', color: '#FFFFFF' }
              : {}
          }
        >
          PRÓXIMO
        </button>
      </div>
    </>
  );
};
export const PacientsStep = ({
  dependents,
  selectedDocumentType,
  selectedPacient,
  setSelectedPacient,
  paciente,
  openModal,
  isOpen,
  setStep,
  step,
}: Props) => {
  const history = useHistory();
  return (
    <>
      <img
        src={xIcon}
        className="icon-close-modal"
        onClick={() => openModal(!isOpen)}
      />
      <span>Qual o CPF do documento?</span>
      <ul className="list-dependents-modal">
        <li
          key={paciente?.cpf}
          onClick={e => {
            setSelectedPacient(paciente);
          }}
          style={
            selectedPacient?.nome === paciente?.nome
              ? { background: '#77838F', color: '#FFFFFF' }
              : { background: '#F2F2F2', color: '#00546B' }
          }
        >{`${paciente?.cpf} - ${paciente?.nome.split(' ', 1)}`}</li>
        {dependents?.filter((dependent => dependent.aprovado)).map(dependent => (
          <li
            key={dependent.cpf}
            onClick={() => {
              setSelectedPacient(dependent);
            }}
            style={
              selectedPacient === dependent
                ? { background: '#77838F', color: '#FFFFFF' }
                : { background: '#F2F2F2', color: '#00546B' }
            }
          >
            {`${dependent.cpf} - ${dependent.nome}`}
          </li>
        ))}
        <li>
          <a onClick={() => history.push('/dependente')}>Adicionar outro CPF</a>
        </li>
      </ul>
      <div className="modal-add-exam-buttons">
        <button className="modal-back-button" onClick={() => setStep(step - 1)}>
          VOLTAR
        </button>
        <button
          className="modal-save-button"
          disabled={selectedPacient ? false : true}
          onClick={() => setStep(step + 1)}
          style={
            !selectedPacient
              ? { background: '#CCCCCC', color: '#FFFFFF' }
              : {}
          }
        >
          PRÓXIMO
        </button>
      </div>
    </>
  );
};
export const BodyPartStep = ({
  bodyParts,
  selectedBodyPart,
  setSelectedBodyPart,
  openModal,
  isOpen,
  setStep,
  step,
}: Props) => {
  return (
    <>
      <img
        src={xIcon}
        className="icon-close-modal"
        onClick={() => openModal(!isOpen)}
      />
      <span>Qual parte do corpo ?</span>
      <ul className="list-body-parts-modal">
        {bodyParts?.map(bodyPart => (
          <li
            key={bodyPart.id}
            onClick={() => setSelectedBodyPart(bodyPart)}
            style={
              selectedBodyPart?.nome === bodyPart.nome
                ? { background: '#77838F', color: '#FFFFFF' }
                : { background: '#F2F2F2', color: '#000000' }
            }
          >
            {bodyPart.nome.toUpperCase()}
          </li>
        ))}
      </ul>
      <div className="modal-add-exam-buttons">
        <button className="modal-back-button" onClick={() => setStep(step - 1)}>
          VOLTAR
        </button>
        <button
          className="modal-save-button"
          disabled={selectedBodyPart ? false : true}
          style={
            !selectedBodyPart ? { background: '#CCCCCC', color: '#FFFFFF' } : {}
          }
          onClick={() => setStep(step + 1)}
        >
          PRÓXIMO
        </button>
      </div>
    </>
  );
};
export const ExamTypeStep = ({
  examTypes,
  selectedExamType,
  setSelectedExamType,
  openModal,
  isOpen,
  setStep,
  step,
}: Props) => {
  return (
    <>
      <img
        src={xIcon}
        className="icon-close-modal"
        onClick={() => openModal(!isOpen)}
      />
      <span>Qual o tipo de exame ?</span>
      <ul className="list-exam-type-modal">
        {examTypes?.map(type => (
          <li
            key={type.id}
            onClick={() => setSelectedExamType(type)}
            style={
              selectedExamType?.nome === type?.nome
                ? { background: '#77838F', color: '#FFFFFF' }
                : { background: '#F2F2F2', color: '#000000' }
            }
          >
            {type.nome.toUpperCase()}
          </li>
        ))}
      </ul>
      <div className="modal-add-exam-buttons">
        <button className="modal-back-button" onClick={() => setStep(step - 1)}>
          VOLTAR
        </button>
        <button
          className="modal-save-button"
          disabled={selectedExamType ? false : true}
          style={
            !selectedExamType ? { background: '#CCCCCC', color: '#FFFFFF' } : {}
          }
          onClick={() => setStep(step + 1)}
        >
          PRÓXIMO
        </button>
      </div>
    </>
  );
};
export const UploadStep = ({
  openModal,
  isOpen,
  setStep,
  step,
  files,
  onChange,
  uploadFiles,
  removeFile,
}: IUploadFiles) => {
  return (
    <>
      <img
        src={xIcon}
        className="icon-close-modal"
        onClick={() => openModal(!isOpen)}
      />
      <GetFiles
        acceptedFiles={['application/pdf', 'image/jpeg', 'image/png']}
        acceptedFilesMessage={`jpg, png e pdf`}
        message="Arraste, clique ou fotografe para enviar seus documentos."
        onChange={onChange}
      />
      <ul className="files-list">
        {files.map((file: File, index) => {
          return (
            <li key={index}>
              <span>{file.name}</span>
              <div onClick={() => removeFile(index)}>X</div>
            </li>
          );
        })}
      </ul>
      <div className="modal-add-exam-buttons">
        <button className="modal-back-button" onClick={() => setStep(step - 1)}>
          VOLTAR
        </button>
        <button
          className="modal-save-button"
          disabled={files.length ? false : true}
          onClick={uploadFiles}
          style={
            !files.length ? { background: '#CCCCCC', color: '#FFFFFF' } : {}
          }
        >
          UPLOAD
        </button>
      </div>
    </>
  );
};
