import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import { convertTo64 } from '../../services/convertTo64';
import api from '../../services/api';

import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    img: {
      maxWidth: '40px',
      maxHeight: "40px",
    },
    label: {
      display: "flex",
      color: "#AAAAAA",
      justifyContent: "center"
    }
  }),
);

interface DocumentType {
  id: string;
  nome: string;
  icon: string;
  iconUrl: string;
}

interface ParamTypes {
  id: string;
}

export default function TipoDeDocumento() {
  const params = useParams<ParamTypes>();
  const [id, setId] = useState(params.id);
  const [iconName, setIconName] = useState('');
  const [iconFile, setIconFile] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [nome, setNome] = useState('');
  const classes = useStyles();
  const history = useHistory();


  useEffect(() => {
    if (id) {
      const getData = async () => {
        const userTokens = await Auth.currentSession();

        const documentType: DocumentType  = (await api.get('/tipo-de-documento/' + id, {
          headers: {
            'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
          },
        })).data;

        if(documentType.id){
          setNome(documentType.nome)
          setIconUrl(documentType.iconUrl)
          setIconFile(documentType.icon)
        }
      };

      getData();
    }
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const userTokens = await Auth.currentSession();
    try {
      if (!nome) {
        Swal.fire({
          title: 'Digite o nome!',
          icon: 'warning',
        });
        return;
      }

      if (id) {
        await api.put(
          `/tipo-de-documento/${id}`,
          {
            nome,
            arquivoBinario: {
              fileName: iconName,
              content: iconFile,
            }
          },
          {
            headers: {
              'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
            },
          },
        );
      } else {
        await api.post(
          '/tipo-de-documento',
          {
            nome,
            arquivoBinario: {
              fileName: iconName,
              content: iconFile,
            }
          },
          {
            headers: {
              'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
            },
          },
        );
      }

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos',
      }).then(result => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/tiposdedocumentos');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }
  }

  async function handleIcon(event: any) {

    const [file] = event.target.files as File[]

    const extensao = file.name.split(".").reverse()[0]
    if (extensao !== "png") {
      return Swal.fire({
        text: "Utilize imagem do formato PNG com fundo transparente"
      })
    }

    setIconName(event.target.value);

    const convertedBase64 = await convertTo64(file) as string;

    setIconFile(convertedBase64);
  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Tipo de Documento</h1>
                <form onSubmit={handleSubmit}>
                  <Input type="hidden" name="id" value={id} />
                  <div className={classes.root}>
                    <TextField
                      id="outlined-basic"
                      label="Tipo de Documento"
                      variant="outlined"
                      name="nome"
                      value={nome}
                      onChange={e => {
                        setNome(e.target.value);
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Ícone"
                      variant="outlined"
                      type="file"
                      InputLabelProps={{ shrink: true }}
                      name="icone"
                      value={iconName}
                      onChange={handleIcon}
                    />
                  </div>
                  <div className={classes.root}>
                    <div />
                    {
                      iconUrl ?
                        <img
                          className={classes.img}
                          src={iconUrl}
                          alt=""
                        />
                        : <label className={classes.label}>
                          Insira uma imagem PNG 100px por 100px
                        </label>
                    }

                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => {
                        history.push('/tiposdedocumentos');
                      }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
