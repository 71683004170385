import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import darkPerfilIcon from '../../assets/images/perfil-icon-dark.svg';
import perfilIcon from '../../assets/images/perfil-icon.svg';
import plusIcon from '../../assets/images/plus-icon.svg';
import StepAddExamModal from '../../components/StepModal';
import api from '../../services/api';
import SearchExames from '../../templates/SearchExames';
import { whatsappUrl } from '../../utils/constants';
import { getExams } from '../../services/api/user';
import { ExameDTO } from '../../dtos/exameDto';
import './styles.scss';

interface DependenteDTO {
  id: string;
  nome: string;
  sobrenome: string;
  aprovado: boolean;
  pathImage: '';
  parentesco: {
    nome: string;
  };
}

type DocumentType = {
  id: string;
  nome: string;
  iconUrl: string;
}

type Tutorial = {
  openGuide: boolean;
};

function HomePaciente() {
  const location = useLocation<Tutorial>();
  const history = useHistory();
  const [name, setName] = React.useState<string | null>('');
  const [dependentes, setDependentes] = React.useState([]);
  const [cpf, setCpf] = React.useState('');
  const [openModal, setOpenModal] = useState(false);
  const [documentTypes, setDocumentsType] = useState<DocumentType[]>();

  useEffect(() => {
    const getPaciente = async () => {
      const user = await Auth.currentSession();
      const sub = user.getIdToken().payload.sub;
      const cliente = await api.get(`usuario/${sub}`, {
        headers: {
          'X-Cognito-ID-Token': user.getIdToken().getJwtToken(),
        },
      });

      setCpf(cliente.data.cpf);
      setName(cliente.data.name);
    };
    getPaciente();

    const getDependentes = async () => {
      try {
        const userTokens = await Auth.currentSession();
        const response = await api.get('dependente', {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
          },
        });
        setDependentes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getDependentes();

    const getDocumentsType = async () => {
      const userTokens = await Auth.currentSession();
      const { data: documentsType } = await api.get('/tipo-de-documento', {
        headers: {
          'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken(),
        },
      });
      setDocumentsType(documentsType);
    };

    getDocumentsType();
  }, [location.state]);

  const filterByDocumentType = async (document: string) => {
    try {
      const exams = await getExams();
      const data = exams.filter((exam: ExameDTO) => {
        return exam.tipoDeDocumento === document;
      });

      history.push('/exames', { data });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: error.message
      })
    }
  };

  const filterByDependent = async (dependent: string) => {
    try {
      const exams = await getExams();
      const data = exams.filter((exam: ExameDTO) => {
        return exam.dadosPessoaExame.nome === dependent;
      });

      history.push('/exames', { data });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: error.message
      })
    }
  };

  return (
    <>
      <main className="main-paciente">
        <SearchExames searchField={true} />

        <div className="card-paciente">
          <span className="name-paciente">{`Olá, ${name}`}</span>
          <span className="email-paciente">
            {cpf.replace(/\D/g, '')}@exames.cliposaude.com.br
          </span>
        </div>

        <div className="first-block-home">
          <div className="home-buttons">
            <button
              className="btn-add-exam"
              onClick={() => setOpenModal(!openModal)}
            >
              <img src={plusIcon} />
              <span>
                Adicionar
                <br />
                documento
              </span>
            </button>

            <button
              className="btn-my-profile"
              onClick={() => history.push('/perfil')}
            >
              <img src={darkPerfilIcon} />
              <span>
                Ver
                <br />
                meu perfil
              </span>
            </button>
          </div>

          <div className="home-documents">
            <button
              className="btn-help"
              onClick={() => {
                window.open(whatsappUrl, '_blank');
              }}
            >
              <span>Preciso de ajuda!</span>
            </button>
            <button
              className="btn-documents"
              onClick={() => history.push('/exames')}
            >
              <span>Ver Documentos</span>
            </button>
          </div>
        </div>
        <div className="second-block-home">
          <div className="cont-dependents">
            <h2>
              <Link to={'/dependentes'}>Dependentes</Link>
            </h2>
            {!dependentes.length ? (
              <button
                className="btn-add-dependents"
                onClick={() => history.push('/dependente')}
              >
                <img src={plusIcon} />
                <span>
                  Adicionar
                  <br />
                  dependente
                </span>
              </button>
            ) : (
              <ul className="list-dependents">
                {dependentes.map((dependente: DependenteDTO) => {
                  return (
                    <li
                      key={dependente.id}
                      onClick={() =>
                       filterByDependent(`${dependente.nome} ${dependente.sobrenome}`)
                      }
                    >
                      <img
                        src={
                          dependente.pathImage
                            ? dependente.pathImage
                            : perfilIcon
                        }
                      />
                      <span>
                        {`${dependente.nome} ${dependente.sobrenome}`
                          .split(' ')
                          .slice(0, 2)
                          .join(' ')}
                      </span>
                      <span>{dependente.parentesco.nome}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="cont-documents">
            <h2>Tipos de documentos</h2>
            <ul className="list-doc-options">
              {documentTypes?.map(item => (
                <li
                  key={item.id}
                  onClick={() => filterByDocumentType(item.nome)}
                >
                  {item.iconUrl ? (
                    <img className="icon" src={item.iconUrl} alt="Ícone" />
                  ) : (
                    <p>{item.nome.substr(0, 1)}</p>
                  )}
                  <span>{item.nome}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <StepAddExamModal isOpen={openModal} openModal={setOpenModal} />
      </main>
    </>
  );
}

export default HomePaciente;
