import axios from 'axios';
import { connectionErrorModal } from '../../utils/connectionErrorModal';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  function (config) {
    if (!navigator?.onLine) {
      connectionErrorModal();
      throw new axios.Cancel();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default api;
