import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, InputLabel, Button, FormControl, Select, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import Swal from 'sweetalert2';
import api from '../../services/api';
import './styles.scss';
import { convertTo64 } from '../../services/convertTo64';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },

    img: {
      maxWidth: '300px' ,
      maxHeight: "50px",
    },
    label: {
      display:"flex",
      color: "#AAAAAA",
      justifyContent: "center"
    }
  }),
);

interface UsuarioClinica {
  id: string;
  nome: object;
}

interface ParamTypes {
  id: string;
}

interface Medico {
  data: {
    id: string;
    fullName: string;
    crm: string;
    url: string;
    signature: string;
    medicoDefault: boolean;
    clinica: {
      id: string;
    };
  }
}

function MedicoClinica() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idUsuarioClinica, setIdUsuarioClinica] = useState({} as UsuarioClinica);
  const [idMedico, setIdMedico] = useState('')
  const [fullName, setFullName] = useState('');
  const [crm, setCrm] = useState('');
  const [urlSignature, setUrlignature] = useState('');
  const [awsSignature, setAwsSignature] = useState('');
  const [nameSignature, setNameSignature] = useState('');
  const [fileSignature, setFileSignature] = useState('');
  const [idClinica, setIdClinica] = React.useState('');
  const [clinicas, setClinicas] = React.useState([]);
  const [isDefault, setIsDefault] = useState(false);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIdClinica(event.target.value as string);
  }

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {

    const getMedico = async () => {
      const medico: Medico = await api.get(`clinica/medico/${params.id}`)
      if(medico.data.id){
        setIdMedico(medico.data.id)
        setFullName(medico.data.fullName);
        setCrm(medico.data.crm);
        setUrlignature(medico.data.url);
        setIdClinica(medico.data.clinica.id);
        setAwsSignature(medico.data.signature);
        setIsDefault(medico.data.medicoDefault);
      }
    }

    const getClinicas = async () => {
      const clinicas = await api.get('/clinica');
      setClinicas(clinicas.data);
    };

    getMedico();
    getClinicas();

    setIdClinica(params.id);

  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {

      const createdMedico = await api.put('/clinica/medico', {
        id: idMedico ? idMedico : undefined,
        fullName,
        crm,
        arquivoBinario: {
          fileName: nameSignature,
          content: fileSignature
        },
        signature: awsSignature,
        clinica: idClinica,
      }) as Medico

      await api.patch('/clinica/medico/default',{
          id: createdMedico.data.id,
          isDefault
      })

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        history.push('/medicos-clinica/' + idClinica);
      });

      
    } catch (error) {
      console.log(error.response)
      Swal.fire({
        icon: 'error',
        text: error.response.data.text,
        title: error.response.data.title
      });
    }

  }

  async function handleAssinatura (event:any){

    const [file] = event.target.files as File[]

    const extensao = file.name.split(".").reverse()[0]
    if(extensao !== "png"){
      return Swal.fire({
        text: "Utilize imagem do formato PNG com fundo transparente"
      })
    }

    setNameSignature(event.target.value);

    const convertedBase64 = await convertTo64(file) as string;

    setFileSignature(convertedBase64);
    console.log(convertedBase64);
  }

  return (
    <>
        <main>
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Médico</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={idUsuarioClinica}
                    />
                    <div className={classes.root}>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Clínica</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={idClinica}
                          onChange={handleChange}
                          label="Clínica"
                        >
                          {
                            clinicas.map((row: any, index) => {
                              return(
                                <MenuItem value={row.id}>{row.nomeFantasia}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        id="outlined-basic"
                        label="Nome Completo"
                        variant="outlined"
                        name="name"
                        value={fullName}
                        onChange={(e) => { setFullName(e.target.value) }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="CRM"
                        variant="outlined"
                        required
                        type="text"
                        name="crm"
                        value={crm}
                        onChange={(e) => { setCrm(e.target.value) }}
                        autoComplete="off"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Assinatura"
                        variant="outlined"
                        type="file"
                        InputLabelProps={{shrink: true}}
                        name="medicoAssinatura"
                        value={nameSignature}
                        onChange={handleAssinatura}
                      />
                    </div>
                    <div className={classes.grid}>

                      <div/>
                      <div/>
                      {
                        urlSignature ? 
                          <img
                            className={classes.img}
                            src={urlSignature}
                            alt=""
                          />
                        : <label className={classes.label}>
                             Insira uma imagem PNG 300px por 50px
                          </label>
                      }
                      </div>
                      <div className={classes.grid}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isDefault}
                            onChange={(e)=>(setIsDefault(!isDefault))}
                            color="primary"
                            name="medicoDefault"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Médico padrão"
                      />
                      </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/medicos-clinica/' + idClinica) }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
    </>
  )
}

export default MedicoClinica;
