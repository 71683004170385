import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ModalExam from '../../components/ModalExam';
import './styles.scss';
import { Redirect, useHistory } from 'react-router-dom';
import { UIStore } from '../../UIStore';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import LogoClipo from '../../assets/images/logo.svg';
import LogoProfile from '../../assets/images/profile.svg';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import UserGuide from '../UserGuide';
import api from '../../services/api';

interface TopMenuProps {
  name: string;
}

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #d3d4d5',
    backgroundColor: '#F2F2F2'
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: "#FF8F23",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      backgroundColor: "#f9b25a",
    }
  },

}))(MenuItem);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "white",
      "&:hover": {
        backgroundColor: "#f9b25a"
      }
    }
  }),
);

const TopMenu: React.FC<TopMenuProps> = ({ name }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const nivel = UIStore.useState(s => s.nivelUser);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleOpenGuide = () => {
    handleCloseDropdown();
    setOpenGuide(true);
  }

  const handleCloseGuide = () => {
    setOpenGuide(false);
  }

  const handleProfile = () => {
    setAnchorEl(null);
    history.push('/perfil');
  }

  const Logout = async () => {

    try {
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
      await Auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.log('error signing out: ', error);
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao fazer logout, por favor tente novamente!',
        icon: 'error',
      });
    }
  }

  return (
    <div className={`top-menu fixed-top${nivel === 'paciente' ? ' top-menu-paciente' : ''}`}>
      <div className="logo">
        <img src={LogoClipo} />
      </div>

      <div className="profile">
        <Button
          className={classes.button}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <svg fill="white" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="10 -20 130 130">
            <path d="M50,10c-10.794,0-18.177,8.711-16.403,19.362l2.686,16.133c1.068,6.394,7.24,11.621,13.718,11.621
            c6.481,0,12.649-5.228,13.714-11.621l2.689-16.133C68.181,18.711,60.798,10,50,10z M59.827,28.269l-2.688,16.133
            c-0.527,3.164-3.929,6.048-7.139,6.048s-6.611-2.884-7.142-6.055l-2.686-16.126c-0.531-3.187,0.179-6.126,1.998-8.275
            c1.82-2.145,4.6-3.327,7.829-3.327s6.013,1.182,7.832,3.33C59.648,22.142,60.358,25.082,59.827,28.269z"/>
            <path d="M83.675,64.558l-19.668-6.556c-3.76,3.535-8.736,5.781-14.007,5.781s-10.247-2.246-14.007-5.781l-19.668,6.556
            C12.845,65.717,10,69.668,10,73.334V90h80V73.334C90,69.668,87.155,65.717,83.675,64.558z M83.333,83.334H16.667v-10
            c0-0.801,1.009-2.201,1.771-2.455l16.201-5.396c4.583,3.223,9.912,4.967,15.361,4.967c5.446,0,10.778-1.744,15.361-4.967
            l16.204,5.396c0.759,0.254,1.768,1.654,1.768,2.455V83.334z"/>
          </svg>
          {name}
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
        >
          {nivel === 'paciente' &&
            <>
              <StyledMenuItem
                onClick={handleProfile}
              >
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Perfil do usuário" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={handleOpenGuide}
              >
                <ListItemIcon>
                  <HelpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Aqui tem uma explicação para você!" />
              </StyledMenuItem>
            </>
          }
          <StyledMenuItem
            onClick={Logout}
          >
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </StyledMenuItem>
        </StyledMenu>
      </div>
      <UserGuide
        open={openGuide}
        handleClose={handleCloseGuide}
        handleCloseTopMenu={handleCloseGuide}
        step={0}
      />
    </div>
  );
}

export default TopMenu;
