import React, { useState, useEffect, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import CircularProgressLoading from '../../assets/images/circular-progress.svg';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import { UIStore } from '../../UIStore';
import Termos from '../../components/Termos';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import validator from 'cpf-cnpj-validator';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { maskCPF, maskName, maskPhoneNumber } from '../../utils/mask';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { findNameByCpfAndBirthDate } from '../../services/hubDevCpf';
import { FaCheckCircle } from 'react-icons/fa';
import { ImRadioUnchecked } from 'react-icons/im';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      backgroundColor: '#00546B',
      minHeight: '100vh',
    },
    terms2: {
      borderRadius: '5px',
      marginTop: '20px',
      width: '75%',
      padding: '20px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    terms: {
      display: 'block',
      borderRadius: '5px',
      marginTop: '20px',
      color: '#fff',
      width: '75%',
      padding: '20px',
      margin: 'auto',
    },
    logo: {
      padding: '0 .1rem',
      zIndex: 5,
    },
    img: {
      width: '200px',
      height: 'auto',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: '50px',
    },
    message: {
      fontSize: '20px',
      textAlign: 'justify',
    },
    buttonCancel: {
      fontFamily: 'Raleway',
      fontWeight: 900,
      width: '150px',
      marginBottom: '20px',
      '&:hover': {
        filter: 'brightness(0.8) !important',
      },
    },
    buttonConfirm: {
      fontFamily: 'Raleway',
      fontWeight: 900,
      width: '150px',
      marginBottom: '20px',
      '&:hover': {
        backgroundColor: '#01A3FF!important',
        filter: 'brightness(0.8) !important',
      },
    },

    checkbox2: {
      color: '#ccc',
      marginBottom: '8px',
    },
    checked2: {
      '&$checked': { color: '#fff' },
    },

    grid1: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: '1fr',
      gridGap: '16px',
      marginBottom: '16px',
    },
    grid2: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: 'repeat(1, 1fr)',
      gridGap: '16px',
    },
  }),
);

class Contrato {
  termos?: boolean = false;
  politica?: boolean = false;
}

export default function NovoUsuarioSocial() {
  const classes = useStyles();
  const history = useHistory();
  const [contrato, setContrato] = useState<Contrato>({
    politica: false,
    termos: false,
  });
  const [signTermoDeUso, setSignTermoDeUso] = useState(false);
  const [signPoliticaDePrivacidade, setSignPoliticaDePrivacidade] = useState(
    false,
  );
  const [login, setLogin] = useState(false);
  const [open, setOpen] = useState(false);
  const [tipo, setTipo] = useState('');
  const [displayLoading, setDisplayLoading] = React.useState(true);
  const [nameLoading, setNameLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [displayForm, setDisplayForm] = React.useState(false);
  const [nomeCompleto, setNomeCompleto] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const Joi = require('@hapi/joi').extend(validator);
  const cpfSchema = Joi.document().cpf().required();

  useEffect(() => {
    const timer = setInterval(() => {
      result();
    }, 2500);

    const error = window.location.search.split('&');
    if (error.length > 0) {
      if (error[0].match(/facebook/g) !== null) {
        handleFederatedSignIn(CognitoHostedUIIdentityProvider.Facebook);
      }
      if (error[0].match(/google/g) !== null) {
        handleFederatedSignIn(CognitoHostedUIIdentityProvider.Google);
      }

      if (error[0].match(/attributes/g) !== null) {
        Swal.fire({
          icon: 'error',
          title: 'Atenção!',
          text:
            'Para continuar, precisamos do acesso ao seu perfil público (nome e foto de perfil) e email!',
        }).then(result => {
          if (result.isConfirmed || result.isDismissed) {
            handleBack();
          }
        });
      }

      if (error[2] === 'error=access_denied') {
        handleBack();
      }
      if (error[0].match(/confirmed/g) !== null) {
        Swal.fire({
          icon: 'error',
          title: 'Atenção!',
          text:
            'Já existe uma conta com esse email, é preciso confirmar a conta para atribuir o login pela rede social!',
        }).then(result => {
          if (result.isConfirmed || result.isDismissed) {
            handleBack();
          }
        });
      }
    }

    const result = async () => {
      const currentUser = await Auth.currentSession();
      const sub = currentUser.getIdToken().payload['sub'];
      const email = currentUser.getIdToken().payload['email'];

      const user = await api.get(`usuario/${sub}`, {
        headers: {
          'X-Cognito-ID-Token': currentUser.getIdToken().getJwtToken(),
        },
      });

      const firstLogin = await api.get(`/login/${email}`, {
        headers: {
          'X-Cognito-ID-Token': currentUser.getIdToken().getJwtToken(),
        },
      });

      const consentimento = await api.get(`/consentimento/${sub}`, {
        headers: {
          'X-Cognito-ID-Token': currentUser.getIdToken().getJwtToken(),
        },
      });

      if (user.data === '') {
        setContrato({
          politica: true,
          termos: true,
        });
        setEmail(currentUser.getIdToken().payload['email']);
        setDisplayForm(true);
        setDisplayLoading(false);
        clearInterval(timer);
      } else {
        if (!consentimento.data.politica || !consentimento.data.termo) {
          history.push({
            pathname: '/assinaturas',
            state: {
              politica: consentimento.data.politica,
              termo: consentimento.data.termo,
              login: firstLogin.data,
            },
          });
        } else if (!firstLogin.data) {
          history.push({
            pathname: '/agradecimento',
          });
        } else {
          UIStore.update(s => {
            s.signed = true;
            s.nivelUser = 'paciente';
            s.username = user.data.email;
            s.name = `${user.data.name} ${user.data.family_name}`;
          });

          const now = new Date();
          const expirationTime = now.setHours(now.getHours() + 24);
          localStorage.setItem('expirationTime', String(expirationTime));

          localStorage.setItem('signed', 'true');
          localStorage.setItem(
            'name',
            `${user.data.name} ${user.data.family_name}`,
          );
          localStorage.setItem('nivelUser', 'paciente');

          history.push({
            pathname: '/home',
          });
        }
      }
    };

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleFederatedSignIn = async (
    provider: CognitoHostedUIIdentityProvider,
  ) => {
    try {
      Swal.fire({
        title: 'Atenção!',
        icon: 'info',
        text:
          'Uma outra conta foi encontrada com o mesmo e-mail e é preciso fazer o login novamente para que seja feita a conexão entre as duas contas!',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCloseButton: false,
      }).then(async result => {
        await Auth.federatedSignIn({
          provider,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = (tipo: string) => {
    setOpen(true);
    setTipo(tipo);
  };

  const handleClose = () => {
    setOpen(false);
    setTipo('');
  };

  const handleBack = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      history.push('/');
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Atenção!',
        text: 'Ocorreu um erro ao executar a ação solicitada!',
      });
    }
  };

  async function handleName() {
    setNomeCompleto('');
    try {
      if (cpf && birthdate) {
        setNameLoading(true);
        const name = await findNameByCpfAndBirthDate(cpf, birthdate);
        setNomeCompleto(name);
        setDisabled(true);
      }
    } catch (error) {
      if (error.message === 'Network Error') {
        setDisabled(false);
      } else {
        setCpf('');
        setBirthdate(null);
        setNomeCompleto('');
        Swal.fire({
          title: 'Erro!',
          text: error.message,
          icon: 'error',
        });
      }
    } finally {
      setNameLoading(false);
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (phoneNumber) {
      const confirmPhone = await Swal.fire({
        title: 'Confirmar número de telefone',
        html: `Para sua segurança precisamos confirmar o número do seu celular. Este número está correto? <br><br> <b>${phoneNumber}</b>`,
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#34af23',
        cancelButtonColor: '#F4A39D',
        cancelButtonText: 'Corrigir',
      });

      if (!confirmPhone.isConfirmed) {
        return;
      }
    }

    try {
      const userAuthenticated = await Auth.currentAuthenticatedUser();
      if (!userAuthenticated) {
        Swal.fire({
          title: 'Sua sessão expirou!',
          text: 'Faça Login novamente',
          icon: 'warning',
        }).then(() => history.push('/'));
      }
    } catch (error) {
      Swal.fire({
        title: 'Algo errado aconteceu!',
        text: 'Tente novamente',
        icon: 'error',
      }).then(() => history.push('/'));
    }

    try {
      await cpfSchema.validateAsync(cpf);
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'CPF inválido!',
        icon: 'error',
      });
      return;
    }

    if (nomeCompleto.trim() === '') {
      Swal.fire({
        title: 'Erro!',
        text: 'É necessário que o campo de nome completo esteja preenchido!',
        icon: 'error',
      });
      return;
    }

    if (String(birthdate) === 'Invalid Date') {
      Swal.fire({
        title: 'Erro!',
        text: 'Data de nascimento informada não é inválida!',
        icon: 'error',
      });
      return;
    }

    const userTokens = await Auth.currentSession();

    let cliente = userTokens.getIdToken().payload['sub'];
    let email = userTokens.getIdToken().payload['email'];
    const nameArray = nomeCompleto.split(' ');
    const nome = nameArray.shift();
    const sobrenome = nameArray.join(' ');
    let nomeCliente = `${nome} ${sobrenome}`;

    try {
      await api.post(
        `usuario`,
        {
          sub: cliente,
          name: nome,
          family_name: sobrenome,
          email,
          cpf: cpf.replace(/\D/g, ''),
          phone_number:
            phoneNumber !== '' ? '+55' + phoneNumber.replace(/\D/g, '') : '',
          birthdate,
        },
        {
          headers: {
            'X-Access-ID-Token': userTokens.getAccessToken().getJwtToken(),
          },
        },
      );

      await api.put('consentimento', {
        ...contrato,
        email,
      });

      if (!login) {
        history.push({
          pathname: '/agradecimento',
        });
      } else {
        UIStore.update(s => {
          s.signed = true;
          s.nivelUser = 'paciente';
          s.username = email;
          s.name = nomeCliente;
        });

        const now = new Date();
        const expirationTime = now.setHours(now.getHours() + 24);
        localStorage.setItem('expirationTime', String(expirationTime));

        localStorage.setItem('signed', 'true');
        localStorage.setItem('name', nomeCliente);
        localStorage.setItem('nivelUser', 'paciente');
        history.push({
          pathname: '/paciente/home',
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.response.data.title,
        text: error.response.data.detail,
        icon: 'error',
      });
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.logo}>
        <img className={classes.img} src={LogoTextoClipo} />
      </div>
      {displayLoading && (
        <div className={classes.terms2}>
          <img
            className="circularProgressLoading"
            src={CircularProgressLoading}
            alt="Loading"
          />
          <h1
            style={{
              textAlign: 'center',
              color: '#ffff',
              fontFamily: 'Raleway',
            }}
          >
            {' '}
            Estamos verificando os seus dados, por favor aguarde....
          </h1>
        </div>
      )}
      {displayForm && (
        <div className={classes.terms}>
          <h3
            style={{
              textAlign: 'center',
              fontFamily: 'Raleway',
              marginTop: '2px',
            }}
          >
            Para continuar, precisamos de mais algumas informações...
          </h3>
          <form className="recadastrar-form" onSubmit={handleSubmit}>
            <div className={classes.grid1}>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="CPF"
                  variant="standard"
                  required
                  name="cpf"
                  onBlur={handleName}
                  value={maskCPF(cpf)}
                  onChange={e => {
                    setCpf(maskCPF(e.target.value));
                  }}
                />
              </FormControl>
              <FormControl required>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    inputVariant="standard"
                    disableFuture
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="Data de nascimento"
                    views={['year', 'month', 'date']}
                    value={birthdate}
                    onBlur={handleName}
                    onChange={value => {
                      setBirthdate(value);
                    }}
                    invalidDateMessage={'Inserir uma data válida'}
                    required
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="Telefone"
                  variant="standard"
                  name="phoneNumber"
                  value={maskPhoneNumber(phoneNumber)}
                  onChange={e => {
                    setPhoneNumber(maskPhoneNumber(e.target.value));
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.grid2}>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label={
                    !nameLoading ? (
                      <span>
                        {disabled ? (
                          <>Nome completo</>
                        ) : (
                          <>Nome completo (sem abreviações)</>
                        )}
                      </span>
                    ) : (
                      <i className="loading-name">
                        Buscando nome. Por favor, aguarde . . .{' '}
                      </i>
                    )
                  }
                  variant="standard"
                  disabled={disabled}
                  required
                  helperText={disabled && '- Preenchido automaticamente'}
                  name="nomeCompleto"
                  value={nomeCompleto}
                  onChange={e => {
                    setNomeCompleto(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="standard"
                  required
                  disabled
                  type="email"
                  name="email"
                  value={email}
                />
              </FormControl>
            </div>
            <div className="termos">
              <div>
                <Checkbox
                  classes={{
                    root: classes.checkbox2,
                    checked: classes.checked2,
                  }}
                  icon={<ImRadioUnchecked />}
                  checkedIcon={<FaCheckCircle />}
                  checked={signPoliticaDePrivacidade}
                  onChange={() =>
                    setSignPoliticaDePrivacidade(!signPoliticaDePrivacidade)
                  }
                  name="termo"
                  color="primary"
                  required
                />

                <span>
                  Declaro que li e estou de acordo com as condições da{' '}
                  <a
                    style={{ fontWeight: 700, textDecoration: 'underline' }}
                    onClick={() => handleOpen('politica')}
                  >
                    Política de Privacidade
                  </a>
                  .
                </span>
              </div>
              <div>
                <Checkbox
                  classes={{
                    root: classes.checkbox2,
                    checked: classes.checked2,
                  }}
                  icon={<ImRadioUnchecked />}
                  checkedIcon={<FaCheckCircle />}
                  checked={signTermoDeUso}
                  onChange={() => setSignTermoDeUso(!signTermoDeUso)}
                  name="termo"
                  color="primary"
                  required
                />

                <span>
                  Declaro que li e estou de acordo com as condições dos{' '}
                  <a
                    style={{ fontWeight: 700, textDecoration: 'underline' }}
                    onClick={() => handleOpen('termos')}
                  >
                    Termos de uso
                  </a>
                </span>
              </div>
            </div>
            <div className="action-buttons">
              <Button
                className={classes.buttonCancel}
                variant="contained"
                color="inherit"
                style={{
                  backgroundColor: '#ff3021',
                  color: 'white',
                }}
                size="large"
                onClick={handleBack}
              >
                Cancelar
              </Button>
              <Button
                className={classes.buttonConfirm}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Salvar
              </Button>
            </div>
          </form>
          <Termos tipo={tipo} handleClose={handleClose} open={open} />
        </div>
      )}
    </div>
  );
}
