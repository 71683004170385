import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, InputLabel, Button, FormControl, Select, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { Auth } from 'aws-amplify';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface UsuarioClinica {
  id: string;
  nome: object;
}

interface ParamTypes {
  id: string;
}

function UsuarioClinica() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idUsuarioClinica, setIdUsuarioClinica] = useState({} as UsuarioClinica);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [idClinica, setIdClinica] = React.useState('');
  const [clinicas, setClinicas] = React.useState([]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIdClinica(event.target.value as string);
  }

  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);

  const classes = useStyles();
  const history = useHistory();

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  useEffect(() => {
    const getClinicas = async () => {
      const clinicas = await api.get('/clinica');
      setClinicas(clinicas.data);
    };

    getClinicas();
  }, []);

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
    }
  }

  // useEffect(() => {
  //   if ( id ){
  //     const getData = async () => {
  //       const response = await api.get('/usuarioClinica/' + id);
  //       setIdUsuarioClinica(response.data.id);
  //       setNome(response.data.nome);
  //       setPreco({
  //         ...preco,
  //         preco : response.data.preco,
  //       });
  //       setAtivo(response.data.ativo);
  //       setEmail(response.data.email);
  //     };

  //     getData();
  //   }
  // }, []);


  // async function handleSubmit(e: FormEvent) {
  //   e.preventDefault();

  //   try {
  //     if ( !name ) {
  //       Swal.fire({
  //         title: 'Digite o nome!',
  //         icon: 'warning',
  //       });
  //       return;
  //     }

  //     const result = await api.put("/usuarioClinica", {
  //       id,
  //       name,
  //       ativo,
  //       email
  //     });

  //     Swal.fire({
  //       title: 'Cadastro realizado com sucesso!',
  //       icon: 'success',
  //       showCloseButton: true,
  //       showCancelButton: true,
  //       focusConfirm: false,
  //       confirmButtonText: 'Continuar',
  //       cancelButtonColor: '#418107',
  //       cancelButtonText: 'Ver Todos'
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.location.reload(false);
  //       } else {
  //         history.push('/usuarioClinicas');
  //       }
  //     });
  //   } catch (error) {
  //     Swal.fire({
  //       title: 'Erro!',
  //       text: error,
  //       icon: 'error',
  //     });
  //   }

  // }

  async function signUp(e: FormEvent) {
    e.preventDefault();

    try {
      const userTokens = await Auth.currentSession();

      const result = await api.put("/cognito/usuario-clinica", {
        nome: name,
        email,
        senha: password,
        clinica: idClinica
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        history.push('/clinicas');
      });
    } catch (error) {
      console.log(error.response)
      Swal.fire({
        icon: 'error',
        text: error.response.data.text,
        title: error.response.data.title
      });
    }

  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Usuário Clínica</h1>
                <form onSubmit={signUp}>
                  <Input
                    type="hidden"
                    name="id"
                    value={idUsuarioClinica}
                  />
                  <div className={classes.root}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Clínica</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={idClinica}
                        onChange={handleChange}
                        label="Clínica"
                      >
                        {
                          clinicas.map((row: any, index) => {
                            return (
                              <MenuItem value={row.id}>{row.nomeFantasia}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      id="outlined-basic"
                      label="Nome"
                      variant="outlined"
                      // required
                      name="name"
                      value={name}
                      onChange={(e) => { setName(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="E-mail"
                      variant="outlined"
                      required
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                      autoComplete="off"
                    />
                    <TextField
                      id="outlined-basic"
                      label="Senha"
                      variant="outlined"
                      required
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={password}
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                    <a onClick={(e) => { handlePassword() }}>{passwordIcon}</a>
                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/clinicas') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UsuarioClinica;
