import React, { useState, useEffect, FormEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { maskPhoneNumber } from '../../utils/mask';
import Swal from 'sweetalert2';
import api from '../../services/api';
import Auth from '@aws-amplify/auth';

interface User {
  name: string;
  email: string;
  phoneNumber?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 500,
    },
    display: {
      display: "inline-block"
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridGap: '16px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    name: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      '@media screen and (max-width: 1150px)': {
        gridColumnStart: 1,
        gridColumnEnd: 1,
      },
    },
    title: {
      padding: '5px 20px 0px 20px',
      gridRowStart: 1,
      gridRowEnd: 1
    },
    container: {
      gridTemplateColumns: "[first] 40px [line2] 50px [line3] auto [col4-start] 50px [five] 40px [end]",
      gridTemplateRows: "[row1-start] 25% [row1-end] 100px [third-line] auto [last-line]",
      marginBottom: "10px",
      marginTop: "10px",
      backgroundColor: "#FFF",
      paddingTop: "10px",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
  }),
);

export default function IndicarUsuario() {
  const classes = useStyles();
  const [newUsers, setNewUsers] = useState<User[]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    setName('');
    setEmail('');
    setPhoneNumber('');
  }, []);

  const handleAdd = (e: FormEvent) => {
    e.preventDefault();

    setNewUsers([...newUsers, {
      name,
      email,
      phoneNumber
    }]);
    setName('');
    setEmail('')
    setPhoneNumber('');
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const userTokens = await Auth.currentSession();

      const response = await api.post('/usuario/indicar', newUsers, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      Swal.fire({
        title: response.data.title,
        text: response.data.detail,
        icon: "success"
      }).then((result) => {
        if (result.isDismissed || result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      Swal.fire({
        title: error.response.data.title,
        text: error.response.data.detail,
        icon: "error"
      })
    }
  }

  const handleDelete = (user: User) => {
    const newArray = newUsers;
    const index = newArray.indexOf(user);
    newArray.splice(index, 1);

    if (newArray.length > 0) {
      setNewUsers([...newArray]);
    } else {
      setNewUsers([]);
    }
  }

  return (
    <main>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>Indique novos usuários</h1>
          </div>
          <form className={classes.filtrosForm} onSubmit={handleAdd}>
            <TextField
              className={classes.name}
              size="small"
              variant="standard"
              label="Nome Completo"
              required
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              size="small"
              variant="standard"
              type="email"
              label="Email"
              required
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              size="small"
              variant="standard"
              label="Telefone"
              id="phoneNumber"
              value={maskPhoneNumber(phoneNumber)}
              onChange={(event) => setPhoneNumber(maskPhoneNumber(event.target.value))}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              disabled={newUsers.length === 5 ? true : false}
            >
              Adicionar
            </Button>
          </form>
          {newUsers.length > 0 &&
            <>
              <div style={{
                marginLeft: "20px",
                marginRight: "20px"
              }}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome completo</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {newUsers.map((user) => (
                        <TableRow>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.phoneNumber}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              color="secondary"
                              className="no-text red-button"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDelete(user)}
                            ></Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div style={{
                marginLeft: "20px",
              }} >
                <form onSubmit={handleSubmit}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SaveIcon />}
                  >
                    Salvar
                  </Button>
                </form>
              </div>
            </>
          }
        </div>
      </div>
    </main>
  );
}
