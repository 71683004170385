import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { Auth } from 'aws-amplify';
import { addHours } from 'date-fns';
import psl from 'psl';
import React from 'react';

import './assets/styles/global.scss';
import { useNetwork } from './hooks/useNetwork';

import Routes from './routes';
import AmplifySetup from './utils/AmplifySetup';
import { connectionErrorModal } from './utils/connectionErrorModal';

function App() {
  const isOnline = useNetwork();
  const rootPath = window.location.href
    .replace(`${window.location.origin}/`, '')
    .split('/')[0];
  const domainInfo = psl.parse(window.location.hostname) as psl.ParsedDomain;
  const { domain, subdomain } = domainInfo;
  const useBasename = !(
    domain === 'cliposaude.com.br' && !subdomain?.includes('homologacao')
  );

  if (!isOnline) connectionErrorModal();

  const theme = createMuiTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ptBR,
  );

  let selectedFrontend: 'admin' | 'clinica' | 'paciente' | '' = '';

  if (domain === 'cliposaude.com.br' && !subdomain?.includes('homologacao')) {
    if (['backoffice', 'admin'].includes(subdomain!)) {
      selectedFrontend = 'admin';
    } else if (subdomain === 'empresas') {
      selectedFrontend = 'clinica';
    } else {
      selectedFrontend = 'paciente';
    }
  } else if (['admin', 'clinica', 'paciente'].includes(rootPath)) {
    selectedFrontend = rootPath as 'admin' | 'clinica' | 'paciente';
  }

  AmplifySetup(selectedFrontend);
  const lastClosing = Number(localStorage.getItem('lastClosing'));
  if (
    lastClosing &&
    new Date().getTime() > addHours(lastClosing, 8).getTime()
  ) {
    Auth.signOut();
    localStorage.clear();
    window.location.reload();
  } else {
    localStorage.removeItem('lastClosing');
    window.onunload = (e: BeforeUnloadEvent) => {
      localStorage.setItem(
        'tabsOpened',
        (Number(localStorage.getItem('tabsOpened')) - 1).toString(),
      );
      if (Number(localStorage.getItem('tabsOpened')) <= 0) {
        localStorage.setItem('lastClosing', new Date().getTime().toString());
        localStorage.removeItem('tabsOpened');
      }
    };
  }
  return (
    <ThemeProvider theme={theme}>
      <Routes useBasename={useBasename} frontend={selectedFrontend} />
    </ThemeProvider>
  );
}

export default App;
