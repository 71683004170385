import React from 'react';
import { Link } from 'react-router-dom';
import BotaoVoltar from '../../assets/images/seta-voltar.svg';

import "./styles.scss";

const LoginScreenButton = () => {

  return (
    <div className="loginScreenButton">
      <Link to="/">
        <img src={BotaoVoltar} alt="botão voltar" />
      </Link>
    </div>
  )
}

export default LoginScreenButton
