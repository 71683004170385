import React, { useState, useEffect, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Termos from '../../components/Termos';
import './styles.scss';
import grafismo from '../../assets/images/grafismo-user.png';
import { UIStore } from '../../UIStore';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import api from '../../services/api';
import { green} from '@material-ui/core/colors';
import SocialLogin from '../../components/SocialLogin';
import { checkAccount } from '../../services/api/login';
import { WindowLoading } from '../../components/WindowLoading';
import {WhatsButton} from '../../components/WhatsButton';

const translate = (term: string) => {
  const terms: { [key: string]: string } = {
    'UserMigration failed with error Incorrect user and password.':
      'Nome de usuário ou senha incorretos.',
    'Incorrect username or password.': 'Nome de usuário ou senha incorretos.',
    'User is not confirmed.': 'Usuário não foi confirmado.',
  };
  if (Object.keys(terms).includes(term)) {
    return terms[term];
  }
  return term;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: '#003f50',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      
      
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    icon: {
      width: '10%',
      marginRight: '10px',
      ['@media (max-width:500px)']: {
        width: '15%',
      },
    },
  }),
);

interface Assinatura {
  politica: boolean;
  termo: boolean;
}

function LoginPaciente() {
  const signed = UIStore.useState(s => s.signed);
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);
  const [tipo, setTipo] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleOpen = (tipo: string) => {
    setTipo(tipo);
    setOpen(true);
  };

  const handleClose = () => {
    setTipo('');
    setOpen(false);
  };

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  useEffect(() => {
    const emailPaciente = localStorage.getItem('emailPaciente');
    if (emailPaciente) {
      setUsername(emailPaciente);
    }

    const getData = async () => {
      if (signed) {
        history.push('/home');
      } else {
        try {
          await Auth.signOut();
          localStorage.clear();
          UIStore.replace({
            signed: false,
            nivelUser: '',
            username: '',
            name: '',
          });
        } catch (error) {
          Swal.fire({
            title: 'Erro!',
            text: 'Erro ao fazer logout, por favor tente novamente!',
            icon: 'error',
          });
        }
      }
    };

    getData();
  }, []);

  async function signIn(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    try {
      const usernameTrim = username.replace(/\s/g, '');
      await checkAccount(username);
      await Auth.signIn(usernameTrim, password);
      const userTokens = await Auth.currentSession();

      let email = userTokens.getIdToken().payload.email;

      const consentimento = async () => {
        return await api.get(`/consentimento/${email}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
          },
        });
      };

      const primeiroLogin = async () => {
        return await api.get(`/login/${email}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
          },
        });
      };

      Promise.all([consentimento(), primeiroLogin()]).then(results => {
        const assinatura: Assinatura = {
          politica: results[0].data.politica,
          termo: results[0].data.termo,
        };
        if (!assinatura.politica || !assinatura.termo) {
          history.push({
            pathname: '/assinaturas',
            state: {
              politica: assinatura.politica,
              termo: assinatura.termo,
              login: results[1].data,
            },
          });
        } else if (!results[1].data) {
          history.push({
            pathname: '/agradecimento',
          });
        } else {
          const name = `${userTokens.getIdToken().payload['name']} ${
            userTokens.getIdToken().payload['family_name']
          }`;
          const now = new Date();
          const expirationTime = now.setHours(now.getHours() + 24);
          localStorage.setItem('expirationTime', String(expirationTime));

          UIStore.update(s => {
            s.signed = true;
            s.nivelUser = 'paciente';
            s.username = username;
            s.name = name;
          });
          localStorage.setItem('signed', 'true');
          localStorage.setItem('name', name);
          localStorage.setItem('nivelUser', 'paciente');

          setLoading(false);
          history.push({
            pathname: '/home',
          });
        }
      });
    } catch (error) {
      if (error.message === 'Usuário não foi confirmado') {
        localStorage.setItem('emailPaciente', username.replace(/\s/g, ''));
        history.push('/confirma');
      } else if (error.code === 'NetworkError') {
        Swal.fire({
          title: 'Erro de conexão',
          text: 'Por favor, verifique sua conexão com a internet!',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Erro!',
          text: translate(error.message),
          icon: 'error',
        });
        localStorage.clear();
        setLoading(false);
        UIStore.replace({
          signed: false,
          nivelUser: '',
          username: '',
          name: '',
        });
      }
    }
  }

  return (
    <>
      <div id="loginCliente">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} alt="Grafismo" />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <img src={LogoTextoClipo} />
              <form className="materializeForm" onSubmit={signIn}>
                <label>E-mail</label>
                <input
                  type="text"
                  autoFocus
                  required
                  name="username"
                  value={username}
                  onChange={e => {
                    setUsername(e.target.value);
                  }}
                />
                <label> Senha</label>
                <div className="container-pass">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="password"
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                  <a
                    onClick={e => {
                      handlePassword();
                    }}
                  >
                    {passwordIcon}
                  </a>
                </div>
                <div className="float float-left">
                  <Link to="/confirma">Reenviar código</Link>
                  <Link to="/esqueci">Esqueceu a senha?</Link>
                </div>
                <button disabled={loading} className={'button'} type="submit">
                  Login
                </button>
                <WindowLoading loading={loading}/>                
                <SocialLogin />
                <Link to="/cadastro">Ainda não tenho cadastro</Link>
              </form>
              <div className="termos">
                <a onClick={() => handleOpen('termos')}>Termos de uso</a>
                <a onClick={() => handleOpen('politica')}>
                  Política de privacidade
                </a>
                <Termos tipo={tipo} handleClose={handleClose} open={open} />
              </div>
            </div>
          </div>
        </div>        
      </div>
      <WhatsButton/>
    </>
  );
}

export default LoginPaciente;
