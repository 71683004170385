import React from 'react';
import { whatsappUrl } from '../../utils/constants';
import iconWhatsapp from '../../assets/images/logos_whatsapp.svg';
import './styles.scss';

export const WhatsButton = () => {
  return (
    <a className={'main-whatsapp'} href={whatsappUrl} target="_blank">
      <img src={iconWhatsapp} />
    </a>
  );
};
