import React, { useState, useEffect, FormEvent } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';

import './styles.scss';

import grafismo from '../../assets/images/grafismo-admin.png';
import { UIStore } from '../../UIStore';

import LogoTextoClipo from '../../assets/images/logo-texto.svg';

const translate = (term: string) => {
  const terms: { [key: string]: string } = {
    'Incorrect username or password.': 'Nome de usuário ou senha incorretos.'
  };
  if (Object.keys(terms).includes(term)) {
    return terms[term];
  }
  return term;
}

function LoginAdmin() {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const userAuthenticated = await Auth.currentAuthenticatedUser();
        if (userAuthenticated) {
          history.push('/home');
        }
      } catch (error) {

      }
    };

    getData();
  }, []);

  async function signIn(e: FormEvent) {
    e.preventDefault();

    try {
      const usernameTrim = username.replace(/\s/g, "");
      const user = await Auth.signIn(usernameTrim, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const name = user.challengeParam.userAttributes.name;
        history.push('/nova-senha', { username, password, email: user.challengeParam.userAttributes.email, name });
      } else if (user.challengeName === 'MFA_SETUP') {
        history.push('/setup-mfa', { username, password });
      } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        history.push('/mfa', { username, password });
      } else {
        UIStore.update(s => {
          s.signed = true;
          s.nivelUser = 'admin';
          s.username = username;
        });
        localStorage.setItem('signed', 'true');
        localStorage.setItem('nivelUser', 'admin');
        history.push('/home');
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Erro!',
        text: translate(error.message),
        icon: 'error',
      });
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
    }

  }

  return (
    <>
      <div id="loginAdmin">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <img src={LogoTextoClipo} />
              <form className="materializeForm" onSubmit={signIn}>
                <input
                  required
                  type="text"
                  placeholder="Login"
                  autoFocus
                  name="username"
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                />
                <div className="container-pass">
                  <input
                    required
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Senha"
                    name="password"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                  />
                  <a onClick={(e) => { handlePassword() }}>{passwordIcon}</a>
                </div>
                {/* <Link to="/esqueci">Esqueci minha senha</Link> */}
                <button>Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginAdmin;
