import React from "react";
import { useEffect, useState } from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ProgressBar, Viewer, Worker } from "@react-pdf-viewer/core";
import Modal from '@material-ui/core/Modal';
import { HandlePdf } from "../../services/HandlePdf";
import './styles.scss'

interface IModalProps {
  isOpen: boolean;
  closeModal: () => void;
  documents?: any[];
  setValidFiles: any;
  uploadFiles: (x?: any) => void;
}

function ModalMergePdf({isOpen, documents,setValidFiles, uploadFiles, closeModal}: IModalProps){
  const handlePdf = new HandlePdf();

  const [open,setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  }

  const DragToReorderList = () => {

    let pdfs = documents?.map((document, index) => (
      {
        number: index,
        content: document,
        title: document.name,
        src: URL.createObjectURL(document)
      }
    ))
    const [list, setList] = useState(pdfs);
    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);


    const onDragStart = (event: any) => {
      const initialPosition = Number(event.currentTarget.dataset.position);

      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition as any,
        isDragging: true,
        originalOrder: list as any
      });
    }

    const onDragOver = (event: any) => {

      event.preventDefault();

      let newList = dragAndDrop.originalOrder;

      const draggedFrom = dragAndDrop.draggedFrom;

      const draggedTo = Number(event.currentTarget.dataset.position);

      const itemDragged = newList[draggedFrom as any];
      const remainingItems = newList.filter((item, index) => index !== draggedFrom);

      newList = [
        ...remainingItems.slice(0, draggedTo),
        itemDragged,
        ...remainingItems.slice(draggedTo)
      ] as any;

      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo as any
        })
      }

    }

    const onDrop = (event: any) => {

      setList(dragAndDrop.updatedOrder);

      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
        isDragging: false
      });
    }


    let onDragLeave = () => {
      setDragAndDrop({
        ...dragAndDrop,
        draggedTo: null
      });

    }


    return (

      <ul className="container-pdfs">

        {list?.map((item, index) => {
          return (
            <div className="content-pdf">
              <li
                key={index}

                data-position={index}
                draggable

                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}

                onDragLeave={onDragLeave}

                className={"document-pdf" + (dragAndDrop && dragAndDrop.draggedTo === Number(index) ? " drop-area" : "")}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                  {item.src &&
                    <Viewer
                      fileUrl={item.src}
                      renderLoader={(percentages: number) => (
                        <div>
                          <ProgressBar progress={Math.round(percentages)} />
                        </div>
                      )}
                    />}
                </Worker>
                <span className="pdf-title">{item.title}</span>
              </li>
            </div>

          )
        })}

      </ul>
    )
  };

  const getOrdenedPdf = async () => {
    const ordenedElements = Array.from(document.getElementsByClassName("document-pdf"))
    const elementsName = ordenedElements.map(element => element.lastChild?.textContent)

    const ordenedDocuments = elementsName?.map((name) => (
      documents?.find(document => document.name === name)
    ))

    const mergedPdf = await handlePdf.mergePDF(ordenedDocuments);
    closeModal();
    uploadFiles(mergedPdf);

    return;
  }

  return (
    <Modal
      open={open}
      classes={{
        root: "border-modal"
      }}
    >
      <div className="pdf-modal">
        <h2 className="info-merge-pdf">Ordene os PDFs para Juntar</h2>

        <DragToReorderList />

        <div className="buttons-modal">
          <button
            className="merge-button"
            onClick={getOrdenedPdf}
          >
            Juntar
          </button>
          <button
            className="close-button"
            onClick={closeModal}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export { ModalMergePdf }

