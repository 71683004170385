import React, { FormEvent, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, FormControl, IconButton, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { maskCPF, maskDate, maskDateToTimestamp } from '../../utils/mask';
import { IFilterProps } from './index.d';
import './styles.scss';

const Filter = (props: IFilterProps) => {
  const [isAutoCompleteField, setIsAutoCompleteField] = useState(false);
  const [autoComplete, setAutoComplete] = useState<Array<any>>([]);
  const [autoCompleteSearch, setAutoCompleteSearch] = useState('');
  const [autoCompleteSelected, setAutoCompleteSelected] = useState<Array<any>>([]);
  const [isSelectField, setIsSelectField] = useState(false);
  const [selections, setSelections] = useState<Array<string>>(['']);
  const [isTextField, setIsTextField] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [inputLabel, setInputLabel] = useState('Escolha a opção');
  const [option, setOption] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const { options, optionsLabel, open, onClose } = props;

  useEffect(() => {
    setOpenFilter(open);
  }, [open]);


  const handleSelectOption = (value: string) => {
    setSearch('');

    options.map((option: any) => {
      if (option.value === value) {
        setInputLabel(option.inputDescription);
        setOption(value);

        if (option.autoComplete) {
          setAutoComplete(option.autoComplete);
          setIsAutoCompleteField(true);
          setIsTextField(false);
          setIsSelectField(false);
        } else if (option.selects) {
          setSelections(option.selects)
          setIsSelectField(true);
          setIsTextField(false);
          setIsAutoCompleteField(false);
        } else {
          setIsTextField(true);
          setIsSelectField(false);
          setIsAutoCompleteField(false);
        }
      }
    });
  }

  const handleOptionValue = (value: any) => {
    if (option === 'cpf') {
      return setSearch(maskCPF(value));
    }

    if (isAutoCompleteField) {
      return setSearch(value)
    }

    setSearch(value);
  }

  const handleClear = () => {
    setIsTextField(false);
    setIsAutoCompleteField(false);
    setIsSelectField(false);
    setAutoComplete([]);
    setAutoCompleteSearch('');
    setAutoCompleteSelected([]);
    setStartDate(null);
    setEndDate(null);
    setSearch('');
    setOption('');
  }

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();

    const query = option === 'cpf' ? `cpf=${search.replace(/\D/g, '')}` : isAutoCompleteField ? `${option}=${autoCompleteSearch}` : `${option}=${search}`;
    const dataCadastroDe = startDate && maskDateToTimestamp(maskDate(startDate));
    const dataCadastroAte = endDate && maskDateToTimestamp(maskDate(endDate));
    const url = (`/exame?${query}` + (startDate ? `&dataCadastroDe=${dataCadastroDe}` : '') + (endDate ? `&dataCadastroAte=${dataCadastroAte}` : ''));

    props.handle(url);
    onClose();
  }

  return (
    <>
      <Dialog
        open={openFilter}
        onClose={onClose}
      >
        <CloseIcon className="close-filter" onClick={onClose} />
        <div className="filter-modal">
          <FormControl>
            <InputLabel id="demo-controlled-open-select-label">{optionsLabel ? optionsLabel : <span>Opção</span>}</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={option}
              onChange={(e) => { handleSelectOption(e.target.value as string) }}
            >
              {options.map((option: any) => {
                return <MenuItem value={option.value}>{option.placeHolder}</MenuItem>
              })}
            </Select>
          </FormControl>
          {isTextField && <FormControl><TextField
            fullWidth
            variant="standard"
            className="search"
            label={inputLabel}
            name="search"
            id="search"
            value={search}
            onChange={(e) => { handleOptionValue(e.target.value as string) }}
          /></FormControl>}
          {isSelectField && <FormControl>
            <InputLabel id="search"><span>{inputLabel}</span></InputLabel>
            <Select
              id="search"
              className="search"
              value={search}
              onChange={(e) => { handleOptionValue(e.target.value as string) }}
            >
              {selections.map((select: any) => {
                return <MenuItem value={select.id}>{select.nomeFantasia}</MenuItem>
              })}
            </Select>
          </FormControl>}
          {isAutoCompleteField && <FormControl variant="outlined">
            <Autocomplete
              multiple
              options={autoComplete}
              getOptionLabel={(option) => option.nome}
              value={autoCompleteSelected}
              onChange={(event, value) => {
                if (value !== null) {
                  setAutoCompleteSelected(value);
                  const response = value.map((result) => {
                    return result.id
                  });
                  setAutoCompleteSearch(JSON.stringify(response));
                }
              }}
              renderInput={(params) => <TextField {...params} label={inputLabel ? inputLabel : "Selecione as categorias"} />}
            />
          </FormControl>}
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableFuture
                openTo="year"
                format="dd/MM/yyyy"
                label="De"
                views={["year", "month", "date"]}
                value={startDate}
                onChange={(e: any) => {
                  setStartDate(e);
                }}
                invalidDateMessage={"Inserir uma data válida"}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableFuture
                openTo="year"
                format="dd/MM/yyyy"
                label="Até"
                views={["year", "month", "date"]}
                value={endDate}
                onChange={(e: any) => {
                  setEndDate(e);
                }}
                invalidDateMessage={"Inserir uma data válida"}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <div className="buttons">
            <FormControl>
              <Button
                disableElevation
                className="clear-button"
                variant="contained"
                size="small"
                onClick={handleClear}
              >Limpar pesquisa</Button>
            </FormControl>
            <FormControl>
              <Button
                disableElevation
                className="search-button"
                variant="contained"
                size="small"
                onClick={handleSearch}
              >Pesquisar</Button>
            </FormControl>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Filter;
