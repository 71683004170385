import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { Doughnut } from 'react-chartjs-2';
import './styles.scss';
import api from '../../services/api';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Swal from 'sweetalert2';
import { formatISO } from 'date-fns';

interface ExameDTO {
  notaClinica: number;
  protocolo: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   
    root: {
      position: "relative",
      left: "50%",
      transform: "translate(-50%, 50%)",
      ['@media (min-width:768px) and (max-width: 1024px)']: {
        left: "40%"
      },
      ['@media (min-width: 1400px)']: {
        left: "40%"
      }
    },
    disabled: {
      opacity: "1 !important"
    },
    large: {
      fontSize: "4.5rem",
      ['@media (max-width:320px)']: {
        fontSize: "3rem",
      },
      ['@media (min-width:330px) and (max-width: 760px)']: {
        fontSize: "4rem",
      },
    },
    root_guide: {
      textAlign: "center",
      marginTop: "35px",
      fontSize: "1.5em"
    },
    root_star: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
    },
    count: {
      display: "block",
      fontSize: "2em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      fontWeight: "bold"
    },
    title: {
      display: "block",
      fontSize: "1.75em",
      marginBlockStart: "0.1em",
      marginBlockEnd: "0.7em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      fontWeight: "bold",
    },
    title_graph: {
      display: "block",
      fontSize: "1.3em",
      marginBlockStart: "1em",
      textAlign: "center",
      fontWeight: "bold",
    },
    percent_graph: {
      display: "block",
      fontSize: "1.3em",
      textAlign: "center",
      fontWeight: "bold",
      marginBlockEnd: "0.5em"
    },
  }),
);

function HomeClinica() {
  const classes = useStyles();
  const [naoEntregues, setNaoEntregues] = useState(0);
  const [entregues, setEntregues] = useState(0);
  const [prctEntregue, setPrctEntregue] = useState<number | string>(0.0);
  const [prctConsumo, setPrctConsumo] = useState<number | string>(0.0);
  const [notaMedia, setNotaMedia] = useState(0);
  const [totalAvaliacoes, setTotalAvaliacoes] = useState(0);
  const [totalExames, setTotalExames] = useState(0);
  const [totalExamesRealizados, setTotalExamesRealizados] = useState(0);
  const [textColor, setTextColor] = useState("");
  const [examesContratados, setExamesContratados] = useState(0);

  const data = {
    labels: [
      'Exames Contratados',
      'Exames Relizados',
    ],
    datasets: [{
      data: [examesContratados, totalExamesRealizados],
      backgroundColor: [
        '#00546B',
        '#FF8F23',
      ],
      hoverBackgroundColor: [
        '#00546B',
        '#FF8F23',
      ]
    }]
  };

  const data2 = {
    labels: [
      'Exames não entregues',
      'Exames Entregues',
    ],
    datasets: [{
      data: [naoEntregues, entregues],
      backgroundColor: [
        '#00546B',
        '#FF8F23',
      ],
      hoverBackgroundColor: [
        '#00546B',
        '#FF8F23',
      ]
    }],
    width: 400,
    height: 200
  };

  const avaliacao = (exames: Array<ExameDTO>) => {
    let qty = 0;
    let grade = 0;
    exames.map((exame) => {
      if (exame.notaClinica !== null) {
        qty += 1;
        grade += exame.notaClinica;
      }
    });

    if (qty > 0) {
      const mediumGrade = Number((grade / qty).toFixed(1));
      setNotaMedia(mediumGrade);
      setTotalAvaliacoes(qty);
    }
  }

  useEffect(() => {
    let repeat: number;

    const getData = async () => {
      try {
        const userTokens = await Auth.currentSession();
        const idClinica = (userTokens.getIdToken().payload["custom:clinica"]);

        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        const dataDe = formatISO(new Date(currentYear, currentMonth, 1, 0, 0, 0,));
        const dataAte = formatISO(new Date(currentYear, currentMonth + 1, 0, 23, 59, 59));;

        const examesRealizados = () => (
          api.get(`/exame?dataCadastroDe=${dataDe}&dataCadastroAte=${dataAte}`, {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          })
        )

        const exames = () => (
          api.get('/exame', {
            headers: {
              'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
            }
          })
        );

        const contrato = () => {
          return api.get(`/clinica/${idClinica}`);
        };

        Promise.all([examesRealizados(), exames(), contrato()])
          .then((results) => {
            let entregues = 0;
            let realizados = 0;
            if (results[1].data.length > 0) {
              const delivered = results[1].data.filter((exame: ExameDTO) => {
                return exame.protocolo !== null;
              });

              const notDelivered = results[1].data.filter((exame: ExameDTO) => {
                return exame.protocolo === null;
              });

              entregues = Number(((delivered.length * 100) / results[1].data.length).toFixed(2));
              if (entregues <= 25) {
                setTextColor("#ff0000");
              } else if (entregues > 25 && entregues <= 50) {
                setTextColor("#FF8F23");
              } else if (entregues > 50 && entregues <= 75) {
                setTextColor("#0040ff");
              } else {
                setTextColor("#00cc00");
              }

              if (results[2].data.qtdeExames > 0) {
                realizados = Number(((results[0].data.length * 100) / results[2].data.qtdeExames).toFixed(2));
              }

              setEntregues(delivered.length);
              setNaoEntregues(notDelivered.length);
              setTotalExamesRealizados(results[0].data.length);
              setTotalExames(results[1].data.length);
              setPrctEntregue(entregues);
              setPrctConsumo(realizados);
              avaliacao(results[1].data);
            }

            setExamesContratados(results[2].data.qtdeExames);
          });

        repeat = setTimeout(getData, 45000);
      } catch (error) {
        Swal.fire({
          title: 'Erro!',
          text: "Ocorreu um erro ao carregar as informações",
          icon: 'error',
        });
      }
    }

    getData();

    return () => {
      if (repeat) {
        clearTimeout(repeat);
      }
    }
  }, []);

  (async () => {
    const user = await Auth.currentSession();
  })();
  return (
    <>
      <main >
        <div className="grid-box grid-two">
          <div className="card">
            <Doughnut data={data} />
            <span className={classes.title_graph}>Consumo</span>
            <span className={classes.percent_graph} style={{ color: textColor }}>{prctConsumo} %</span>
          </div>
          <div className="card">
            {naoEntregues > 0 && <Doughnut data={data2} />}
            <span className={classes.title_graph}>Percentual de exames entregues</span>
            <span className={classes.percent_graph} style={{ color: textColor }}>{prctEntregue} %</span>
          </div>
          <div className="card">
            <div className="card-body card-grid">
              <div>
                <svg width="80" height="80" viewBox="0 0 197 197" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M131.334 19.7H72.2336C64.9781 19.7 59.0996 25.5746 59.0996 32.8281V45.966H72.2336V32.832H118.2V65.666C118.2 72.9137 124.078 78.8 131.334 78.8H164.166V164.16H101.784V177.3H164.166C171.413 177.3 177.3 171.422 177.3 164.168V65.668L131.334 19.7ZM131.334 65.666V38.2712L158.719 65.666H131.334Z" fill="#9E9E9E" />
                  <path d="M88.6492 93.575C88.6492 74.5409 73.2064 59.1 54.1742 59.1C35.142 59.1 19.6992 74.5409 19.6992 93.575V152.675H29.5492V93.575C29.5492 79.98 40.5812 68.95 54.1742 68.95C67.7672 68.95 78.7992 79.98 78.7992 93.575L78.8051 152.675C78.8051 160.833 72.1899 167.45 64.0301 167.45C55.8704 167.45 49.2551 160.833 49.2551 152.675V93.575C49.2551 90.8564 51.4615 88.65 54.1801 88.65C56.9007 88.65 59.1051 90.8564 59.1051 93.575V152.675H68.9551V93.575C68.9551 85.4172 62.3399 78.8 54.1801 78.8C46.0204 78.8 39.4051 85.4172 39.4051 93.575V152.675C39.4051 166.268 50.4391 177.3 64.0301 177.3C77.6251 177.3 88.6551 166.268 88.6551 152.675L88.6492 93.575Z" fill="#FF8F23" />
                  <path d="M151.033 137.9H101.783V151.032H151.033V137.9Z" fill="#9E9E9E" />
                  <path d="M151.033 111.632H101.783V124.768H151.033V111.632Z" fill="#FF8F23" />
                </svg>
              </div>
              <div>
                <span className={classes.count}>{totalExames} exames</span>
                <span className={classes.title} style={{ marginTop: "30px" }}></span>
                <NavLink to="/exames" className="button blue-dark">Visualizar</NavLink>
                <NavLink to="/exame" className="button">Cadastrar</NavLink>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body card-grid">
              <div>
                <svg height="80" viewBox="0 0 512 512" width="80" xmlns="http://www.w3.org/2000/svg">
                  <path d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0" fill="#ffb400" />
                </svg>
              </div>
              <div>
                <h2 className={classes.title}>Avaliações</h2>
                <Rating
                  classes={{
                    root: classes.root,
                    disabled: classes.disabled,
                    sizeLarge: classes.large,

                  }}
                  name="half-rating"
                  precision={0.1}
                  value={notaMedia}
                  size="large"
                  readOnly
                />
                <Box className={classes.root_guide} ml={0}>Nota média da clínica: {notaMedia} <br></br> Total de avaliações: {totalAvaliacoes}</Box>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default HomeClinica;
