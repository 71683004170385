import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Swal from 'sweetalert2';

// SVG Icons
import searchIcon from "../../assets/images/search-icon.svg";

// Styles
import './styles.scss';

interface ISearchField {
  searchField: boolean;
  url:string;
  redirect: string;
  placeholderSearchField:string;
  children?: JSX.Element;
}

function TopPageSearch( props: ISearchField) {
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  async function handleSearch() {

    try {
      const userTokens = await Auth.currentSession();
      const { data } = await api.get(props.url + searchValue , {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }

      });

      if(!data){
        throw new Error ("Nenhum resultado encontrado!")
      }
      history.push(props.redirect, {
        data
      })

    } catch(erro) {
      Swal.fire({
        icon: "warning",
        title: erro as string,
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }

  return (
    <header className='top-page'>
      <div className='search-field' style={{ visibility: props.searchField ? "visible" : "hidden" }}>
        <img src={searchIcon} onClick={handleSearch} />
        <input
          className='field'
          placeholder={props.placeholderSearchField}
          value={searchValue}
          onChange={(e) => (setSearchValue(e.target.value))}
          onKeyPress={(e) => { if (e.key === "Enter") handleSearch() }}
        />
      </div>
      {props.children}
    </header>
  );
}

export { TopPageSearch }
