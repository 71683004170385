import React, { useState, useEffect, FormEvent } from 'react';
import { Auth } from 'aws-amplify';

import Swal from 'sweetalert2';

import api from '../../services/api';

import './styles.scss';

import compartilhamento from '../../assets/images/banner-clinica.png';

import LogoClipo from '../../assets/images/logo.svg';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import { useHistory } from 'react-router-dom';
import { UIStore } from '../../UIStore';

interface ExamesDTO {
  id: string,
}

function AcessoCompartilhado() {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [codeExist, setCodeExist] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const signOut = async () => {
      await Auth.signOut();
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
    }

    signOut();
  }, [])

  async function handleSearchExame(e: FormEvent) {
    e.preventDefault();

    try {
      await api.get(`/exame/acesso/${code}`);
      setCodeExist(true);
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Código inválido, tente novamente.',
        icon: 'error',
      });
    }
  }

  async function handleListExame(e: FormEvent) {
    e.preventDefault();

    try {
      const response = await api.post(`/exame/acesso/${code}/`, {
        senha: password
      })
      history.push({
        pathname: "/compartilhado/exames",
        state: {
          exames: response.data.exames,
          codigo: response.data.codigo,
          senha: response.data.senha,
          expirarEm: response.data.expirarEm
        }
      });

    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Senha incorreta, tente novamente.',
        icon: 'error',
      });
    }
  }

  const handleView = async (id: string, arquivo: string) => {
    const ext = arquivo.substr(arquivo.length - 3);

    let typeFile = ext == 'pdf' ? 'application' : 'image';

    const userTokens = await Auth.currentSession();

    await api.post(`/exame/acesso/${code}/f94b6e8a-9f6a-4d0b-8d06-124f5417f8ed`, {
      senha: password,
      responseType: 'blob'
    })
      .then(response => {
        console.log(typeFile + '/' + ext);
        const data = response.data;
        const file = new Blob(
          [data],
          { type: typeFile + '/' + ext });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      <div id="examesCompartilhado">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={compartilhamento} alt="Grafismo" />
          </div>
          <div className="background-blue-light v-height">
            <div className="form">
              <img src={LogoTextoClipo}></img>
              <form action="" className="materializeForm" onSubmit={handleSearchExame}>
                <input
                  type="text"
                  placeholder="Código do Exame"
                  autoFocus={autoFocus}
                  required
                  name="code"
                  value={code}
                  onChange={(e) => { setCode(e.target.value) }}
                />
                <div className={codeExist ? 'visible' : 'hidden'}>
                  <input
                    type="text"
                    placeholder="Senha"
                    name="password"
                    required={codeExist ? true : false}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                  />
                </div>
                <div className={codeExist ? 'hidden' : 'visible'}>
                  <button>VERIFICAR</button>
                </div>
                <div className={codeExist ? 'visible' : 'hidden'}>
                  <a className="button" onClick={handleListExame}>ACESSAR</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcessoCompartilhado;
