import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import GoogleIcon from '../../assets/images/pesquisa.svg';
import FacebookIcon from '../../assets/images/facebook.svg';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: "20%",
      marginRight: "10px",
      ['@media (max-width:500px)']: {
        width: "18%",
      }
    },
    socialLogin: {
      display: "flex",
    }
  })
);

export default function SocialLogin() {
  const classes = useStyles();

  const federatedSignIn = async (provider: CognitoHostedUIIdentityProvider) => {
    try {
      await Auth.federatedSignIn({ provider });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Atenção!',
        text: "Ocorreu um erro ao tentar executar a ação solicitada!"
      })
    }
  }

  return (
    <div className={classes.socialLogin}>
      <Button
        variant="contained"
        classes={{
          root: "buttonFacebook"
        }}
        size="large"
        type="button"
        onClick={() => {
          federatedSignIn(CognitoHostedUIIdentityProvider.Facebook);
        }}
      >
        <div className='faceDiv'> <img src={FacebookIcon} className={classes.icon} />
          FACEBOOK</div>
      </Button>
      <Button
        classes={{
          root: "buttonGoogle"
        }}
        variant="contained"
        size="large"
        type="button"
        onClick={() => {
          federatedSignIn(CognitoHostedUIIdentityProvider.Google);
        }}
      >
        <div className='GoogleDiv'>
        <img src={GoogleIcon} className={classes.icon} />

          Google
        </div>
      </Button>
    </div>
  )
}
