import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
// import {useAuth} from '../contexts/auth';

import AdminRoutes from './admin.routes';
import ClinicaRoutes from './clinica.routes';
import PacienteRoutes from './paciente.routes';

export default function Routes({ frontend, useBasename }: { frontend: string, useBasename: boolean }) {
  switch (frontend) {
    case 'admin':
      return <AdminRoutes useBasename={useBasename} />;
    case 'clinica':
      return <ClinicaRoutes useBasename={useBasename} />;
    case 'paciente':
    default:
      return <PacienteRoutes useBasename={useBasename} />;
  }
};
