/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import iconDependents from '../assets/images/dependents-icon.svg';
import iconDocuments from '../assets/images/document-icon.svg';
import iconHome from '../assets/images/home-icon.svg';
import iconLogout from '../assets/images/logout-icon.svg';
import iconPerfil from '../assets/images/perfil-icon.svg';
import iconPets from '../assets/images/pets-icon.svg';
import iconWhatsapp from '../assets/images/whatsapp-icon.svg';
import PrivateRoute from '../components/PrivateRoute';
import SideMenu from '../components/SideMenuPaciente';
import { Welcome } from '../components/Welcome';
import AcessoCompartilhado from '../pages/AcessoCompartilhado';
import Agradecimento from '../pages/Agradecimento';
import Assinaturas from '../pages/Assinaturas';
import BuscaClinica from '../pages/BuscaClinica';
import CadastroPaciente from '../pages/CadastroPaciente';
import ConfirmaPaciente from '../pages/ConfirmaPaciente';
import Dependente from '../pages/Dependente';
import Dependentes from '../pages/Dependentes';
import EsqueciPaciente from '../pages/EsqueciPaciente';
import ExamePaciente from '../pages/ExamePaciente';
import ExamePet from '../pages/ExamePet';
import ExamesCompartilhado from '../pages/ExamesCompartilhado';
import ExamesPaciente from '../pages/ExamesPaciente';
import ExamesPet from '../pages/ExamesPet';
import HomePaciente from '../pages/HomePaciente';
import IndicarClinica from '../pages/IndicarClinica';
import IndicarUsuario from '../pages/IndicarUsuarios';
import LoginPaciente from '../pages/LoginPaciente';
import NovoUsuarioSocial from '../pages/NovoUsuarioSocial';
import Profile from '../pages/PerfilPaciente';
import Pet from '../pages/Pet';
import Pets from '../pages/Pets';
import RedefinirPaciente from '../pages/RedefinirPaciente';
import { UIStore } from '../UIStore';



function PacienteRoutes({ useBasename }: { useBasename: boolean }) {
  const signed = UIStore.useState(s => s.signed);
  const nivelUser = UIStore.useState(s => s.nivelUser);
  const username = UIStore.useState(s => s.username);
  const name = UIStore.useState(s => s.name);
  const [device, setDevice] = useState('desktop');

  useEffect(() => {
    if (isMobile) {
      setDevice('mobile');
    }
  }, []);

  return (
    <BrowserRouter basename={useBasename ? '/paciente/' : undefined}>
      <>
        <Welcome
          title={
            <h3>
              Novos recursos,<br></br>cara nova!
            </h3>
          }
          description="Nossa interface foi totalmente reformulada para trazer a melhor experiência para você."
          disableOption={true}
        />
        <div className="app-container">
          {signed && nivelUser == 'paciente' ? (
            <>
              <SideMenu
                device={device}
                options={[
                  {
                    id: 'home',
                    title: 'Home',
                    link: '/home',
                    icon: <img src={iconHome}></img>,
                  },
                  {
                    id: 'exames',
                    title: 'Documentos',
                    link: '/exames',
                    icon: <img src={iconDocuments}></img>,
                  },
                  {
                    id: 'dependentes',
                    title: 'Dependentes',
                    link: '/dependentes',
                    icon: <img src={iconDependents}></img>,
                  },
                  {
                    id: 'pets',
                    title: 'Pets',
                    link: '/pets',
                    icon: <img src={iconPets}></img>,
                  },
                  {
                    title: 'Perfil',
                    link: '/perfil',
                    icon: <img src={iconPerfil}></img>,
                  },
                  {
                    title: 'Ajuda',
                    link: '#',
                    icon: <img src={iconWhatsapp}></img>,
                  },
                  {
                    title: 'Sair',
                    link: 'logout',
                    icon: <img src={iconLogout}></img>,
                  },
                ]}
              />
              <PrivateRoute
                timeToExtend={24}
                exact={true}
                path={["/", "/home"]}
              >
                <HomePaciente />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/perfil">
                <Profile />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/exames">
                <ExamesPaciente />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/exame">
                <ExamePaciente />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={true} path="/pets">
                <Pets />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={true} path="/pet/:id?">
                <Pet />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={true} path="/pets/exames/">
                <ExamesPet />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={true} path="/pets/exame/">
                <ExamePet />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={24}
                exact={true}
                path="/pets/exame/:id"
              >
                <ExamePet />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/dependentes">
                <Dependentes />
              </PrivateRoute>
              <PrivateRoute
                timeToExtend={24}
                exact={false}
                path="/dependente/:id?"
              >
                <Dependente />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/clinicas">
                <BuscaClinica />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/clinica">
                <IndicarClinica />
              </PrivateRoute>
              <PrivateRoute timeToExtend={24} exact={false} path="/usuarios">
                <IndicarUsuario />
              </PrivateRoute>
              <Route
                path="/compartilhado/acesso"
                component={AcessoCompartilhado}
              />
              <Route
                path="/compartilhado/exames"
                component={ExamesCompartilhado}
              />
            </>
          ) : (
            <Switch>
              <Route path="/" exact component={LoginPaciente} />
              <Route path="/login" exact component={LoginPaciente} />
              <Route path="/cadastro" component={CadastroPaciente} />
              <Route path="/confirma" component={ConfirmaPaciente} />
              <Route path="/esqueci" component={EsqueciPaciente} />
              <Route path="/redefinir" component={RedefinirPaciente} />
              <Route
                path="/compartilhado/acesso"
                component={AcessoCompartilhado}
              />
              <Route
                path="/compartilhado/exames"
                component={ExamesCompartilhado}
              />
              <Route path="/assinaturas" component={Assinaturas} />
              <Route path="/recadastrar" component={NovoUsuarioSocial} />
              <Route path="/agradecimento" component={Agradecimento} />
            </Switch>
          )}
        </div>
      </>
    </BrowserRouter>
  );
}

export default PacienteRoutes;
