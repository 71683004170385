import Swal from 'sweetalert2';
import api from '..';

type OutCheckAccount = {
  status: string;
  sentBy: string;
};

export const checkAccount = async (email: string) => {
  try {
    return await api.get<OutCheckAccount>(`/usuario/confirma/${email}`);
  } catch (error) {
    const response = error.response.data;

    if (response.status) {
      const intermediary =
        response.sentBy === 'phone' ? 'número de telefone' : 'email';

      Swal.fire({
        title: 'Usuário não foi confirmado',
        text: `Para a sua segurança, confirme sua conta com o código que enviamos para o seu ${intermediary} na página a seguir.`,
        icon: 'error',
      });

      throw new Error('Usuário não foi confirmado');
    } else {
      return;
    }
  }
};
