import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

import api from '../../services/api';
import SearchExames from '../../templates/SearchExames';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }),
);

interface ParamTypes {
  id: string;
}

function ExamePet() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const uploadModalRef = useRef<HTMLDivElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [validFiles, setValidFiles] = useState<any[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [hover, setHover] = useState('');
  const [petId, setPetId] = useState('');
  const [pets, setPets] = useState([]);
  const [petState, setPetState] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const handleChangePet = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPetId(event.target.value as string);
  }

  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();

  if (!id) {
    setId(uuidv4());
    if (params.id) {
      setPetId(params.id);
      setPetState(true);
    }
  }

  useEffect(() => {
    const getPets = async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get('/pet', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      setPets(response.data);
    };

    getPets();

    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item: { name: any; }) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
  }, [selectedFiles]);

  const preventDefault = (e: FormEvent) => {
    e.preventDefault();
  }

  const dragOver = (e: any) => {
    preventDefault(e);
    setHover('hover');
  }

  const dragEnter = (e: any) => {
    preventDefault(e);
  }

  const dragLeave = (e: any) => {
    preventDefault(e);
    setHover('');
  }

  const validateFile = (file: any) => {
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];

    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  }

  const handleFiles = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles([...selectedFiles, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles(prevArray => [...prevArray, files[i]]);
        setErrorMessage('Formato inválido!');
        setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
      }
    }
  }

  const fileDrop = (e: any) => {
    setHover('');
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      if (fileInputRef.current.files.length) {
        handleFiles(fileInputRef.current.files);
      }
    }
  }

  const fileInputClicked = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  }

  const fileSize = (size: any) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const fileType = (fileName: any) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  }

  const removeFile = (name: any) => {
    const index = validFiles.findIndex(e => e.name === name);
    const index2 = selectedFiles.findIndex(e => e.name === name);
    const index3 = unsupportedFiles.findIndex(e => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  }

  const openImageModal = (file: File) => {
    const reader = new FileReader();
    if (modalRef.current) modalRef.current.style.display = "none";
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      if (modalImageRef.current) modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    }
  }

  const closeModal = () => {
    if (modalRef.current) modalRef.current.style.display = "none";
    if (modalImageRef.current) modalImageRef.current.style.backgroundImage = 'none';
  }

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader && reader.result) resolve(reader.result.toString().replace(/^data:.+;base64,/, ''));
    }
    reader.onerror = error => reject(error);
  });

  const uploadFiles = async () => {
    if (!petId) {
      Swal.fire({
        title: 'Por favor, selecionar um pet!',
        icon: 'warning',
      });
      return;
    }

    if (uploadModalRef.current) uploadModalRef.current.style.display = 'block';
    if (uploadRef.current) uploadRef.current.innerHTML = 'Subindo exame...';
    for (let i = 0; i < validFiles.length; i++) {
      const nomeExame = 'Exame';
      const ativo = true;

      try {
        const userTokens = await Auth.currentSession();
        const response = await api.get(`/pet/${petId}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });
        const pet = {
          id: response.data.id,
          responsavel: response.data.responsavel,
        };

        await api.put('examepet', {
          id,
          descricao: nomeExame,
          pet,
          arquivoBinario: {
            fileName: validFiles[i].name,
            content: await toBase64(validFiles[i])
          },
          ativo,
        }, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          },
          onUploadProgress: (progressEvent: any) => {
            const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
            if (progressRef.current) {
              progressRef.current.innerHTML = `${uploadPercentage}%`;
              progressRef.current.style.width = `${uploadPercentage}%`;
            }

            if (uploadPercentage === 100) {
              if (uploadRef.current) {
                uploadRef.current.innerHTML = 'Exame cadastrado com sucesso!';

                Swal.fire({
                  title: 'Exame cadastrado com sucesso!',
                  icon: 'success',
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonText: 'Continuar',
                  cancelButtonColor: '#418107',
                  cancelButtonText: 'Ver Todos'
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload(false);
                  } else {
                    history.push({
                      pathname: '/pets/exames',
                      state: petId
                    });
                  }
                });
              }
              validFiles.length = 0;
              setValidFiles([...validFiles]);
              setSelectedFiles([...validFiles]);
              setUnsupportedFiles([...validFiles]);
            }
          },
        }).catch(() => {
          if (uploadRef.current) {
            uploadRef.current.innerHTML = `<span class="error">Erro ao subir exame!</span>`;
            Swal.fire({
              title: 'Erro ao subir exame!',
              icon: 'error',
            });
          }
          if (progressRef.current) progressRef.current.style.backgroundColor = 'red';
        })

      } catch (error) {
        console.log(error);
      }
    }
  }

  const closeUploadModal = () => {
    if (uploadModalRef.current) uploadModalRef.current.style.display = 'none';
  }

  return (
    <main className="main-paciente exame-pet">
      <SearchExames searchField={false} />
      <div className="upload-files">
        <div>
          <h1>Upload Exame</h1>
          {!petState ?
            <div className={classes.grid}>
              <FormControl className="cpf" variant="standard">
                <InputLabel id="demo-simple-select-outlined-label">Selecione o Pet</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={petId}
                  onChange={handleChangePet}
                  label="PET"
                >
                  {
                    pets.map((row: any, index) => {
                      return (
                        <MenuItem key={row.id} value={row.id}>{row.nome + ' - ' + row.raca}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
            :
            ""
          }
          <div className="container">
            {unsupportedFiles.length === 0 && validFiles.length ? <button className="file-upload-btn" onClick={() => uploadFiles()}>Subir Exame</button> : ''}
            {unsupportedFiles.length ? <p>Por favor remova os documentos em formato inválido.</p> : ''}
            <div className="drop-container" id={hover}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              onClick={fileInputClicked}
            >
              <div className="drop-message">
                <div className="upload-icon"><PublishIcon /></div>
                Arraste ou clique aqui para enviar documentos (PDF, PNG ou JPG)
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                onChange={filesSelected}
              />
            </div>
            <div className="file-display-container">
              {
                validFiles.map((data, i) =>
                  <div className="file-status-bar" key={i}>
                    <div onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}>
                      <div className="file-type-logo"></div>
                      <div className="file-type">{fileType(data.name)}</div>
                      <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                      <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                    </div>
                    <div className="file-remove" >
                      <Button variant="outlined" color="secondary" onClick={() => removeFile(data.name)}>X</Button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <div className="modal" ref={modalRef}>
          <div className="overlay"></div>
          <span className="close" onClick={(() => closeModal())}>X</span>
          <div className="modal-image" ref={modalImageRef}></div>
        </div>
        <div className="upload-modal" ref={uploadModalRef}>
          <div className="overlay"></div>
          <div className="progress-container">
            <div className="close" onClick={(() => closeUploadModal())}>X</div>
            <span ref={uploadRef}></span>
            <div className="progress">
              <div className="progress-bar" ref={progressRef}></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ExamePet;
