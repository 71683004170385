import React, { FormEvent, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import { Button, FormControl, FormGroup, Input, InputLabel, TextField } from '@material-ui/core';
import api from '../../services/api';
import { Auth } from 'aws-amplify';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Swal from 'sweetalert2';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CancelIcon from '@material-ui/icons/Cancel';
interface Data {
  protocolo: string;
  dataCadastro: Date;
  clinica: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: Order) {
  if (a[orderBy] === b[orderBy]) {
    return 0;
  } else if (a[orderBy] === null) {
    return 1;
  } else if (b[orderBy] === null) {
    return -1;
  } else if (order === 'asc') {
    return a[orderBy] < b[orderBy] ? -1 : 1
  } else if (order === 'desc') {
    return a[orderBy] < b[orderBy] ? 1 : -1
  } else {
    return 0;
  }
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return (a, b) => descendingComparator(a, b, orderBy, order)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
  { id: 'dataCadastro', numeric: false, disablePadding: true, label: 'Data de cadastro' },
  { id: 'clinica', numeric: false, disablePadding: true, label: 'Clinica' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Protocolos dos exames
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitSpacing: {
      marginTop: "0px",
      paddingTop: "0px",
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    filtrosForm: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridGap: '10px',
      padding: '5px 20px 25px 20px',
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '@media screen and (max-width: 991px)': {
        gridGap: '0px',
        gridTemplateColumns: '1fr',
      },
      '& > div > *': {
        display: 'block!important',
      },
      '& > div > label': {
        marginTop: '0!important',
      }
    },
    container: {
      marginBottom: "10px",
      marginTop: "10px",
      paddingTop: "10px",
      backgroundColor: "#FFF",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
    divClearButton: {
      '@media screen and (min-width: 1151px)': {
        gridColumnStart: 4,
        gridColumnEnd: 5
      },
      '@media screen and (min-width: 991px) and (max-width: 1150px)': {
        gridColumnStart: 3,
        gridColumnEnd: 4
      },
    },
    divFilterButton: {
      '@media screen and (min-width: 1151px)': {
        gridColumnStart: 5,
        gridColumnEnd: 6
      },
      '@media screen and (min-width: 991px) and (max-width: 1150px)': {
        gridColumnStart: 4,
        gridColumnEnd: 5
      },
    },
    filterButton: {
      width: "100%",
      margin: "theme.spacing(1)"
    },
    clean: {
      background: "#ff0000",
      color: "#fff",
      width: "100%"
    },
    label: {
      color: "#000000"
    },
    date: {
      width: "100%"
    }
  }),
);

interface ClinicaDTO {
  id: string;
  cnpj: string;
  nomeFantasia: string;
}

interface ISearch {
  [name: string]: string | MaterialUiPickersDate | boolean | string | null
}

export default function Protocolos() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('protocolo');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [fetchParams, setFetchParams] = React.useState<ISearch>({})
  const [clinicas, setClinicas] = React.useState<ClinicaDTO[]>([]);
  const [clinicaSearch, setClinicaSearch] = React.useState<null | ClinicaDTO>(null);
  const [exameParticularSearch, setExameParticularSearch] = React.useState(false);
  const [exameClinicaSearch, setExameClinicaSearch] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const getData = async () => {
      const userTokens = await Auth.currentSession();

      const exames = await api.get('/exame', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      setRows(exames.data);

      const response = await api.get('/clinica');
      setClinicas(response.data);
    };

    getData();
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleClear = async () => {
    const userTokens = await Auth.currentSession();

    setFetchParams({
      entregues: false,
      naoEntregues: false
    })
    setExameClinicaSearch(false);
    setExameParticularSearch(false);

    const response = await api.get(`/exame`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      }
    });

    setRows(response.data);
    setClinicaSearch(null);
  }

  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();
    const userTokens = await Auth.currentSession();

    const params = new URLSearchParams();

    Object.keys(fetchParams).forEach((key) => {
      params.append(key, fetchParams[key] as string);
    });

    const response = await api.get(`/exame?${params.toString()}`, {
      headers: {
        'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
      }
    });

    setRows(response.data);
    setPage(0);
  }

  return (
    <main className={classes.fitSpacing}>
      <div className={classes.root}>
        <div className={classes.container}>
          <form className={classes.filtrosForm} onSubmit={handleSearch}>
            <FormControl variant="outlined">
              <TextField
                name="protocoloSearch"
                id="protocoloSearch"
                label="Digite o nº do protocolo"
                variant="outlined"
                value={fetchParams['protocolo'] !== undefined ? fetchParams['protocolo'] as string : ''}
                onChange={(e) => {
                  setFetchParams({
                    ...fetchParams,
                    protocolo: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl variant="outlined">
              <Autocomplete
                options={clinicas}
                getOptionLabel={(option) => option.nomeFantasia}
                value={clinicaSearch}
                onChange={(event, value) => {
                  setFetchParams({
                    ...fetchParams,
                    clinica: value !== null ? (value.id !== undefined ? value.id : '') : ''
                  });
                  setClinicaSearch(value);
                }}
                renderInput={(params) => <TextField {...params} label="Selecione a clínica" variant="outlined" />}
              />
            </FormControl>
            <FormControl>
              <MuiPickersUtilsProvider locale={dateFnsPtBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.date}
                  disableFuture
                  inputVariant="outlined"
                  openTo="year"
                  format="dd/MM/yyyy"
                  label="DE"
                  views={["year", "month", "date"]}
                  value={fetchParams['dataCadastroDe'] !== undefined ? fetchParams['dataCadastroDe'] as MaterialUiPickersDate : null}
                  onChange={(value) => {
                    setFetchParams({
                      ...fetchParams,
                      dataCadastroDe: value
                    });
                  }}
                  invalidDateMessage={"Inserir uma data válida"}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl>
              <MuiPickersUtilsProvider locale={dateFnsPtBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.date}
                  disableFuture
                  inputVariant="outlined"
                  openTo="year"
                  format="dd/MM/yyyy"
                  label="ATÉ"
                  views={["year", "month", "date"]}
                  value={fetchParams['dataCadastroAte'] !== undefined ? fetchParams['dataCadastroAte'] as MaterialUiPickersDate : null}
                  onChange={(value) => {
                    setFetchParams({
                      ...fetchParams,
                      dataCadastroAte: value
                    });
                  }}
                  invalidDateMessage={"Inserir uma data válida"}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  checked={exameParticularSearch}
                  control={<Checkbox color="primary" />}
                  label="Particulares"
                  labelPlacement="end"
                  classes={{
                    root: classes.label
                  }}
                  onChange={() => {
                    setFetchParams({
                      ...fetchParams,
                      exameParticular: !exameParticularSearch
                    });
                    setExameParticularSearch(!exameParticularSearch)
                  }}
                />
                <FormControlLabel
                  checked={exameClinicaSearch}
                  control={<Checkbox color="primary" />}
                  label="Clínicas"
                  labelPlacement="end"
                  classes={{
                    root: classes.label
                  }}
                  onChange={() => {
                    setFetchParams({
                      ...fetchParams,
                      exameClinica: !exameClinicaSearch
                    });
                    setExameClinicaSearch(!exameClinicaSearch)
                  }}
                />
              </FormGroup>
            </FormControl>
            <FormControl
              className={classes.divClearButton}
            >
              <Button
                className={classes.clean}
                variant="contained"
                size="small"
                startIcon={<CancelIcon />}
                onClick={handleClear}
              >Limpar pesquisa</Button>
            </FormControl>
            <FormControl
              className={classes.divFilterButton}
            >
              <Button
                className={classes.filterButton}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SearchIcon />}
                type="submit"
              >Pesquisar</Button>
            </FormControl>
          </form>
        </div>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.protocolo !== null ? row.protocolo : (row.clinica !== null ? 'Exame não entregue' : 'Exame particular')}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {format(new Date(row.dataCadastro), 'dd/MM/yyyy HH:mm:ss', { locale: dateFnsPtBR })}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.clinica !== null ? row.clinica.razaoSocial : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows, display: 'none' }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main>
  );
}
