import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import './styles.scss';

import grafismo from '../../assets/images/grafismo-user.png';

import LogoClipo from '../../assets/images/logo.svg';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';

const translate = (term: string) => {
  const terms: { [key: string]: string } = {
    'Attempt limit exceeded, please try after some time.': 'Limite de tentativas excedido, por favor tente novamente mais tarde.',
    'Invalid verification code provided, please try again.': 'O código de verificação é inválido, tente novamente.'
  };
  if (Object.keys(terms).includes(term)) {
    return terms[term];
  }
  return term;
}

function RedefinirPaciente() {
  const [username, setUsername] = useState('');
  const [new_password, setPassword] = useState('');
  const [code, setCode] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const emailPaciente = localStorage.getItem('emailPaciente');
    if (emailPaciente) {
      setUsername(emailPaciente);
    }
  }, []);

  const checkPasswordLength = (password: string) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  }

  const checkSpecialCharacters = (password: string) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  }

  const checkUppercase = (password: string) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  }

  const checkLowercase = (password: string) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }
  }

  const checkNumber = (password: string) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkLowercase(event.target.value);
    checkNumber(event.target.value);
  };

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  async function signUp(e: FormEvent) {
    e.preventDefault();

    try {
      Auth.forgotPasswordSubmit(username, code, new_password)
        .then(data => {
          history.push('/');
        })
        .catch(err => {
          Swal.fire({
            title: 'Erro!',
            text: translate(err.message),
            icon: 'error',
          });
        });
    } catch (error) {
    }
  }

  const sendCode = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await Auth.forgotPassword(username)
      localStorage.setItem('emailPaciente', username);
      Swal.fire({
        title: 'Sucesso!',
        text: `Se o seu e-mail está cadastrado em nossa base, você receberá um código para realizar a ativação da sua conta. Enviado para: <b>${response.CodeDeliveryDetails.Destination}</b>`,
        icon: 'success',
      }).then(() => {
        history.push('/redefinir');
      });
    } catch (err) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao enviar o código, por favor verifique o email informado!',
        icon: 'error',
      });
    }
  }

  return (
    <>
      <div id="redefinirPaciente">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} alt="Grafismo" />
          </div>
          <div className="background-blue-light v-height">
            <div className="form">
              <img src={LogoTextoClipo}></img>
              <form action="" className="materializeForm" onSubmit={signUp}>
                <input
                  type="text"
                  placeholder="Nome do Usuário"
                  name="username"
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                />
                <input
                  type="text"
                  placeholder="Código de Verificação"
                  autoFocus
                  required
                  name="code"
                  value={code}
                  onChange={(e) => { setCode(e.target.value) }}
                />
                <div className="container-pass">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Senha"
                    name="password"
                    value={new_password}
                    autoComplete="off"
                    onChange={(e) => { handlePasswordChange(e) }}
                    required
                  />
                  <a onClick={(e) => { handlePassword() }}>{passwordIcon}</a>
                </div>
                <div className="validation">
                  <div className="validator">
                    {charNumberValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">Mínimo 8 caracteres</p>
                  </div>
                  <div className="validator">
                    {specialCharValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 caracter especial</p>
                  </div>
                  <div className="validator">
                    {uppercaseValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 letra maiúscula</p>
                  </div>
                  <div className="validator">
                    {lowercaseValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 letra minúscula</p>
                  </div>
                  <div className="validator">
                    {numberValid ? <CheckIcon className="success" /> : <CloseIcon />}
                    <p className="validation-item">1 número</p>
                  </div>
                </div>

                <a onClick={(e) => { sendCode(e) }}>Reenviar o código</a>
                <button>ALTERAR</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RedefinirPaciente;
