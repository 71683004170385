import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import Swal from 'sweetalert2';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Button } from '@material-ui/core';
import DataList from '../../components/DataList';
import filePngIcon from '../../assets/images/png_file_symbol.png';
import filePdfIcon from '../../assets/images/pdf_file_symbol.png';
import fileJpgIcon from '../../assets/images/jpg_file_symbol.png';
import ModalExam from '../../components/ModalExam';
import { ExameDTO, ExamesCompartilhadoDTO } from '../../dtos/exameDto';
import api from '../../services/api';
import './styles.scss';

const ExamesCompartilhado = () => {
  const [rows, setRows] = useState<ExameDTO[]>([]);
  const [id, setId] = useState('');
  const [archive, setArchive] = useState('');
  const [fileBody, setFileBody] = useState('');
  const [fileSrc, setFileSrc] = useState('');
  const [openImgModal, setOpenImgModal] = useState<boolean>(false);
  const { state } = useLocation<ExamesCompartilhadoDTO>();
  const history = useHistory();

  const handleOpenModalExam = async (id: string, archive?: string) => {
    if (!archive) return;

    setOpenImgModal(true);
    setId(id);
    setArchive(archive);
  };

  const handleCloseImgModal = () => {
    setOpenImgModal(false);
    setId('');
    setArchive('');
    setFileBody('');
    setFileSrc('');
  };

  const handleDownload = async (id: string, archive: string) => {
    const ext = archive.substr(archive.length - 3);

    let typeFile = ext == 'pdf' ? 'application' : 'image';

    const url = `exame/acesso/${state.codigo}/${id}`;

    await api
      .post(url, { senha: state.senha }, { responseType: 'blob' })
      .then(response => {
        const data = response.data;
        const file = new Blob([data], { type: typeFile + '/' + ext });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${archive}`);

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleExit = () => {
    window.location.replace('https://www.cliposaude.com.br/');
  };

  const handleDataListRowProps = (row: ExameDTO) => {
    const ext = row.arquivo.split('.').pop();
    const avatar =
      ext === 'pdf' ? filePdfIcon : ext === 'png' ? filePngIcon : fileJpgIcon;

    return {
      avatar: <img style={{ height: '50px' }} src={avatar} alt="avatar" />,
      title: row.dadosPessoaExame?.nome,
      subTitle: format(new Date(row.dataCadastro), 'dd/MM/yyyy HH:mm:ss', {
        locale: dateFnsPtBR,
      }),
      onClick: handleOpenModalExam,
      param: row.arquivo,
      actions: [
        {
          description: 'Visualizar documento',
          tooltip: 'VER',
          icon: <VisibilityIcon />,
          param: row.arquivo,
          handle: handleOpenModalExam,
        },
        {
          description: 'Baixar documento',
          tooltip: 'DOWNLOAD',
          icon: <DownloadIcon />,
          param: row.arquivo,
          handle: handleDownload,
        },
      ],
    };
  };

  useEffect(() => {
    (async () => {
      if (state) {
        const rows = state.exames;
        setRows(rows);
        const expiretionTime = new Date(state.expirarEm);
        const now = new Date();
        const timeLeft = expiretionTime.getTime() - now.getTime();

        setTimeout(() => {
          Swal.fire({
            title: 'Os exames compartilhados expiraram!',
            icon: 'error',
            showCloseButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
          }).then(result => {
            if (result.isConfirmed) {
              window.location.replace('https://www.cliposaude.com.br/');
            }
          });
        }, timeLeft);
      } else {
        setRows([]);
        history.push('/compartilhado/acesso');
      }
    })();
  }, []);

  return (
    <div className="exame-compartilhado">
      <DataList
        handleRowProps={handleDataListRowProps}
        avatarBackground={false}
        checkbox={false}
        orderBy="nome"
        orderByTitle="Nome"
        rows={rows}
        pageName="Exames"
        headActions={[]}
      />
      <ModalExam
        src={fileSrc}
        user={false}
        url={`/exame/acesso/${state.codigo}/:id`}
        open={openImgModal}
        id={id}
        arquivo={archive}
        handleClose={handleCloseImgModal}
        body={fileBody}
        senha={state.senha}
      />
      <Button
        variant="contained"
        type="button"
        onClick={handleExit}
        className="exit-button"
      >
        <ExitToAppIcon />
        SAIR
      </Button>
    </div>
  );
};

export default ExamesCompartilhado;
