import { formatISO } from "date-fns"

export const maskCPF = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskPhoneNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskName = (value: string) => {
  return value
    .replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, "")
}

export const maskDate = (value: Date) => {
  const date = value.toJSON().slice(0, 10);
  return date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
}

export const maskDateToTimestamp = (value: string) => {
  const dateSplit = value.split('/');
  const dayDe = Number(dateSplit[0]);
  const monthDe = Number(dateSplit[1]) - 1;
  const yearDe = Number(dateSplit[2]);
  return value ? formatISO(new Date(yearDe, monthDe, dayDe, 0, 0, 0)) : '';
}
