const convertTo64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    if (reader && reader.result) resolve(reader.result.toString().replace(/^data:.+;base64,/, ''));
  }
  reader.onerror = error => reject(error);
});

export {convertTo64}

