import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel, FormControl } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../services/api';
import axios from 'axios';

import './styles.scss';

import brazil from '../../assets/images/brazil.svg';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    span: {
      padding: "15px",
      color: "#FFFFFF",
      backgroundColor: "#21ACFC",
      borderRadius: "10px",
      margin: "15px 8px 15px 8px",
      '@media screen and (max-width: 991px)': {
        margin: "15px 0 15px 0",
      }
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface Clinica {
  id: string;
  cnpj: string;
  responsavel: string;
}

interface ClinicaDTO {
  id: string;
}

interface TelefoneMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TelefoneMask(props: TelefoneMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '5', '5', ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface CepMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CepMask(props: CepMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function IndicarClinica() {
  const classes = useStyles();
  const history = useHistory();
  const [id, setId] = useState("");
  const location = useLocation<ClinicaDTO>();

  const [ativo, setAtivo] = useState(true);
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [email, setEmail] = useState('');
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState('');
  const [descricao, setDescricao] = useState('');
  const [visibility, setVisibility] = useState<VisibilityState>("hidden");
  const [telefoneComercial, setTelefoneComercial] = useState("");
  const [cep, setCep] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTelefoneComercial(event.target.value);
    if (event.target.name === 'telefoneComercial') {
      setVisibility("visible")
    }
  };

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      const getData = async () => {
        const response = await api.get('/clinica/' + location.state.id);
        setId(response.data.id);
        setNomeFantasia(response.data.nomeFantasia);
        setCep(response.data.cep);
        setEndereco(response.data.endereco);
        setBairro(response.data.bairro);
        setCidade(response.data.cidade);
        setEstado(response.data.estado);
        setEmail(response.data.email);
        setAtivo(response.data.ativo);
        setNumero(response.data.numero);
        setComplemento(response.data.complemento);
      };

      getData();
    }
  }, []);

  function buscaCep() {
    const numberPattern = /\d+/g;

    if (cep) {
      if (cep.match(/^[\d]{2}.[\d]{3}-[\d]{3}/) === null) {
        setEndereco("");
        setBairro("");
        setCidade("");
        setEstado("");

        Swal.fire({
          icon: "error",
          title: "Atenção!",
          text: "O CEP está em formato inválido"
        });
      }


      const cepNumber = cep.replace('.', '').replace('-', '');
      axios.get(`https://api.postmon.com.br/v1/cep/${cepNumber}`)
        .then(response => {
          console.log(response);
          if (response.status == 200) {
            setEndereco(response.data.logradouro);
            setBairro(response.data.bairro);
            setCidade(response.data.cidade);
            setEstado(response.data.estado);
          }
        })
        .catch(error => {
          console.log(error)
        });
    }
  }

  const handleCep = (value: string) => {
    if (value === "") {
      setCep(value);
      setEndereco("");
      setBairro("");
      setCidade("");
      setEstado("");
    } else {
      setCep(value);
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (cep.match(/^[\d]{2}.[\d]{3}-[\d]{3}/) === null) {
      Swal.fire({
        icon: "error",
        title: "Atenção!",
        text: "O CEP está em formato inválido"
      });
    }

    try {
      const result = async () => {
        const userTokens = await Auth.currentSession();

        return await api.put(`/clinica/indicar`, {
          nomeFantasia,
          cep,
          endereco,
          bairro,
          cidade,
          estado,
          email,
          telefoneComercial,
          ativo,
          numero,
          complemento,
        }, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          },
        });
      }

      await result();

      Swal.fire({
        title: "Indicação realizada com sucesso",
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/clinicas');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Indique uma clínica</h1>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="hidden"
                    name="id"
                    value={id}
                  />
                  <div className={classes.span}>
                    <span>Dados Gerais</span>
                  </div>
                  <div className={classes.grid4}>
                    <TextField
                      id="outlined-basic"
                      label="Nome da clínica"
                      variant="outlined"
                      name="nomeClinica"
                      value={nomeFantasia}
                      required
                      onChange={(e) => { setNomeFantasia(e.target.value) }}
                    />
                  </div>
                  <div className={classes.span}>
                    <span>Endereço</span>
                  </div>
                  <div className={classes.grid3}>
                    <TextField
                      id="outlined-basic"
                      label="CEP"
                      variant="outlined"
                      required
                      name="cep"
                      InputProps={{
                        inputComponent: CepMask as any,
                      }}
                      value={cep}
                      onChange={(e) => { handleCep(e.target.value) }}
                      onBlur={() => { buscaCep() }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Endereço"
                      disabled
                      variant="outlined"
                      required
                      name="endereco"
                      value={endereco}
                      onChange={(e) => { setEndereco(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Bairro"
                      disabled
                      variant="outlined"
                      required
                      name="bairro"
                      value={bairro}
                      onChange={(e) => { setBairro(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid4}>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Número"
                      variant="outlined"
                      name="numero"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={numero}
                      onChange={(e) => { setNumero(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Complemento"
                      variant="outlined"
                      name="complemento"
                      value={complemento}
                      onChange={(e) => { setComplemento(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      disabled
                      label="Cidade"
                      variant="outlined"
                      required
                      name="cidade"
                      value={cidade}
                      onChange={(e) => { setCidade(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Estado"
                      disabled
                      variant="outlined"
                      required
                      name="estado"
                      value={estado}
                      onChange={(e) => { setEstado(e.target.value) }}
                    />
                  </div>
                  <div className={classes.span}>
                    <span>Contato</span>
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      id="outlined-basic"
                      label="E-mail"
                      type="email"
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        label="Telefone Comercial"
                        variant="outlined"
                        name="telefoneComercial"
                        InputProps={{
                          inputComponent: TelefoneMask as any,
                        }}
                        value={telefoneComercial}
                        onChange={handleChange}
                      />
                      <img src={brazil} style={{ visibility: visibility }} className="flagPhone outlined" />
                    </FormControl>
                  </div>
                  <div className={classes.button}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={ativo}
                          onChange={(e) => { setAtivo(!ativo) }}
                          color="primary"
                          name="ativo"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Status"
                    />
                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/clinicas') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default IndicarClinica;
