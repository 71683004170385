import { Auth } from 'aws-amplify';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UIStore } from '../../UIStore';

const renewOrExpireSession = async (hours: number): Promise<boolean> => {
  const expirationTime = localStorage.getItem('expirationTime');

  if (new Date().getTime() >= Number(expirationTime)) {
    await Auth.signOut();
    localStorage.clear();
    UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
    window.location.replace('/');
    return false;
  } else {
    const now = new Date();
    const expirationTime = now.setHours(now.getHours() + hours);
    localStorage.setItem('expirationTime', String(expirationTime));
    return true;
  }
};
interface tokenProps {
  children: JSX.Element;
  path: string | Array<string>;
  exact?: boolean;
  timeToExtend: number;
}

const PrivateRoute = (props: tokenProps) => {
  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={({ location }) => {
        return renewOrExpireSession(props.timeToExtend) ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: '/paciente',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
