import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import moment from "moment-timezone";

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel, InputLabel, Select, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../services/api';
import axios from 'axios';

import './styles.scss';
import { Auth } from 'aws-amplify';
import { FormControl } from '@material-ui/core';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { maskPhoneNumber } from '../../utils/mask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface Paciente {
  id: string;
  cpf: string;
  responsavel: string;
}

interface ParamTypes {
  id: string;
}

interface Plano {
  id: string;
  nome: string;
  descricao: string;
}

interface CpfMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CpfMask(props: CpfMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface DataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function DataMask(props: DataMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface State {
  cpf: string;
}

function Paciente() {
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [usuario, setUsuario] = useState('');
  const [dataNasc, setDataNasc] = useState('');
  const [contatoPreferencial, setContatoPreferencial] = useState('');
  const [plano, setPlano] = useState("");
  const [values, setValues] = React.useState({
    cpf: '',
  });

  const [planos, setPlanos] = useState([]);

  const location = useLocation<State>()
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      const getData = async () => {
        const userTokens = await Auth.currentSession();
        const response = await api.get(`/usuario/cpf/${location.state.cpf}`, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });
        setId(response.data.id);
        setValues({
          ...values,
          cpf: response.data.cpf,
        });
        setNome(response.data.name);
        setSobrenome(response.data.family_name);
        setUsuario(response.data.email);
        setDataNasc(moment(new Date(response.data.birthdate)).format('L'));
        setPlano(response.data.plano_usuario?.id);
        setTelefone(maskPhoneNumber(response.data.phone_number.replace('+55', '')));
        setContatoPreferencial(response.data.contato_preferencial);

        const result = await api.get('/plano');
        setPlanos(result.data);
      };

      getData();
    }
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const userTokens = await Auth.currentSession();

    try {
      if (!nome) {
        Swal.fire({
          title: 'Digite o nome!',
          icon: 'warning',
        });
        return;
      }

      await api.put(`/usuario/${location.state.cpf}`, {
        name: nome,
        family_name: sobrenome,
        email: usuario,
        birthdate: new Date(dataNasc).toISOString(),
        plano_usuario: plano,
        phone_number: telefone !== '' ? "+55" + telefone.replace(/\D/g, '') : '',
        contato_preferencial: contatoPreferencial,
      }, {
        headers: {
          'X-Cognito-Access-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        } else {
          history.push('/usuario/pacientes');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error?.response.data.detail ?? 'Erro ao executar a solicitação - Por favor, tente novamente. Se persistir, entre em contato com o suporte.',
        icon: 'error',
      });
    }

  }

  const handleChangePlano = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPlano(event.target.value as string);
  }

  const handleChangeTelefone = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTelefone(maskPhoneNumber(event.target.value as string));
  }

  const handleChangeUsuario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUsuario(event.target.value as string);
  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Paciente</h1>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="hidden"
                    name="id"
                    value={id}
                  />
                  <div className={classes.grid3}>
                    <TextField
                      id="outlined-basic"
                      label="Nome Completo"
                      variant="outlined"
                      name="nome"
                      value={`${nome} ${sobrenome}`}
                      disabled

                    />
                    <TextField
                      id="outlined-basic"
                      label="Usuário"
                      variant="outlined"
                      name="sobrenome"
                      value={usuario}
                      onChange={handleChangeUsuario}
                    />
                  </div>
                  <div className={classes.grid3}>
                    <TextField
                      id="outlined-basic"
                      label="CPF"
                      variant="outlined"
                      name="cpf"
                      InputProps={{
                        inputComponent: CpfMask as any,
                      }}
                      value={values.cpf}
                      disabled
                    />
                    <TextField
                      id="outlined-basic"
                      label="Data de nascimento"
                      variant="outlined"
                      name="dataNasc"
                      value={dataNasc}
                      InputProps={{
                        inputComponent: DataMask as any,
                      }}
                      disabled
                    />
                  </div>
                  <div className={classes.grid3}>
                    <TextField
                      id="outlined-basic"
                      label="Telefone"
                      variant="outlined"
                      name="telefone"
                      value={telefone}
                      onChange={handleChangeTelefone}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Contato preferencial"
                      variant="outlined"
                      name="Contato preferencial"
                      value={contatoPreferencial}
                      disabled
                    />
                      
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="contact">Plano do usuário</InputLabel>
                      <Select
                        fullWidth
                        id="contact"
                        name="contact"
                        variant="outlined"
                        onChange={handleChangePlano}
                        value={plano}
                        label="Plano do usuário"
                        labelId="contact"
                      >
                        {
                          planos.map((plano: Plano) => {
                            return (
                              <MenuItem key={plano.id} value={plano.id}>
                                {plano.nome}
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/usuario/pacientes') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Paciente;
