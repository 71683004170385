import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import api from '../../services/api';
import './styles.scss';
import UploadFile from '../../components/UploadFile';
import SearchExames from '../../templates/SearchExames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface ExameDTO {
  exame: {
    id: string;
  }
  edit: boolean;
}

interface IDadosPessoaExame {
  dependente?: {
    id?: string
  },
  cliente?: string;
  cpf?: string;
}

function Exame() {
  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const uploadModalRef = useRef<HTMLDivElement>(null);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [dadosPessoaExame, setDadosPessoaExame] = useState<IDadosPessoaExame>({});
  const [nomePaciente, setNomePaciente] = useState('');
  const [cpfPaciente, setCpfPaciente] = useState('');
  const [cpf, setCpf] = useState('');
  const [dependentes, setDependentes] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [validFiles, setValidFiles] = useState<any[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<any[]>([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState('');
  const [type, setType] = useState('');
  const [bearer, setBearer] = useState("");

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<ExameDTO>();

  const handleChangeCpf = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setCpf(event.target.value as string);
    const CPF = event.target.value as string;
    const cpfNumber = CPF.replace(/\D/g, '');

    try {
      const userTokens = await Auth.currentSession();
      const dependenteExist = await api.get(`/dependente?cpf=${cpfNumber}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      const clienteExist = await api.get(`usuario/cpf/${cpfNumber}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      if (Object.keys(clienteExist.data).length > 0) {
        setDadosPessoaExame({
          cliente: clienteExist.data.id
        });
        setButtonLoading(false);
      } else if (dependenteExist.data.length > 0) {
        setDadosPessoaExame({
          dependente: {
            id: dependenteExist.data[0].id
          },
          cpf: cpfNumber
        });
        setButtonLoading(false);
      }

    } catch (error) {
      setButtonLoading(false);
    }
  }

  const handleNewTypeOfBearer = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  }

  const handleChangeBearer = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setBearer(event.target.value as string);
  }

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      setEdit(location.state.edit);
      setId(location.state.exame.id);
    }

    const getPaciente = async () => {
      const userTokens = await Auth.currentSession();
      const sub = userTokens.getIdToken().payload.sub;
      const user = await api.get(`/usuario/${sub}`, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });

      setNomePaciente(user.data.name + ' ' + user.data.family_name);
      setCpfPaciente(user.data.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
    };

    getPaciente();

    const getDependentes = async () => {
      const userTokens = await Auth.currentSession();

      const dependentes = await api.get('/dependente', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      if (dependentes.data.length > 0) {
        setDependentes(dependentes.data);
      } else {
        setDependentes([]);
      }
    };

    getDependentes();

    const getPets = async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get('/pet', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      if (response.data.length > 0) {
        setPets(response.data);
      } else {
        setPets([]);
      }
    };

    getPets();
  }, []);

  const closeModal = () => {
    if (modalRef.current) modalRef.current.style.display = "none";
    if (modalImageRef.current) modalImageRef.current.style.backgroundImage = 'none';
  }

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader && reader.result) resolve(reader.result.toString().replace(/^data:.+;base64,/, ''));
    }
    reader.onerror = error => reject(error);
  });

  const uploadProgress = (progressEvent: any) => {
    const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
    if (progressRef.current) {
      progressRef.current.innerHTML = `${uploadPercentage}%`;
      progressRef.current.style.width = `${uploadPercentage}%`;
    }

    if (uploadPercentage === 100) {
      if (uploadRef.current) {
        uploadRef.current.innerHTML = 'Exame cadastrado com sucesso!';

        Swal.fire({
          title: 'Exame cadastrado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/exames');
          }
        });
      }
      setValidFiles([...validFiles]);
      setSelectedFiles([...validFiles]);
      setUnsupportedFiles([...validFiles]);
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const userTokens = await Auth.currentSession();

      if (type === "dependentes") {
        await api.patch(`exame/reatribuir/${id}`, {
          cpf: bearer.replace(/\D/g, ''),
        }, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          }
        });
      } else {
        const pet = {
          id: bearer,
          responsavel: userTokens.getIdToken().payload.sub
        }

        await api.post(`/examepet/reatribuir/${id}`, {
          id: uuidv4(),
          descricao: "Novo exame",
          pet,
          ativo: true,
        }, {
          headers: {
            'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
          },
        });
      }

      Swal.fire({
        title: 'Exame reatribuído com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Ver Todos',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/exames');
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro ao executar a ação solicitada!'
      })
    }
  }

  const uploadFiles = async () => {
    if (!cpf && id === "") {
      Swal.fire({
        title: 'Digite o cpf!',
        icon: 'warning',
      });
      return;
    }

    if (uploadModalRef.current) uploadModalRef.current.style.display = 'block';
    if (uploadRef.current) uploadRef.current.innerHTML = 'Subindo exame...';

    try {
      let concatImages = false;
      const userTokens = await Auth.currentSession();
      const nomeExame = '';
      const ativo = true;

      let extensionsAreAllImagesExtensions = validFiles.every(file => {
        if (['pdf', 'PDF'].includes(file.name.split('.').reverse()[0])) {
          return false;
        } else {
          return true;
        }
      });

      if (extensionsAreAllImagesExtensions) {
        await Swal.fire({
          title: 'Deseja juntar todas as imagens em um único arquivo PDF?',
          icon: 'question',
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sim',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.isConfirmed) {
            concatImages = true;
          } else {
            concatImages = false;
          }
        });
      }

      const promises = validFiles.map(async (file) => ({
        id: uuidv4(),
        nomeExame,
        ...dadosPessoaExame,
        arquivoBinario: {
          fileName: file.name,
          content: await toBase64(file)
        },
        ativo,
        cpf: cpf.replace(/\D/g, '')
      }));

      const bodyUpload = await Promise.all(promises);

      await api.put('exame', {
        concatImages,
        exames: bodyUpload
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        },
        onUploadProgress: (progressEvent: any) => {
          uploadProgress(progressEvent);
        },
      });
    } catch (err) {
      if (uploadRef.current) {
        uploadRef.current.innerHTML = `<span class="error">Erro ao subir exame!</span>`;
        Swal.fire({
          title: 'Erro ao subir exame!',
          icon: 'error',
        });
      }
      if (progressRef.current) progressRef.current.style.backgroundColor = 'red';
    }
  }

  const closeUploadModal = () => {
    if (uploadModalRef.current) uploadModalRef.current.style.display = 'none';
  }

  return (
    <main className="upload-files main-paciente">
      <SearchExames searchField={false} />
      <div>
        <h1>Upload documentos</h1>
        {!edit &&
          <>
            <div className={classes.grid}>
              <FormControl className="cpf" variant="standard">
                <InputLabel id="demo-simple-select-outlined-label">Selecione o CPF</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={cpf}
                  onChange={handleChangeCpf}
                  label="Selecione um CPF..."
                >
                  <MenuItem value={cpfPaciente}>{cpfPaciente + ' - ' + nomePaciente}</MenuItem>
                  {
                    dependentes.map((row: any, index) => {
                      const cpf = row.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                      return (
                        row.aprovado == true ?
                          <MenuItem value={row.cpf}>{cpf + ' - ' + row.nome + ' ' + row.sobrenome}</MenuItem>
                          : ''
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <UploadFile
              submit={buttonLoading}
              multiple={true}
              modalRef={modalRef}
              modalImageRef={modalImageRef}
              uploadModalRef={uploadModalRef}
              uploadRef={uploadRef}
              progressRef={progressRef}
              selectedFiles={selectedFiles}
              unsupportedFiles={unsupportedFiles}
              validFiles={validFiles}
              setValidFiles={setValidFiles}
              setSelectedFiles={setSelectedFiles}
              setUnsupportedFiles={setUnsupportedFiles}
              uploadFiles={uploadFiles}
              validTypes={['application/pdf', 'image/jpeg', 'image/png']}
              type={'person'}
            />
          </>
        }

        {edit &&
          <div>
            <form onSubmit={handleSubmit}>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Tipo do titular</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={type}
                  onChange={handleNewTypeOfBearer}
                  label="Tipo do titular"
                  required
                >
                  <MenuItem value="dependentes">Dependentes</MenuItem>
                  <MenuItem value="pets">Pets</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">{type === "dependentes" ? "Dependentes" : "Pets"}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={bearer}
                  onChange={handleChangeBearer}
                  label={type === "dependentes" ? "Dependentes" : "Pets"}
                  required
                >
                  {type === "dependentes" &&
                    dependentes.map((row: any, index) => {
                      const cpf = row.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                      const label = (type === "dependentes" ? cpf + ' - ' + row.nome + ' ' + row.sobrenome : row.nome);

                      return (
                        row.aprovado ? <MenuItem value={cpf}>{label}</MenuItem> : ''
                      )
                    })
                  }
                  {type === "pets" &&
                    pets.map((row: any, index) => (
                      <MenuItem value={row.id}>{row.nome}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<BackIcon />}
                  onClick={() => { history.push('/exames') }}
                >
                  Voltar
                </Button>
              </div>
            </form>
          </div>
        }
      </div>

      <div className="modal" ref={modalRef}>
        <div className="overlay"></div>
        <span className="close" onClick={(() => closeModal())}>X</span>
        <div className="modal-image" ref={modalImageRef}></div>
      </div>

      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay"></div>
        <div className="progress-container">
          <div className="close" onClick={(() => closeUploadModal())}>X</div>
          <span ref={uploadRef}></span>
          <div className="progress">
            <div className="progress-bar" ref={progressRef}></div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Exame;
