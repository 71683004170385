import React, { useState, useEffect, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from 'aws-amplify';
import { UIStore } from '../../UIStore';
import Termos from '../../components/Termos';
import { FaCheckCircle } from 'react-icons/fa'
import { ImRadioUnchecked } from 'react-icons/im'
import './styles.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      backgroundColor: "#00546B",
      minHeight: '100vh',
      fontFamily: 'Raleway',
    },
    terms: {
      display: 'block',
      borderRadius: '5px',
      marginTop: '20px',
      color: '#fff',
      width: '75%',
      padding: '20px',
      margin: 'auto',
    },
    logo: {
      padding: '0 .1rem',
      zIndex: 5,
    },
    img: {
      width: '200px',
      height: 'auto',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: '50px',
    },
    message: {
      fontSize: "20px",
      textAlign: "center",
      alignItems: 'center',
      margin: "20px auto",
      width: '75%',
      fontWeight: 400,
      ['@media (max-width: 450px)']: {
        width: '100%',
        textAlign: "center",
        fontSize: "17px",
      }
    },
    buttonCancel: {
      fontFamily: 'Raleway',
      fontWeight: 900,
      width: "150px",
      marginBottom: "20px",
      '&:hover': {
        filter: "brightness(0.8) !important"
      }
    },
    buttonConfirm: {
      fontFamily: 'Raleway',
      fontWeight: 900,
      width: "150px",
      marginBottom: "20px",
      '&:hover': {
        backgroundColor: '#01A3FF!important',
        filter: "brightness(0.8) !important"
      }

    }

    ,
    checkbox: {
      color: '#ccc',
      marginBottom: "8px",

    },
    checked: {
      "&$checked": { color: "#fff" },

    },



  })
);

interface Assinatura {
  politica: boolean;
  termos: boolean;
  login: boolean;
}
class Contrato {
  termos: boolean = false;
  politica: boolean = false;
}

export default function Assinaturas() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<Assinatura>();
  const [contrato, setContrato] = useState<Contrato>({
    politica: false,
    termos: false
  });
  const [mensagem, setMensagem] = useState("");

  const [signTermoDeUso, setSignTermoDeUso] = useState(false);
  const [signPoliticaDePrivacidade, setSignPoliticaDePrivacidade] = useState(false);
  const [politicaRequired, setPoliticaRequired] = useState(false);
  const [termoRequired, setTermoRequired] = useState(false);
  const [displayPolitica, setDisplayPolitica] = useState("");
  const [displayTermo, setDisplayTermo] = useState("");
  const [login, setLogin] = useState(false);
  const [open, setOpen] = useState(false);
  const [tipo, setTipo] = useState("");

  useEffect(() => {
    setLogin(location.state.login);
    if (location.state !== undefined && location.state !== null) {
      const novosTermos = location.state.termos;
      const novasPoliticas = location.state.politica;

      const newContrato: Contrato = {
        ...(!novosTermos ? { termos: true } : { termos: false }),
        ...(!novasPoliticas ? { politica: true } : { politica: false }),
      };
      setPoliticaRequired(newContrato.politica);
      setTermoRequired(newContrato.termos);
      setDisplayPolitica(newContrato.politica ? "block" : "none");
      setDisplayTermo(newContrato.termos ? "block" : "none");
      setContrato(newContrato);
      setMensagem(`
        ${novosTermos && novasPoliticas ? "Os termos de uso e políticas de privacidade foram atualizados." :
          novosTermos ? "Os termos de uso foram atualizados." :
            "As políticas de privacidade foram atualizadas."}
      `);
    }
  }, []);

  const handleOpen = (tipo: string) => {
    setOpen(true);
    setTipo(tipo);
  }

  const handleClose = () => {
    setOpen(false);
    setTipo("");
  }

  const handleBack = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      window.location.replace("https://www.cliposaude.com.br/");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Atenção!",
        text: "Ocorreu um erro ao executar a ação solicitada!"
      });
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const userTokens = await Auth.currentSession();

    let nome = `${userTokens.getIdToken().payload["name"]} ${userTokens.getIdToken().payload["family_name"]}`;
    let email = userTokens.getIdToken().payload["email"];

    try {
      await api.put('consentimento', {
        ...contrato,
      }, {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken()
        }
      });
      if (!login) {
        history.push({
          pathname: "/agradecimento",
        });
      } else {
        UIStore.update(s => {
          s.signed = true;
          s.nivelUser = 'paciente';
          s.username = email;
          s.name = nome
        });
        localStorage.setItem('signed', 'true');
        localStorage.setItem('name', nome);
        localStorage.setItem('nivelUser', 'paciente');

        const now = new Date();
        const expirationTime = now.setHours(now.getHours() + 24);
        localStorage.setItem('expirationTime', String(expirationTime));

        history.push({
          pathname: "/home",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={classes.main}>
      <div className={classes.logo}>
        <img className={classes.img} src={LogoTextoClipo} />
      </div>
      <div className={classes.terms}>
        <h3 className='tituloMensagem'>{mensagem}</h3>
        <p className={classes.message}>Para continuar é necessário estar ciente
          das alterações realizadas e consentir novamente para prosseguir
          e interagir com sistema da Clipo Saúde.</p>
        <form onSubmit={handleSubmit} className='assinaturas-form'>
          <div className="termos">
            <div>
              <Checkbox
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked
                }}
                icon={<ImRadioUnchecked />}
                checkedIcon={<FaCheckCircle />}
                checked={signPoliticaDePrivacidade}
                onChange={() => setSignPoliticaDePrivacidade(!signPoliticaDePrivacidade)}
                name="termo"
                color="primary"
                required={politicaRequired}
              />
              <span>
                Declaro que li e estou de acordo com as condições de  <a style={{ fontWeight: 700, textDecoration: "underline" }} onClick={() => handleOpen("politica")}>Política de Privacidade</a>.
              </span>
            </div>
            <div>
              <Checkbox
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked
                }}
                icon={<ImRadioUnchecked />}
                checkedIcon={<FaCheckCircle />}
                checked={signTermoDeUso}
                onChange={() => setSignTermoDeUso(!signTermoDeUso)}
                name="termo"
                color="primary"
                required={termoRequired}
              />
              <span>
                Declaro que li e estou de acordo com as condições dos  <a style={{ fontWeight: 700, textDecoration: "underline" }} onClick={() => handleOpen("termos")}>Termos de uso</a>
              </span>
            </div>
          </div>
          <div className="action-buttons">
            <Button
              className={classes.buttonCancel}
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: "#ff3021",
                color: "white"
              }}
              size="large"
              onClick={handleBack}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonConfirm}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
        <Termos
          tipo={tipo}
          handleClose={handleClose}
          open={open}
        />
      </div>
    </div>
  )
}
