import React from "react";
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import CachedIcon from '@material-ui/icons/Cached';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface PropsColumn {
  row: any;
  headCell: HeadCell;
  type: "exams" | "clinicExams" | "examesNotVerified" | "dependents" | "pets" | "petsExam" | "clinics";
  handleOpenRate?: (id: string, cpf: string) => void;
  handleOpenDesc?: (id: string, cpf: string) => void;
  handleOpenDoc?: (id: string, arquivo: string) => void;
  handleView?: (id: string, arquivo: string) => void;
  handleDelete?: (id: string) => void;
  width: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    danger: {
      color: '#fff',
      pointerEvents: 'visible',
      border: '1px solid #dc3545',
      backgroundColor: '#dc3545',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    warning: {
      color: '#fff',
      pointerEvents: 'visible',
      border: '1px solid #e8c305',
      backgroundColor: '#e8c305',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
    success: {
      color: '#fff',
      pointerEvents: 'visible',
      backgroundColor: '#218838',
      border: '1px solid #218838',
      padding: "5px 10px 5px 10px",
      width: "50%"
    },
  }),
);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Column(props: PropsColumn): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (['exams', 'clinicExams', 'examesNotVerified'].includes(props.type)) {
    if (props.headCell.id !== 'opcoes') {
      return (
        <>
          {props.headCell.id === 'dataCadastro' &&
            <TableCell align="left" padding="none" id={props.headCell.id}>
              {format(new Date(props.row.dataCadastro), 'dd/MM/yyyy HH:mm:ss', { locale: dateFnsPtBR })}
            </TableCell>
          }
          {props.headCell.id === 'cpf' &&
            <TableCell>
              {props.row[props.headCell.id].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
            </TableCell>
          }
          {props.headCell.id !== 'dataCadastro' && props.headCell.id !== 'clinica' && props.headCell.id !== 'cpf' && props.headCell.id !== 'status' &&
            <TableCell padding="none" id={props.headCell.id}>
              {props.row[props.headCell.id]}
            </TableCell>
          }
          {props.headCell.id === 'clinica' &&
            <TableCell padding="none" id={props.headCell.id}>
              {props.row[props.headCell.id] !== null ? props.row[props.headCell.id].nomeFantasia : ""}
            </TableCell>
          }
          {props.headCell.id === 'status' &&
            <TableCell align="center" padding="none">
              {(!props.row.identificado && (props.row.dataValidacao !== null && props.row.dataValidacao !== undefined) ?
                <Tooltip title="FALHA NA VERIFICAÇÃO">
                  <Button classes={{ root: classes.danger }}>
                    <CancelIcon />
                  </Button>
                </Tooltip>
                : (props.row.identificado && (props.row.dataValidacao !== null && props.row.dataValidacao !== undefined) ?
                  <Tooltip title="VERIFICADO">
                    <Button classes={{ root: classes.success }}>
                      <CheckCircleIcon />
                    </Button>
                  </Tooltip>
                  :
                  <Tooltip title="AGUARDANDO VERIFICAÇÃO">
                    <Button classes={{ root: classes.warning }}>
                      <AccessTimeIcon />
                    </Button>
                  </Tooltip>
                ))}
            </TableCell>
          }
        </>
      )
    } else {
      if (props.width <= 1024 && props.type === 'exams') {
        return (
          <TableCell id={props.headCell.id}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {props.row.clinica !== undefined && props.row.clinica !== null &&
                <StyledMenuItem
                  onClick={() => {
                    props.handleOpenRate!(props.row.id, props.row.cpf);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <StarIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="classificar clínica" />
                </StyledMenuItem>
              }
              <StyledMenuItem
                onClick={() => {
                  props.handleOpenDesc!(props.row.id, props.row.cpf);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <CreateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="identificar exame" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  props.handleOpenDoc!(props.row.id, props.row.arquivo);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <VisibilityIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="visualizar exame" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  props.handleView!(props.row.id, props.row.arquivo);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="download" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  props.handleDelete!(props.row.id);
                  handleClose();
                }}
                disabled={props.row.clinica !== null ? true : false}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="excluir" />
              </StyledMenuItem>
            </StyledMenu>
          </TableCell>
        )
      } else {
        return (
          <TableCell align="right" id={props.headCell.id}>
            {props.type === 'exams' && props.row.clinica !== undefined && props.row.clinica !== null &&
              <Tooltip title="CLASSIFICAR CLÍNICA">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<StarIcon />}
                  onClick={() => props.handleOpenRate!(props.row.id, props.row.cpf)}
                  className="no-text"
                ></Button>
              </Tooltip>
            }
            {(
              (props.type === 'examesNotVerified' && !props.row.identificado)
              ||
              (props.type === 'exams' && (props.row.clinica === undefined || props.row.clinica === null))
            )
              &&
              props.row.email &&
              <>
                <NavLink to={{
                  pathname: "exame",
                  state: {
                    exame: {
                      id: props.row.id
                    },
                    edit: true
                  }
                }}>
                  <Tooltip title="ALTERAR TITULAR DO EXAME">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CachedIcon />}
                      className="no-text"
                    ></Button>
                  </Tooltip>
                </NavLink>
              </>
            }
            {
              props.type === 'clinicExams' &&
              (!props.row.identificado && (props.row.dataValidacao !== null && props.row.dataValidacao !== undefined) ?
                <NavLink to={{
                  pathname: "exame",
                  state: {
                    id: props.row.id,
                  }
                }}>
                  <Tooltip title="SUBSTITUIR EXAME">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CachedIcon />}
                      className="no-text"
                    ></Button>
                  </Tooltip>
                </NavLink>
                : ""
              )
            }
            {
              props.type === 'exams' &&
              <Tooltip title="ATRIBUIR DESCRIÇÃO AO EXAME">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CreateIcon />}
                  onClick={() => props.handleOpenDesc!(props.row.id, props.row.cpf)}
                  className="no-text"
                ></Button>
              </Tooltip>
            }
            <Tooltip title="VISUALIZE SEU EXAME">
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => props.handleOpenDoc!(props.row.id, props.row.arquivo)}
                className="no-text"
              ></Button>
            </Tooltip>
            <Tooltip title="DOWNLOAD">
              <Button
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={() => props.handleView!(props.row.id, props.row.arquivo)}
                className="no-text"
              ></Button>
            </Tooltip>
            {props.type === 'exams' &&
              <Tooltip title="EXCLUIR">
                <Button
                  variant="contained"
                  color="secondary"
                  className="no-text red-button"
                  startIcon={<DeleteIcon />}
                  onClick={() => props.handleDelete!(props.row.id)}
                  disabled={props.row.clinica !== null ? true : false}
                ></Button>
              </Tooltip>
            }
            {props.type === 'clinicExams' &&
              <Tooltip title="Exames enviados pela clínica não podem ser excluídos.">
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="no-text red-button"
                    startIcon={<DeleteIcon />}
                    disabled
                  ></Button>
                </span>
              </Tooltip>
            }
          </TableCell>
        )
      }
    }
  } else {
    if (props.headCell.id !== 'opcoes') {
      return (
        <TableCell>
          {props.row[props.headCell.id]}
        </TableCell>
      )
    } else {
      return (
        <TableCell align="right">
          <Tooltip title="VISUALIZAR INFORMAÇÕES DA CLÍNICA">
            <NavLink to={{
              pathname: "clinica",
              state: {
                id: props.row.id
              }
            }}>
              <Button
                variant="contained"
                color="primary"
                className="no-text"
                startIcon={<ViewIcon />}
              ></Button>
            </NavLink>
          </Tooltip>
        </TableCell>
      )
    }
  }
}
