import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import CreateIcon from '@material-ui/icons/Create';
import { AddIcon, AddDocumentIcon } from '../../utils/SvgIcons';

import api from '../../services/api';
import patas from '../../assets/images/pawprint.png';
import DataList from '../../components/DataList';
import { handleDelete } from '../../utils/handleExam';
import SearchExames from '../../templates/SearchExames';
import './styles.scss';

const Pets = () => {
  const [rows, setRows] = useState([]);
  const history = useHistory();

  const handleDataListRowProps = (row: any) => {
    return {
      avatar: <img src={patas} alt="" />,
      title: row.nome,
      subTitle: row.familia.nome,
      onClick: () => history.push(`/pets/exames/`, row.id),
      actions: [
        {
          description: 'Ver documentos',
          tooltip: 'DOCUMENTOS',
          link: { pathname: '/pets/exames/', state: row.id },
          icon: <DescriptionIcon />,
        },
        {
          description: 'Editar perfil',
          tooltip: 'EDITAR',
          link: '/pet/' + row.id,
          icon: <CreateIcon />,
        },
        {
          description: 'Excluir',
          tooltip: 'EXCLUIR',
          icon: <DeleteIcon />,
          url: `/pet/${row.id}`,
          param: 'pet',
          handle: handleDelete,
        },
      ],
    };
  };

  useEffect(() => {
    (async () => {
      const userTokens = await Auth.currentSession();

      const response = await api.get('/pet', {
        headers: {
          'X-Cognito-ID-Token': userTokens.getIdToken().getJwtToken(),
        },
      });

      if (response.data.length > 0) {
        setRows(response.data);
      } else {
        setRows([]);
      }
    })();
  }, []);

  return (
    <main className="pets main-paciente">
      <SearchExames searchField={false} />
      <DataList
        handleRowProps={handleDataListRowProps}
        checkbox={false}
        orderBy="nome"
        orderByTitle="Nome"
        rows={rows}
        pageName="Pets"
        headActions={[
          {
            icon: <AddIcon />,
            text: 'Doc',
            tooltip: 'ADICIONAR DOCUMENTO',
            handle: () => history.push('pets/exame/'),
          },
          {
            icon: <AddIcon />,
            text: 'Novo',
            tooltip: 'CADASTRAR PET',
            link: 'pet/',
          },
        ]}
      />
    </main>
  );
};

export default Pets;
