import React, { useState, FormEvent, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import QRCode from 'qrcode.react';

import './styles.scss';

import grafismo from '../../assets/images/grafismo-admin.png';
import { UIStore } from '../../UIStore';

import LogoTextoClipo from '../../assets/images/logo-texto.svg';

const translate = (term: string) => {
  const terms: { [key: string]: string } = {
    'Code mismatch and fail enable Software Token MFA': 'Código incorreto, MFA não foi habilitado.'
  };
  if (Object.keys(terms).includes(term)) {
    return terms[term];
  }
  return term;
}

function SetupMFAClinica(props: {
  location: {
    state: {
      username: string,
      password: string
    }
  }
}) {
  const history = useHistory();
  const [mfaCode, setMFACode] = useState('');
  const [user, setUser] = useState<any>();
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    (async () => {
      const user = await Auth.signIn(props.location.state.username, props.location.state.password);
      setUser(user);

      const code = await Auth.setupTOTP(user);
      setQrCode(`otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=Clipo%20Saúde`);
    })();
  }, []);

  if (props.location.state === undefined || props.location.state === null) {
    history.goBack();
  }

  async function signIn(e: FormEvent) {
    e.preventDefault();

    const mfaCodeTrim = mfaCode.replace(/\s/g, '');

    if (mfaCodeTrim.length !== 6 || mfaCodeTrim.match(/[0-9]/g) === null) {
      Swal.fire({
        icon: 'error',
        text: 'O código MFA precisa conter 6 caracteres numéricos!',
        title: 'Atenção!'
      });

      return false;
    }

    try {
      await Auth.verifyTotpToken(user, mfaCodeTrim);
      await Auth.setPreferredMFA(user, 'TOTP');
      const userAuthenticated = await Auth.currentSession();
      const name = userAuthenticated.getIdToken().payload.name.split(' ');
      UIStore.update(s => {
        s.signed = true;
        s.username = user?.username;
        s.nivelUser = 'clinica';
        s.name = name.join(' ');
      });

      const now = new Date();
      const expirationTime = now.setHours(now.getHours() + 12);
      localStorage.setItem('expirationTime', String(expirationTime));
      localStorage.setItem('signed', 'true');
      localStorage.setItem('username', user?.username);
      localStorage.setItem('name', name.join(' '));
      localStorage.setItem('nivelUser', 'clinica');
      history.replace('/exames');
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Erro!',
        text: translate(error.message.toString()),
        icon: 'error',
      });
      localStorage.clear();
      UIStore.replace({ signed: false, nivelUser: '', username: '', name: '' });
    }

  }

  return (
    <>
      <div id="loginAdmin">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <img src={LogoTextoClipo} />
              <form className="materializeForm" onSubmit={signIn}>
                <div style={{ backgroundColor: 'white' }}>
                  <QRCode value={qrCode} />
                </div>
                <input
                  type="text"
                  placeholder="Código MFA"
                  autoFocus
                  name="mfa"
                  value={mfaCode}
                  onChange={(e) => { setMFACode(e.target.value) }}
                  required
                />
                <button>Confirmar MFA</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SetupMFAClinica;
