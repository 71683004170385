import axios from 'axios';
import { maskDate } from '../utils/mask';

const api = axios.create({
  baseURL: 'https://ws.hubdodesenvolvedor.com.br/v2/cpf'
});

export const findNameByCpfAndBirthDate = async (cpf: string, birthDate: Date) => {
  try {
    const maskCPF = cpf.replace(/\D/g, '');
    const formattedDate = maskDate(birthDate);
    const token = process.env.REACT_APP_HUBDEV_CPF_TOKEN;
    const url = `/?&cpf=${maskCPF}&data=${formattedDate}&token=${token}`;
    const { data } = await api.get(url);

    if (data.return === "OK") {
        const { nome_da_pf } = data.result;

        return nome_da_pf.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, (l: string) => l.toUpperCase());
      } else if (data.message === "Token Inválido ou sem saldo para a consulta.") {
        throw new Error("Network Error");
      } else {
        throw new Error("Data de nascimento e/ou CPF incorretos. Por favor, digite suas informações corretamente!");
      }

  } catch (error) {
    throw new Error(error.message);
  }
}
