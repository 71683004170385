import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import NumberFormat from 'react-number-format';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../services/api';

import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface Plano {
  id: string;
  nome: object;
  preco: number;
  descricao: string;
}

interface ParamTypes {
  id: string;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalSeparator={'.'}
      isNumericString
      decimalScale={1}
      prefix="R$ "
    />
  );
}

interface State {
  preco: number;
}

function Plano() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [idPlano, setIdPlano] = useState({} as Plano);
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [preco, setPreco] = React.useState<State>({
    preco: 0,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreco({
      ...preco,
      [event.target.name]: event.target.value,
    });
  };

  const [ativo, setAtivo] = useState(true);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
    }
  }

  useEffect(() => {
    if (id) {
      const getData = async () => {
        const response = await api.get('/plano/' + id);
        setIdPlano(response.data.id);
        setNome(response.data.nome);
        setPreco({
          ...preco,
          preco: response.data.preco,
        });
        setAtivo(response.data.ativo);
        setDescricao(response.data.descricao);
      };

      getData();
    }
  }, []);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!nome) {
        Swal.fire({
          title: 'Digite o nome!',
          icon: 'warning',
        });
        return;
      }

      const result = await api.put("/plano", {
        id,
        nome,
        preco: Number(preco.preco),
        ativo,
        descricao
      });

      Swal.fire({
        title: 'Cadastro realizado com sucesso!',
        icon: 'success',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#418107',
        cancelButtonText: 'Ver Todos'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        } else {
          history.push('/usuario/planos');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Plano</h1>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="hidden"
                    name="id"
                    value={idPlano}
                  />
                  <div className={classes.grid}>
                    <TextField
                      id="outlined-basic"
                      label="Plano"
                      variant="outlined"
                      // required
                      name="nome"
                      value={nome}
                      onChange={(e) => { setNome(e.target.value) }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Preço"
                      variant="outlined"
                      required
                      name="preco"
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      value={preco.preco}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.root}>
                    <TextField
                      id="outlined-basic"
                      label="Descrição"
                      variant="outlined"
                      multiline
                      rows={6}
                      required
                      name="descricao"
                      value={descricao}
                      onChange={(e) => { setDescricao(e.target.value) }}
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={ativo}
                        onChange={(e) => { setAtivo(!ativo) }}
                        color="primary"
                        name="ativo"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Status"
                  />
                  <div className={classes.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/usuario/planos') }}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Plano;
