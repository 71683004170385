import React from 'react'
import { TopPageSearch } from '../../components/TopPageSearch'

// SVG Icons
import darkLogo from "../../assets/images/bluedark_logo.svg";

export default function SearchExames({searchField}:{searchField: boolean }) {
    return (
        <TopPageSearch 
          searchField={searchField}
          url="/exame?descricao="
          placeholderSearchField="Pesquisar Exames..."
          redirect="/exames"
          children={
            <img className='header-dark-logo' src={darkLogo} alt="Clipo" /> 
          }
        />
    )
}
