import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Toolbar, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { IOptions, IAction } from './index.d';
import './styles.scss';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Options = (props: IOptions) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openAnchor = Boolean(anchorEl);

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {windowDimensions.width < 880 ?
        <div className="mobile-menu">
          <IconButton
            disableRipple
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleCloseAnchor}
            className="menu-list"
            PaperProps={{
              style: {
                maxHeight: 'auto',
                width: 'auto',
                padding: '.2rem',
                borderRadius: '14px'
              }
            }}
          >
            {props.actions.map((action: IAction) => {
              const handleClick = () => {
                const value = action.url || props.id;
                action.handle?.(value, action.param);

                handleCloseAnchor();
              }
              const visible = action.visible === undefined ? true : action.visible

              return (
                <div>
                  {visible && <MenuItem
                    className="menu-item"
                    onClick={handleClick} disableRipple>
                    {action.icon}
                    {action.link ? <NavLink to={action.link} ><span>{action.description}</span></NavLink> : <span>{action.description}</span>}
                  </MenuItem>}
                </div>
              )
            })}
          </Menu>
        </div >
        :
        <div>
          <Toolbar className="desktop-tools">
            {props.actions.map((action: IAction) => {
              const handleClick = () => {
                const value = action.url || props.id;
                action.handle?.(value, action.param);
              }
              const visible = action.visible === undefined ? true : action.visible;

              return (
                <>
                  {visible && <Tooltip title={action.tooltip}>
                    <IconButton onClick={handleClick}>
                      {action.link ? <NavLink to={action.link}>{action.icon}</NavLink> : <>{action.icon}</>}
                    </IconButton>
                  </Tooltip>}
                </>
              )
            })}
          </Toolbar>
        </div>
      }
    </>
  );
}

export default Options;
