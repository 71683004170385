import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Swal from 'sweetalert2';

import './styles.scss';

import grafismo from '../../assets/images/grafismo-user.png';

import LogoClipo from '../../assets/images/logo.svg';
import LogoTextoClipo from '../../assets/images/logo-texto.svg';

function EsqueciClinica() {
  const [username, setUsername] = useState('');

  const history = useHistory();

  useEffect(() => {
    const emailClinica = localStorage.getItem('emailClinica');
    if (emailClinica) {
      setUsername(emailClinica);
    }
  }, []);

  const sendCode = async (e: FormEvent) => {
    e.preventDefault();

    try {
      Auth.forgotPassword(username)
        .then(data => {
          localStorage.setItem('emailClinica', username);
          history.push('/redefinir-clinica');
        })
        .catch(err => console.log(err));
      // Swal.fire({
      //   title: 'Sucesso!',
      //   text: 'Código enviado, verique o seu e-mail!',
      //   icon: 'success',
      // });
    } catch (err) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao enviar o código. Por favor, tente novamente!',
        icon: 'error',
      });
    }
  }

  return (
    <>
      <div id="esqueciClinica">
        <div className="grid-box grid-two">
          <div className="background-gray v-height">
            <img src={grafismo} alt="Grafismo" />
          </div>
          <div className="background-blue-dark v-height">
            <div className="form">
              <img src={LogoTextoClipo}></img>
              <form className="materializeForm" onSubmit={sendCode}>
                <input
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                  required
                />
                <button>Enviar</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EsqueciClinica;
